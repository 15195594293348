export type DataObj = {
    user_name: string;
    area: string;
    name: string;
    room: string;
    state: string;
    fixed_date: string;
    scheduled_date: string;
    completion_date: string;
    plan: string;
    aircon: string;
    interior: string;
    repo1: string;
    repo2: string;
    repo3: string;
    unConstructed: string;
    cloth: string;
    waxP: string;
    led: string;
    amido: string;
    otherBuhin: string;
    other: string;
};

export type DataObjUser = {
    area: string;
    name: string;
    room: string;
    state: string;
    fixed_date: string;
    scheduled_date: string;
    completion_date: string;
    plan: string;
    aircon: string;
    interior: string;
    repo1: string;
    repo2: string;
    repo3: string;
    unConstructed: string;
    cloth: string;
    waxP: string;
    led: string;
    amido: string;
    otherBuhin: string;
    other: string;
};

export const headers = [
    { label: "作業者", key: "user_name" },
    { label: "地域", key: "area" },
    { label: "名称", key: "name" },
    { label: "部屋", key: "room" },
    { label: "状態", key: "state" },
    { label: "期日", key: "fixed_date" },
    { label: "予定日", key: "scheduled_date" },
    { label: "完了日", key: "completion_date" },
    { label: "間取り", key: "plan" },
    { label: "エアコン", key: "aircon" },
    { label: "内装工事", key: "interior" },
    { label: "指示書記載事項報告", key: "repo1" },
    { label: "指示書無記載事項報告", key: "repo2" },
    { label: "気付き箇所報告", key: "repo3" },
    { label: "未施工", key: "unConstructed" },
    { label: "クロスC", key: "cloth" },
    { label: "ワックス剝離", key: "waxP" },
    { label: "LED等交換", key: "led" },
    { label: "編戸張替", key: "amido" },
    { label: "その他部品", key: "otherBuhin" },
    { label: "その他", key: "other" },
];

export const headersUser = [
    { label: "地域", key: "area" },
    { label: "名称", key: "name" },
    { label: "部屋", key: "room" },
    { label: "状態", key: "state" },
    { label: "期日", key: "fixed_date" },
    { label: "予定日", key: "scheduled_date" },
    { label: "完了日", key: "completion_date" },
    { label: "間取り", key: "plan" },
    { label: "エアコン", key: "aircon" },
    { label: "内装工事", key: "interior" },
    { label: "指示書記載事項報告", key: "repo1" },
    { label: "指示書無記載事項報告", key: "repo2" },
    { label: "気付き箇所報告", key: "repo3" },
    { label: "未施工", key: "unConstructed" },
    { label: "クロスC", key: "cloth" },
    { label: "ワックス剝離", key: "waxP" },
    { label: "LED等交換", key: "led" },
    { label: "編戸張替", key: "amido" },
    { label: "その他部品", key: "otherBuhin" },
    { label: "その他", key: "other" },
];
