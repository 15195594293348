import React, { useEffect, useState, useContext } from "react";
import { db, timestamp } from "../../../../config/firebase";
import { getTargetMonth, fromTimeStampToString } from "../../../../common/date";
import { worksConst } from "../../../../config/Const";
import ResponsiveDrawer from "../../../templates/ResponsiveDrawer";
import { AuthContext } from "../../../../store/AuthContext";
import { usersConst } from "../../../../config/Const";
import {
  Divider,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitHandler, useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100ch",
      marginBottom: 20,
    },
  },
  formControlPlan: {
    margin: theme.spacing(1),
    width: "14ch",
  },
  formControlAircon: {
    margin: theme.spacing(1),
    width: "12ch",
  },
  formControlInterior: {
    marginLeft: theme.spacing(4),
    width: "40ch",
  },
  textfield: {
    marginTop: theme.spacing(1),
    width: "60ch",
  },
  textfieldInvoice1: {
    marginRight: theme.spacing(2),
    width: "12ch",
    textAlignLast: "end",
  },
  textfieldInvoice2: {
    marginRight: theme.spacing(2),
    width: "16ch",
    textAlignLast: "end",
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "12ch",
  },
}));

type Inputs = {
  plan: string;
  aircon: string;
  interior: string;
  repo1: string;
  repo2: string;
  repo3: string;
  unConstructed: string;
  cloth: string;
  waxP: string;
  led: string;
  amido: string;
  otherBuhin: string;
  other: string;
  comment: string;
  comment2: string;
};

const WorksReport: React.FC = (props: any) => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const { params } = props.match;
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [area, setArea] = useState("");
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [completionDate, setCompletionDate] = useState("");
  const [addPlan, setAddPlan] = useState("");
  const [addAircon, setAddAircon] = useState("");
  const [addInterior, setAddInterior] = useState("");
  const [addRepo1, setAddRepo1] = useState("");
  const [addRepo2, setAddRepo2] = useState("");
  const [addRepo3, setAddRepo3] = useState("");
  const [addUnConstructed, setAddUnConstructed] = useState("");
  const [addCloth, setAddCloth] = useState("0");
  const [addWaxP, setAddWaxP] = useState("0");
  const [addLed, setAddLed] = useState("0");
  const [addAmido, setAddAmido] = useState("0");
  const [addOtherBuhon, setAddOtherBuhon] = useState("0");
  const [addOther, setAddOther] = useState("0");
  const [addComment, setAddComment] = useState("");
  const [addComment2, setAddComment2] = useState("");
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState("");
  const [confirmDate, setConfirmDate] = useState("");
  const [isExistLink, setIsExistLink] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      plan: addPlan,
      aircon: addAircon,
      interior: addInterior,
      repo1: addRepo1,
      repo2: addRepo2,
      repo3: addRepo3,
      unConstructed: addUnConstructed,
      cloth: addCloth,
      waxP: addWaxP,
      led: addLed,
      amido: addAmido,
      otherBuhin: addOtherBuhon,
      other: addOther,
      comment: addComment,
      comment2: addComment2,
    },
  });
  const baseDisabled: boolean =
    authValue.users.role !== usersConst.roleName.User ||
    state === worksConst.stateName.Si ||
    state === worksConst.stateName.Ni ||
    state === worksConst.stateName.Ok;

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authValue.currentUser]);

  useEffect(() => {
    searchReports();
    searchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchInvoiceLinks(
      authValue.users.team,
      userId,
      getTargetMonth(completionDate)
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completionDate]);

  const searchReports = async () => {
    try {
      const reportRef = db.collection("reports").doc(params.docId);
      const reportDoc = await reportRef.get();
      if (reportDoc.exists) {
        setAddPlan(reportDoc.get("plan"));
        setAddAircon(reportDoc.get("aircon"));
        setAddInterior(reportDoc.get("interior"));
        setAddRepo1(reportDoc.get("repo1"));
        setAddRepo2(reportDoc.get("repo2"));
        setAddRepo3(reportDoc.get("repo3"));
        if (reportDoc.get("unConstructed") !== undefined)
          setAddUnConstructed(reportDoc.get("unConstructed"));
        setAddComment(reportDoc.get("comment"));
        setAddComment2(reportDoc.get("comment2"));
        setState(reportDoc.get("state"));
      }
    } catch (e) {
      console.log("searchReportsに失敗しました:" + e);
    }
  };

  const searchInvoices = async () => {
    try {
      const invoiceRef = db.collection("invoices").doc(params.docId);
      const invoiceDoc = await invoiceRef.get();
      if (invoiceDoc.exists) {
        if (invoiceDoc.get("user_id") !== undefined)
          setUserId(invoiceDoc.get("user_id"));
        if (invoiceDoc.get("user_name") !== undefined)
          setUserName(invoiceDoc.get("user_name"));
        if (invoiceDoc.get("area") !== undefined)
          setArea(invoiceDoc.get("area"));
        if (invoiceDoc.get("name") !== undefined)
          setName(invoiceDoc.get("name"));
        if (invoiceDoc.get("room") !== undefined)
          setRoom(invoiceDoc.get("room"));
        if (invoiceDoc.get("completion_date") !== undefined)
          setCompletionDate(invoiceDoc.get("completion_date"));
        if (invoiceDoc.get("cloth") !== undefined)
          setAddCloth(invoiceDoc.get("cloth"));
        if (invoiceDoc.get("waxP") !== undefined)
          setAddWaxP(invoiceDoc.get("waxP"));
        if (invoiceDoc.get("led") !== undefined)
          setAddLed(invoiceDoc.get("led"));
        if (invoiceDoc.get("amido") !== undefined)
          setAddAmido(invoiceDoc.get("amido"));
        if (invoiceDoc.get("otherBuhin") !== undefined)
          setAddOtherBuhon(invoiceDoc.get("otherBuhin"));
        if (invoiceDoc.get("other") !== undefined)
          setAddOther(invoiceDoc.get("other"));
      }
    } catch (e) {
      console.log("searchInvoicesに失敗しました:" + e);
    }
  };

  const searchInvoiceLinks = async (
    team: string,
    user_id: string,
    yyyymm: string
  ) => {
    try {
      const ref = await db
        .collection("invoicelinks")
        .where("team", "==", team)
        .where("user_id", "==", user_id)
        .where("yyyymm", "==", yyyymm)
        .get();

      if (ref.empty) return;
      ref.forEach((doc) => {
        setIsExistLink(true);
        if (doc.get("confirm_date") !== undefined)
          setConfirmDate(fromTimeStampToString(doc.get("confirm_date")));
      });
    } catch (e) {
      console.log("getMaterialsTotalに失敗しました:" + e);
    }
  };

  /*   const isNotExistInvoicelinks = async(team: string, user_id: string, yyyymm: string): Promise<boolean> => {
    try {
      var ref = await db.collection('invoicelinks')
        .where('team', '==', team)
        .where('user_id', '==', user_id)
        .where('yyyymm', '==', yyyymm)
        .get();
      if (ref.empty) return true;
      return false;
    } catch(e) {
      console.log("isExistInvoicelinksに失敗しました:" + e);
      return false;
    }
  }
 */

  const handleClose = () => {
    //reset();
    props.history.goBack();
  };

  const handleChangePlan = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAddPlan(event.target.value as string);
  };

  const handleChangeAircon = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAddAircon(event.target.value as string);
  };

  const handleChangeInterior = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddInterior((event.target as HTMLInputElement).value);
  };

  const onSave: SubmitHandler<Inputs> = async (/* data */) => {
    reset();
    try {
      await db.runTransaction(async (transaction) => {
        const reportRef = db.collection("reports").doc(params.docId);
        await transaction.get(reportRef);
        transaction.set(reportRef, {
          user_name: userName,
          completion_date: completionDate,
          name: name,
          room: room,
          plan: addPlan,
          aircon: addAircon,
          interior: addInterior,
          repo1: addRepo1.toString().replace(/\n/g, ","),
          repo2: addRepo2.toString().replace(/\n/g, ","),
          repo3: addRepo3.toString().replace(/\n/g, ","),
          unConstructed: addUnConstructed.toString().replace(/\n/g, ","),
          comment: addComment.toString().replace(/\n/g, ","),
          comment2: addComment2.toString().replace(/\n/g, ","),
          state: state,
        });
        const invoiceRef = db.collection("invoices").doc(params.docId);
        transaction.update(invoiceRef, {
          plan: addPlan,
          aircon: addAircon,
          cloth: addCloth,
          waxP: addWaxP,
          led: addLed,
          amido: addAmido,
          otherBuhin: addOtherBuhon,
          other: addOther,
        });
      });
    } catch (e) {
      console.log("onSaveに失敗しました:" + e);
    }
  };

  const onRequest: SubmitHandler<Inputs> = async (/* data */) => {
    if (window.confirm("請求情報を正しく入力しましたか？")) {
      reset();
      try {
        await db.runTransaction(async (transaction) => {
          const reportRef = db.collection("reports").doc(params.docId);
          await transaction.get(reportRef);
          transaction.set(reportRef, {
            user_name: userName,
            completion_date: completionDate,
            name: name,
            room: room,
            plan: addPlan,
            aircon: addAircon,
            interior: addInterior,
            repo1: addRepo1.toString().replace(/\n/g, ","),
            repo2: addRepo2.toString().replace(/\n/g, ","),
            repo3: addRepo3.toString().replace(/\n/g, ","),
            unConstructed: addUnConstructed.toString().replace(/\n/g, ","),
            comment: addComment.toString().replace(/\n/g, ","),
            comment2: "",
            state: worksConst.stateName.Si,
          });
          const invoiceRef = db.collection("invoices").doc(params.docId);
          transaction.update(invoiceRef, {
            plan: addPlan,
            aircon: addAircon,
            cloth: addCloth,
            waxP: addWaxP,
            led: addLed,
            amido: addAmido,
            otherBuhin: addOtherBuhon,
            other: addOther,
          });
          const workRef = db.collection("works").doc(params.docId);
          transaction.update(workRef, {
            reportFlg: "1",
            state: worksConst.stateName.Si,
          });
          const infomationRef = db.collection("infomations").doc();
          transaction.set(infomationRef, {
            create_time: timestamp,
            team: authValue.users.team,
            to_user_id: authValue.users.doc_id,
            manager_user_id: "",
            menu: "works",
            text: worksConst.textSi.Manager,
            from_text: worksConst.textSi.User,
            url: "/worksReport/" + params.docId,
            from_user_name: authValue.users.name,
            verification_flg: false,
            info: name + room + "@" + area,
          });
        });
      } catch (e) {
        console.log("onRequestに失敗しました：" + e);
      }
      props.history.goBack();
    }
  };

  const onRemandRequest: SubmitHandler<Inputs> = async (/* data */) => {
    reset();
    try {
      await db.runTransaction(async (transaction) => {
        const reportRef = db.collection("reports").doc(params.docId);
        await transaction.get(reportRef);
        transaction.update(reportRef, {
          comment2: addComment2.toString().replace(/\n/g, ","),
          state: worksConst.stateName.Ni,
        });
        const workRef = db.collection("works").doc(params.docId);
        transaction.update(workRef, {
          state: worksConst.stateName.Ni,
        });
        const infomationRef = db.collection("infomations").doc();
        transaction.set(infomationRef, {
          create_time: timestamp,
          team: authValue.users.team,
          to_user_id: authValue.users.doc_id,
          manager_user_id: "",
          menu: "works",
          text: worksConst.textNi.Manager,
          from_text: worksConst.textNi.User,
          url: "/worksReport/" + params.docId,
          from_user_name: authValue.users.name,
          verification_flg: false,
          info: name + room + "@" + area,
        });
      });
    } catch (e) {
      console.log("onRemandRequestに失敗しました：" + e);
    }
    props.history.goBack();
  };

  const onApproval: SubmitHandler<Inputs> = async (/* data */) => {
    reset();
    try {
      await db.runTransaction(async (transaction) => {
        const reportRef = db.collection("reports").doc(params.docId);
        await transaction.get(reportRef);
        transaction.update(reportRef, {
          comment: "",
          state: worksConst.stateName.Ok,
        });
        const workRef = db.collection("works").doc(params.docId);
        transaction.update(workRef, {
          state: worksConst.stateName.Ok,
        });
        const infomationRef = db.collection("infomations").doc();
        transaction.set(infomationRef, {
          create_time: timestamp,
          team: authValue.users.team,
          to_user_id: userId,
          manager_user_id: authValue.users.doc_id,
          menu: "works",
          text: worksConst.textOk.User,
          from_text: worksConst.textOk.Manager,
          url: "/worksReport/" + params.docId,
          from_user_name: authValue.users.name,
          verification_flg: false,
          info: name + room + "@" + area,
        });
        // invoicelinksに存在しない場合のみ新規追加する
        /* if (await isNotExistInvoicelinks(authValue.users.team, userId, getTargetMonth(completionDate))) { */
        if (!isExistLink) {
          const invoicelinkRef = db.collection("invoicelinks").doc();
          transaction.set(invoicelinkRef, {
            team: authValue.users.team,
            user_id: userId,
            yyyymm: getTargetMonth(completionDate),
            user_name: userName,
          });
        }
      });
    } catch (e) {
      console.log("onApprovalに失敗しました：" + e);
    }
    props.history.goBack();
  };

  const onRemand: SubmitHandler<Inputs> = async (data) => {
    if (data.comment.trim() === "") {
      alert("差戻しするときは差戻しコメントを入力してください");
      return;
    }
    reset();
    try {
      await db.runTransaction(async (transaction) => {
        const reportRef = db.collection("reports").doc(params.docId);
        await transaction.get(reportRef);
        transaction.update(reportRef, {
          comment: addComment.toString().replace(/\n/g, ","),
          state: worksConst.stateName.Ng,
        });
        const workRef = db.collection("works").doc(params.docId);
        transaction.update(workRef, {
          state: worksConst.stateName.Ng,
        });
        const infomationRef = db.collection("infomations").doc();
        transaction.set(infomationRef, {
          create_time: timestamp,
          team: authValue.users.team,
          to_user_id: userId,
          manager_user_id: authValue.users.doc_id,
          menu: "works",
          text: worksConst.textNg.User,
          from_text: worksConst.textNg.Manager,
          url: "/worksReport/" + params.docId,
          from_user_name: authValue.users.name,
          verification_flg: false,
          info: name + room + "@" + area,
        });
      });
    } catch (e) {
      console.log("onRemandに失敗しました：" + e);
    }
    props.history.goBack();
  };

  const ViewButtons = () => {
    var disabledUser: boolean = true;
    var disabledManager: boolean = true;
    disabledUser =
      state === worksConst.stateName.Si ||
      state === worksConst.stateName.Ok ||
      state === worksConst.stateName.Ni;
    if (
      area !== "" &&
      name !== "" &&
      room !== "" &&
      state === worksConst.stateName.Si
    )
      disabledManager = false;

    if (authValue.users.role === usersConst.roleName.User) {
      return (
        <>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onSave)}
            color="primary"
            disabled={disabledUser}
          >
            保存
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            戻る
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onRequest)}
            color="secondary"
            //type="submit"
            disabled={disabledUser}
          >
            承認依頼
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onRemandRequest)}
            color="secondary"
            disabled={
              !(state === worksConst.stateName.Ok) || confirmDate !== ""
            }
          >
            差戻依頼
          </Button>
        </>
      );
    } else if (authValue.users.role === usersConst.roleName.Manager) {
      return (
        <>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            戻る
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onApproval)}
            color="secondary"
            disabled={disabledManager}
          >
            承認
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onRemand)}
            color="secondary"
            disabled={disabledManager && !(state === worksConst.stateName.Ni)}
          >
            差戻し
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            戻る
          </Button>
        </>
      );
    }
  };

  return (
    <>
      {loading ? (
        <div>LOADING.....</div>
      ) : (
        <ResponsiveDrawer title="REPORT">
          <form className={classes.root} noValidate autoComplete="off">
            <Divider className={classes.divider} />
            <div>{area + name + room}</div>
            <div>
              <FormControl className={classes.formControlPlan}>
                <InputLabel id="select-plan-label">間取り</InputLabel>
                <Select
                  labelId="select-plan-label"
                  id="select-plan"
                  value={addPlan}
                  {...register("plan", {
                    required: addPlan === "" ? true : false,
                  })}
                  error={addPlan === "" ? Boolean(errors.plan) : false}
                  onChange={handleChangePlan}
                  disabled={baseDisabled}
                >
                  {Object.keys(worksConst.plan).map((key) => (
                    <MenuItem value={worksConst.plan[key]}>
                      {worksConst.plan[key]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {[
                    errors.plan &&
                      errors.plan.type === "required" &&
                      "選択必須です",
                  ]}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formControlAircon}>
                <InputLabel id="select-aircon-label">エアコン</InputLabel>
                <Select
                  labelId="select-aircon-label"
                  id="select-aircon"
                  value={addAircon}
                  {...register("aircon", {
                    required: addAircon === "" ? true : false,
                  })}
                  error={addAircon === "" ? Boolean(errors.aircon) : false}
                  onChange={handleChangeAircon}
                  disabled={baseDisabled}
                >
                  {Object.keys(worksConst.airconName).map((key) => (
                    <MenuItem value={worksConst.airconName[key]}>
                      {worksConst.airconName[key]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {[
                    errors.aircon &&
                      errors.aircon.type === "required" &&
                      "選択必須です",
                  ]}
                </FormHelperText>
              </FormControl>

              <FormControl
                component="fieldset"
                className={classes.formControlInterior}
              >
                <FormLabel component="legend">内装工事</FormLabel>
                <RadioGroup
                  row
                  aria-label="radio-group-interior"
                  name="radio-group-interior"
                  value={addInterior}
                  defaultValue={addInterior}
                  onChange={handleChangeInterior}
                >
                  <FormControlLabel
                    value="無し"
                    control={<Radio color="default" disabled={baseDisabled} />}
                    label="無し"
                  />
                  <FormControlLabel
                    value="未施工"
                    control={<Radio color="default" disabled={baseDisabled} />}
                    label="未施工"
                  />
                  <FormControlLabel
                    value="施工済"
                    control={<Radio color="default" disabled={baseDisabled} />}
                    label="施工済"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div>
              <TextField
                className={classes.textfield}
                id="text-repo1"
                label="指示書記載事項報告"
                multiline
                rowsMax={4}
                value={addRepo1.toString().replace(/,/g, "\n")}
                aria-invalid={errors.repo1 ? "true" : "false"}
                {...register("repo1", {
                  maxLength: 200,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.repo1)}
                helperText={[
                  errors.repo1 &&
                    errors.repo1.type === "maxLength" &&
                    "200文字以内で入力してください",
                  errors.repo1 &&
                    errors.repo1.type === "pattern" &&
                    "禁則文字が使用されています",
                ]}
                onChange={(event) => {
                  setAddRepo1(event.target.value);
                  setValue("repo1", event.target.value);
                }}
                disabled={baseDisabled}
              />
            </div>
            <div>
              <TextField
                className={classes.textfield}
                id="text-repo2"
                label="指示書無記載事項報告"
                multiline
                rowsMax={4}
                value={addRepo2.toString().replace(/,/g, "\n")}
                aria-invalid={errors.repo2 ? "true" : "false"}
                {...register("repo2", {
                  maxLength: 200,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.repo2)}
                helperText={[
                  errors.repo2 &&
                    errors.repo2.type === "maxLength" &&
                    "200文字以内で入力してください",
                  errors.repo2 &&
                    errors.repo2.type === "pattern" &&
                    "禁則文字が使用されています",
                ]}
                onChange={(event) => {
                  setAddRepo2(event.target.value);
                  setValue("repo2", event.target.value);
                }}
                disabled={baseDisabled}
              />
            </div>
            <div>
              <TextField
                className={classes.textfield}
                id="text-repo3"
                label="気付き箇所報告"
                multiline
                rowsMax={4}
                value={addRepo3.toString().replace(/,/g, "\n")}
                aria-invalid={errors.repo3 ? "true" : "false"}
                {...register("repo3", {
                  maxLength: 200,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.repo3)}
                helperText={[
                  errors.repo3 &&
                    errors.repo3.type === "maxLength" &&
                    "200文字以内で入力してください",
                  errors.repo3 &&
                    errors.repo3.type === "pattern" &&
                    "禁則文字が使用されています",
                ]}
                onChange={(event) => {
                  setAddRepo3(event.target.value);
                  setValue("repo3", event.target.value);
                }}
                disabled={baseDisabled}
              />
            </div>
            <div>
              <TextField
                className={classes.textfield}
                id="text-un-constructed"
                label="未施工"
                multiline
                rowsMax={4}
                value={addUnConstructed.toString().replace(/,/g, "\n")}
                aria-invalid={errors.unConstructed ? "true" : "false"}
                {...register("unConstructed", {
                  maxLength: 50,
                  pattern: /[^!"#$%&'()*+\-.,/:;<=>?[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.unConstructed)}
                helperText={[
                  errors.unConstructed &&
                    errors.unConstructed.type === "maxLength" &&
                    "50文字以内で入力してください",
                  errors.unConstructed &&
                    errors.unConstructed.type === "pattern" &&
                    "禁則文字が使用されています",
                ]}
                onChange={(event) => {
                  setAddUnConstructed(event.target.value);
                  setValue("unConstructed", event.target.value);
                }}
                disabled={baseDisabled}
              />
            </div>
            <Divider className={classes.divider} />
            <div>請求情報</div>

            <div>
              <TextField
                className={classes.textfieldInvoice1}
                id="text-cloth"
                label="クロスⅭ"
                type="number"
                value={addCloth}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">㎡</InputAdornment>
                  ),
                }}
                aria-invalid={errors.cloth ? "true" : "false"}
                {...register("cloth", {
                  required: false,
                  maxLength: 5,
                  pattern: /\d+(?:\.\d+)?/,
                })}
                error={Boolean(errors.cloth)}
                helperText={[
                  errors.cloth &&
                    errors.cloth.type === "maxLength" &&
                    "5桁以内で入力してください",
                  errors.cloth &&
                    errors.cloth.type === "pattern" &&
                    "平米数を実数で入力してください",
                ]}
                onChange={(event) => {
                  setAddCloth(event.target.value);
                  setValue("cloth", event.target.value);
                }}
                disabled={baseDisabled}
              />

              <TextField
                className={classes.textfieldInvoice2}
                id="text-waxP"
                label="ワックス剝離"
                type="number"
                value={addWaxP}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">㎡</InputAdornment>
                  ),
                }}
                aria-invalid={errors.waxP ? "true" : "false"}
                {...register("waxP", {
                  required: false,
                  maxLength: 5,
                  pattern: /\d+(?:\.\d+)?/,
                })}
                error={Boolean(errors.waxP)}
                helperText={[
                  errors.waxP &&
                    errors.waxP.type === "maxLength" &&
                    "5桁以内で入力してください",
                  errors.waxP &&
                    errors.waxP.type === "pattern" &&
                    "平米数を実数で入力してください",
                ]}
                onChange={(event) => {
                  setAddWaxP(event.target.value);
                  setValue("waxP", event.target.value);
                }}
                disabled={baseDisabled}
              />

              <TextField
                className={classes.textfieldInvoice1}
                id="text-led"
                label="LED等交換"
                type="number"
                value={addLed}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                aria-invalid={errors.led ? "true" : "false"}
                {...register("led", {
                  required: false,
                  maxLength: 5,
                  pattern: /^[0-9]+$/,
                })}
                error={Boolean(errors.led)}
                helperText={[
                  errors.led &&
                    errors.led.type === "maxLength" &&
                    "5桁以内で入力してください",
                  errors.led &&
                    errors.led.type === "pattern" &&
                    "金額を整数で入力してください",
                ]}
                onChange={(event) => {
                  setAddLed(event.target.value);
                  setValue("led", event.target.value);
                }}
                disabled={baseDisabled}
              />

              <TextField
                className={classes.textfieldInvoice1}
                id="text-amido"
                label="網戸張替"
                type="number"
                value={addAmido}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                aria-invalid={errors.amido ? "true" : "false"}
                {...register("amido", {
                  required: false,
                  maxLength: 5,
                  pattern: /^[0-9]+$/,
                })}
                error={Boolean(errors.amido)}
                helperText={[
                  errors.amido &&
                    errors.amido.type === "maxLength" &&
                    "5桁以内で入力してください",
                  errors.amido &&
                    errors.amido.type === "pattern" &&
                    "金額を整数で入力してください",
                ]}
                onChange={(event) => {
                  setAddAmido(event.target.value);
                  setValue("amido", event.target.value);
                }}
                disabled={baseDisabled}
              />

              <TextField
                className={classes.textfieldInvoice2}
                id="text-otherBuhin"
                label="その他部品交換"
                type="number"
                value={addOtherBuhon}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                aria-invalid={errors.otherBuhin ? "true" : "false"}
                {...register("otherBuhin", {
                  required: false,
                  maxLength: 5,
                  pattern: /^[0-9]+$/,
                })}
                error={Boolean(errors.otherBuhin)}
                helperText={[
                  errors.otherBuhin &&
                    errors.otherBuhin.type === "maxLength" &&
                    "5桁以内で入力してください",
                  errors.otherBuhin &&
                    errors.otherBuhin.type === "pattern" &&
                    "金額を整数で入力してください",
                ]}
                onChange={(event) => {
                  setAddOtherBuhon(event.target.value);
                  setValue("otherBuhin", event.target.value);
                }}
                disabled={baseDisabled}
              />

              <TextField
                className={classes.textfieldInvoice1}
                id="text-other"
                label="その他"
                type="number"
                value={addOther}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                aria-invalid={errors.other ? "true" : "false"}
                {...register("other", {
                  required: false,
                  maxLength: 5,
                  pattern: /^[0-9]+$/,
                })}
                error={Boolean(errors.other)}
                helperText={[
                  errors.other &&
                    errors.other.type === "maxLength" &&
                    "5桁以内で入力してください",
                  errors.other &&
                    errors.other.type === "pattern" &&
                    "金額を整数で入力してください",
                ]}
                onChange={(event) => {
                  setAddOther(event.target.value);
                  setValue("other", event.target.value);
                }}
                disabled={baseDisabled}
              />
            </div>

            <Divider className={classes.divider} />
            <div>承認／差戻し</div>

            <div>
              <TextField
                className={classes.textfield}
                id="text-comment"
                label="差戻しコメント"
                multiline
                rowsMax={4}
                value={addComment.toString().replace(/,/g, "\n")}
                aria-invalid={errors.comment ? "true" : "false"}
                {...register("comment", {
                  maxLength: 200,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.comment)}
                helperText={[
                  errors.comment &&
                    errors.comment.type === "maxLength" &&
                    "200文字以内で入力してください",
                  errors.comment &&
                    errors.comment.type === "pattern" &&
                    "禁則文字が使用されています",
                ]}
                onChange={(event) => {
                  setAddComment(event.target.value);
                  setValue("comment", event.target.value);
                }}
                disabled={
                  !baseDisabled ||
                  state === worksConst.stateName.Ok ||
                  (state === worksConst.stateName.Si &&
                    authValue.users.role === usersConst.roleName.User) ||
                  (state === worksConst.stateName.Ni &&
                    authValue.users.role === usersConst.roleName.User) ||
                  (state === worksConst.stateName.Ng &&
                    authValue.users.role === usersConst.roleName.Manager)
                }
              />
            </div>

            <div>
              <TextField
                className={classes.textfield}
                id="text-comment2"
                label="差戻し依頼コメント"
                multiline
                rowsMax={4}
                value={addComment2.toString().replace(/,/g, "\n")}
                aria-invalid={errors.comment2 ? "true" : "false"}
                {...register("comment2", {
                  required:
                    true &&
                    authValue.users.role === usersConst.roleName.User &&
                    state === worksConst.stateName.Ok,
                  maxLength: 200,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.comment2)}
                helperText={[
                  errors.comment2 &&
                    errors.comment2.type === "required" &&
                    "差戻し依頼時、入力必須です",
                  errors.comment2 &&
                    errors.comment2.type === "maxLength" &&
                    "200文字以内で入力してください",
                  errors.comment2 &&
                    errors.comment2.type === "pattern" &&
                    "禁則文字が使用されています",
                ]}
                onChange={(event) => {
                  setAddComment2(event.target.value);
                  setValue("comment2", event.target.value);
                }}
                disabled={
                  !(
                    authValue.users.role === usersConst.roleName.User &&
                    state === worksConst.stateName.Ok
                  )
                }
              />
            </div>

            <Divider className={classes.divider} />
            <ViewButtons />
          </form>
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default WorksReport;
