import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import firebase, { db, timestamp } from '../../../config/firebase';
import ResponsiveDrawer from '../../templates/ResponsiveDrawer';
import { AuthContext } from '../../../store/AuthContext';
import { ConfigContext } from '../../../store/ConfigContext';
import { usersConst, materialConst } from '../../../config/Const';
import { fromTimeStampToDate } from '../../../common/date';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  Typography,
  Container
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ReportIcon from '@material-ui/icons/Description'
import PdfIcon from '@material-ui/icons/PictureAsPdf'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import MaterialsState from './components/MaterialsState';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  container: {
    minWidth: '100vh',
    textAlign: 'right',
  },
  icon: {
    color: '#3F51B5',
  },
  iconrep: {
    color: 'magenta',
  },
}));

const Materials: React.FC = (props: any) => {
  const classes = useStyles();
  const configValue = useContext(ConfigContext);
  const authValue = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [materials, setMaterials] = useState<firebase.firestore.DocumentData[]>([]);

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
  }, [authValue.currentUser]);

  useEffect(() => {
    searchMaterials();
    setLoading(false);
  }, []);

  const searchMaterials = async () => {
    try {
      var ref;
      // NOTE: FirebaseのWebコンソールより手動で複合インデックスを設定
      // NOTE: index1（1:team 昇順 2:createTime 降順）、コレクション
      // NOTE: index1（1:team 昇順 2:user_id 昇順 3:createTime 降順）、コレクション
      // NOTE: index2（1:team 昇順 2:state 昇順 3:offerTime 降順）、コレクション
      if (authValue.users.role === usersConst.roleName.Admin) {
        ref = await db.collection('materials')
          .where('team', '==', authValue.users.team)
          .orderBy('createTime', 'desc')
          .get();
      } else if (authValue.users.role === usersConst.roleName.Manager) {
        ref = await db.collection('materials')
          .where('team', '==', authValue.users.team)
          .where('state', '==', materialConst.state.KonyuIrai)
          .orderBy('offerTime', 'desc')
          .get();
      } else {
        ref = await db.collection('materials')
          .where('team', '==', authValue.users.team)
          .where('user_id', '==', authValue.users.doc_id)
          .orderBy('createTime', 'desc')
          .get();
      }

      if (ref.empty) return [];
      const materialList: firebase.firestore.DocumentData[] = [];
      ref.forEach(doc => {
        materialList.push({
          doc_id: doc.id,
          ...doc.data(),
        });
      });
      setMaterials(materialList);

    } catch (e) {
      console.log("searchMaterialsに失敗しました：" + e);
    }
  }

  const handleDelete = async (doc_id: string) => {
    if (window.confirm("削除しますか？")) {
      try {
        await db.collection('materials').doc(doc_id).delete();
        // 初期化
        setMaterials([]);
        searchMaterials();
      } catch (e) {
        console.log("削除に失敗しました：" + e);
      }
    }
  };

  const handlePurchase = async (doc_id: string) => {
    if (window.confirm("買取依頼しますか？\n取り消しはできません")) {
      try {
        await db.runTransaction(async transaction => {
          const materialRef = db.collection('materials').doc(doc_id);
          await transaction.get(materialRef);
          transaction.update(materialRef, {
            offerTime: timestamp,
            state: materialConst.state.KonyuIrai,
          });
          const infomationRef = db.collection('infomations').doc();
          transaction.set(infomationRef, {
            create_time: timestamp,
            team: authValue.users.team,
            to_user_id: authValue.users.doc_id,
            manager_user_id: "",
            menu: 'materials',
            text: materialConst.text.Manager,
            from_text: materialConst.text.User,
            url: '/materialsEdit/view/' + doc_id,
            from_user_name: authValue.users.name,
            verification_flg: false,
          });
        });
        searchMaterials();
      } catch (e) {
        console.log("handlePurchaseに失敗しました：" + e);
      }
    }
  };

  const handleAddOpen = () => {
    props.history.push("/materialsEdit/add/");
  };

  const handleEditOpen = (id: any) => {
    props.history.push("/materialsEdit/edit/" + id);
  };

  const handleViewOpen = (id: any) => {
    props.history.push("/materialsEdit/view/" + id);
  };

  const AddButtonGroup = () => {
    if (authValue.users.role === usersConst.roleName.User) {
      return (
        <>
          <Container className={classes.container}>
            <Button onClick={() => handleAddOpen()}>
              <AddCircleIcon className={classes.icon} fontSize="large" />
              <Typography>追加</Typography>
            </Button>
          </Container>
        </>
      );
    } else {
      return (<></>);
    }
  }

  const EditButtonGroup = (props: any) => {
    if (authValue.users.role === usersConst.roleName.User) {
      return (
        <>
          <Button
            onClick={() => handleEditOpen(props.doc_id)}
            disabled={(props.state === materialConst.state.KonyuIrai)}
          >
            <EditIcon className={classes.icon} fontSize="large" />
            <Typography>編集</Typography>
          </Button>
          <Button
            onClick={() => handleDelete(props.doc_id)}
            disabled={(props.state === materialConst.state.KonyuIrai)}
          >
            <DeleteIcon className={classes.icon} fontSize="large" />
            <Typography>削除</Typography>
          </Button>
        </>
      );
    } else {
      return (<></>);
    }
  }

  const PurchaseButton = (props: any) => {
    if (authValue.users.role === usersConst.roleName.User) {
      return (
        <>
          <Button
            onClick={() => handlePurchase(props.doc_id)}
            disabled={(props.state === materialConst.state.KonyuIrai)}
          >
            <CheckIcon className={classes.icon} fontSize="large" />
            <Typography>買取依頼</Typography>
          </Button>
        </>
      );
    } else {
      return (<></>);
    }
  }

  const PdfLink = (props: any) => {
    if (props.state === materialConst.state.KonyuIrai) {
      return (
        <>
          <Link to={'/materialsPdf/' + props.doc_id} target="_blank">
            <IconButton className={classes.iconrep} size="medium">
              <PdfIcon />
            </IconButton>
          </Link>
        </>
      );
    } else {
      return (<></>);
    }
  }

  return (
    <>
      {
        loading ? (
          <div>LOADING.....</div>
        ) :
          <ResponsiveDrawer title={configValue.lang.materials}>
            <AddButtonGroup />
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">部材買取依頼表</TableCell>
                    <TableCell align="left">作成日時</TableCell>
                    <TableCell align="left">買取依頼日時</TableCell>
                    {
                      (authValue.users.role === usersConst.roleName.Manager
                        || authValue.users.role === usersConst.roleName.Admin) ?
                        <TableCell align="left">作成者</TableCell> : <></>
                    }
                    <TableCell align="left">状態</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materials.map((row) => (
                    <TableRow hover key={row.doc_id}>
                      <TableCell align="left">
                        <IconButton
                          className={classes.iconrep}
                          size="medium"
                          onClick={() => handleViewOpen(row.doc_id)}
                          disabled={!(row.state === materialConst.state.KonyuIrai)}
                        >
                          <ReportIcon />
                        </IconButton>
                        <PdfLink doc_id={row.doc_id} state={row.state} />
                      </TableCell>
                      <TableCell align="left">{fromTimeStampToDate(row.createTime)}</TableCell>
                      <TableCell align="left">{fromTimeStampToDate(row.offerTime)}</TableCell>
                      {
                        (authValue.users.role === usersConst.roleName.Manager
                          || authValue.users.role === usersConst.roleName.Admin) ?
                          <TableCell align="left">{row.user_name}</TableCell> : <></>
                      }
                      <TableCell align="center">
                        <MaterialsState>{row.state}</MaterialsState>
                      </TableCell>
                      <TableCell align="left">
                        <PurchaseButton doc_id={row.doc_id} state={row.state} />
                      </TableCell>
                      <TableCell align="right">
                        <EditButtonGroup doc_id={row.doc_id} state={row.state} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ResponsiveDrawer>
      }
    </>
  );
}

export default Materials;