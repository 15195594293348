import React, { useEffect, useContext, useState } from 'react';
import firebase, { db, timestamp } from '../../../config/firebase';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../store/AuthContext';
import { ConfigContext } from '../../../store/ConfigContext';
import { usersConst, invoicesConst } from '../../../config/Const';
import { dateToString, getBillingDate } from '../../../common/date';
import ResponsiveDrawer from '../../templates/ResponsiveDrawer';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReportIcon from '@material-ui/icons/Report'
import PdfIcon from '@material-ui/icons/PictureAsPdf'

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  divider2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    height: 25,
  },
  iconButton: {
    height: 25,
  },
  icon: {
    color: '#3F51B5',
  },
  iconrep: {
    color: 'magenta',
  },
  col1: {
    display: 'inline-block',
    width: 300,
  },
  col2: {
    display: 'inline-block',
    width: 120,
  },
  col3: {
    display: 'inline-block',
    width: 600,
  },
}));

const Invoices: React.FC = (props: any) => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const configValue = useContext(ConfigContext);
  const [loading, setLoading] = useState(true);
  const [invoicelinks, setInvoicelinks] = useState<firebase.firestore.DocumentData[]>([]);

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
  }, [authValue.currentUser])

  useEffect(() => {
    searchInvoicelinks();
    setLoading(false);
  }, []);

  const searchInvoicelinks = async () => {
    try {
      var ref;
      // NOTE: FirebaseのWebコンソールより手動で複合インデックスを設定
      // NOTE: index1（1:team 昇順 2:yyyymm 降順 3:user_id 昇順）、コレクション
      // NOTE: index1（1:team 昇順 2:user_id 昇順 3:yyyymm 降順）、コレクション
      if (authValue.users.role === usersConst.roleName.Manager
        || authValue.users.role === usersConst.roleName.Admin) {
        ref = await db.collection('invoicelinks')
          .where('team', '==', authValue.users.team)
          .orderBy('yyyymm', 'desc')
          .orderBy('user_id', 'asc')
          .get();
      } else {
        ref = await db.collection('invoicelinks')
          .where('team', '==', authValue.users.team)
          .where('user_id', '==', authValue.users.doc_id)
          .orderBy('yyyymm', 'desc')
          .get();
      }

      if (ref.empty) return [];
      const invoicelinksList: firebase.firestore.DocumentData[] = [];
      var d: string = '';
      ref.forEach(doc => {
        if (d !== '' && d !== doc.get('yyyymm')) {
          invoicelinksList.push({ doc_id: '', team: '', user_id: '', yyyymm: '', user_name: '', confirm_date: '' });
        }
        invoicelinksList.push({
          doc_id: doc.id,
          ...doc.data(),
        });
        d = doc.get('yyyymm');
      });
      setInvoicelinks(invoicelinksList);

    } catch (e) {
      console.log("searchInvoicelinksに失敗しました：" + e);
    }
  }

  const handleComfirm = async (doc_id: string) => {
    if (window.confirm("確定しますか？\n取り消しはできません")) {
      try {
        await db.runTransaction(async transaction => {
          const invoicelinksRef = db.collection('invoicelinks').doc(doc_id);
          await transaction.get(invoicelinksRef);
          transaction.set(invoicelinksRef, {
            confirm_date: timestamp,
          }, { merge: true });
          const infomationRef = db.collection('infomations').doc();
          transaction.set(infomationRef, {
            create_time: timestamp,
            team: authValue.users.team,
            to_user_id: authValue.users.doc_id,
            manager_user_id: "",
            menu: 'invoices',
            text: invoicesConst.text.Manager,
            from_text: invoicesConst.text.User,
            url: '/invoices/',
            from_user_name: authValue.users.name,
            verification_flg: false,
          });
        });
        searchInvoicelinks();
      } catch (e) {
        console.log("handleComfirmに失敗しました：" + e);
      }
    }
  };

  const PdfLink = (props: any) => {
    return (
      <span className={classes.col1}>
        <Link
          to={'/invoicesViewer/' + encodeURIComponent(props.doc_id)}
          target="_blank"
        >
          <IconButton className={classes.iconrep} size="small">
            <PdfIcon />
          </IconButton>
        </Link>
        {props.yyyymm + ' ' + props.user_name}
      </span>
    );
  }

  const ConfirmButton = (props: any) => {
    const disabled: boolean = (props.yyyymm + '26' > dateToString(new Date(), 'YYYYMMDD'));
    return (
      <>
        <span className={classes.col2}>
          <Typography color={(props.confirm_date === undefined) ? 'secondary' : 'primary'}>{(props.confirm_date === undefined) ? '未確定' : '確定済み'}</Typography>
        </span>
        {(props.confirm_date === undefined) ?
          <>
            <span className={classes.col2}>
              <Button
                className={classes.button}
                onClick={() => handleComfirm(props.doc_id)}
                disabled={disabled}
              >
                <CheckIcon className={classes.icon} fontSize="small" />
                <Typography>確定する</Typography>
              </Button>
            </span>
            <span className={classes.col3}>
              <Typography color="secondary" variant="caption">請求書の内容が正しいことを確認し、{getBillingDate(props.yyyymm).substr(4, 2) + '/10'}までに確定してください</Typography>
            </span>
          </>
          : <></>}
      </>
    );
  }

  const ConfirmText = (props: any) => {
    return (
      <>
        <span className={classes.col2}>
          {(props.confirm_date !== undefined) ?
            <IconButton className={classes.iconButton} disabled>
              <CheckIcon className={classes.icon} fontSize="small" />
              <Typography color="primary">確定済み</Typography>
            </IconButton>
            : <IconButton className={classes.iconButton} disabled>
              <ReportIcon className={classes.iconrep} fontSize="small" />
              <Typography color="secondary">未確定</Typography>
            </IconButton>}
        </span>
        <span className={classes.col3}>
          {(props.confirm_date === undefined) ?
            <Typography color="secondary" variant="caption">作業者が請求書の確定をしていません。未承認の完了報告書がある場合は、承認をしてください。</Typography>
            : <></>}
        </span>
      </>
    );
  }

  return (
    <>
      {
        loading ? (
          <div>LOADING.....</div>
        ) :
          <ResponsiveDrawer title={configValue.lang.invoices}>
            <Divider className={classes.divider} />
            <div>
              <span className={classes.col1}>請求書</span>
              <span className={classes.col2}>状態</span>
              <span className={classes.col3}>
                {(authValue.users.role === usersConst.roleName.User)
                  ? '毎月26日から翌月10日までの期間に請求書を確定してください'
                  : '毎月26日から翌月10日までの期間に作業者が請求書を確定する必要があります'}
              </span>
            </div>
            <Divider className={classes.divider2} />
            {/* TODO: encodeURI()?? */}
            {invoicelinks.map((row) => (
              row.doc_id !== '' ?
                <div>
                  <PdfLink
                    doc_id={row.doc_id}
                    user_id={row.user_id}
                    yyyymm={row.yyyymm}
                    user_name={row.user_name}
                    confirm_date={row.confirm_date}
                  />
                  {(authValue.users.role === usersConst.roleName.User)
                    ? <ConfirmButton doc_id={row.doc_id} confirm_date={row.confirm_date} yyyymm={row.yyyymm} />
                    : <ConfirmText confirm_date={row.confirm_date} />}
                </div>
                :
                <>&nbsp;</>
            ))}
          </ResponsiveDrawer>
      }
    </>
  );
}

export default Invoices;