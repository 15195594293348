import React, { useContext, useState } from 'react';
import { db } from '../config/firebase';
import Config from '../config/Config';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { AuthContext } from '../store/AuthContext'

const ConfigContext = React.createContext({
	lang: Config.language.en,
	changeLang: (v: number) => {},
	blightness: Config.blightness.light.isDark,
	handleIsDark: () => {},
});

const ConfigProvider = (props: any) => {
	const authValue = useContext(AuthContext);
	const [lang, setLang] = useState(
		(authValue.users.lang===Config.language.en.id) ? 
			Config.language.en : Config.language.ja
	);
	const [isDark, setIsDark] = useState(
		(authValue.users.is_dark) ?
		Config.blightness.dark.isDark : Config.blightness.light.isDark
	);
	
	const changeLang = (v: number) => {
		let l: string;
		if (v === Config.language.ja.value) {
			setLang(Config.language.ja);
			l = Config.language.ja.id;
		} else {
			setLang(Config.language.en);
			l = Config.language.en.id;
		}
		updateLang(l);
	};
  
	const handleIsDark = () => {
		updateIsDark(!isDark);
		isDark === Config.blightness.light.isDark ? setIsDark(Config.blightness.dark.isDark) : setIsDark(Config.blightness.light.isDark);
	};
	
	const theme = createMuiTheme({
		palette: {
			primary: {
				main: "#3B5999",
			},
			type: (isDark === Config.blightness.dark.isDark) ? "dark" : "light",
		},
	});
  
	const updateLang = async(lang: string) => {
		try {
			const ref = db.collection('users').doc(authValue.users.doc_id);
			await ref.set({lang: lang}, {merge: true});
		} catch(e) {
			console.log("langの更新に失敗しました：" + e);
		}
	};

	const updateIsDark = async(isDark: boolean) => {
		try {
			const ref = db.collection('users').doc(authValue.users.doc_id);
			await ref.set({is_dark: isDark}, {merge: true});
		} catch(e) {
			console.log("is_darkの更新に失敗しました：" + e);
		}
	};

	return (
		<>
		<MuiThemeProvider theme={theme}>
			<ConfigContext.Provider 
				value={{
					lang: lang, 
					changeLang: changeLang, 
					blightness: isDark, 
					handleIsDark: handleIsDark,
				}}>
				{props.children}
			</ConfigContext.Provider>
		</MuiThemeProvider>
		</>
	);
}

export { ConfigContext, ConfigProvider };