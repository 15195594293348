import { worksConst } from "../config/Const";

export function rowDisabledWorksEdit(user_id: string, state: string, current_user_id?: string) {
	if (!user_id || !state) return true;
	if (user_id===current_user_id 
		&& (state===worksConst.stateName.Mi 
			|| state===worksConst.stateName.Sa
			|| state===worksConst.stateName.Pe
			|| state===worksConst.stateName.Ka
			|| state===worksConst.stateName.Ng)) {
		return false;
	} else {
		return true;
	}
}

export function rowDisabledWorksReport(reportFlg: string, state: string) {
	if (!reportFlg || !state) return true;
	if (reportFlg==='1' 
		&& (state===worksConst.stateName.Ka
			|| state===worksConst.stateName.Si
			|| state===worksConst.stateName.Ok
			|| state===worksConst.stateName.Ng)) {
		return false;
	} else {
		return true;
	}
}

export function rowDisabledWorksReportEdit(state: string) {
	//if (!state) return true;
	if (state===worksConst.stateName.Ka	
		|| state===worksConst.stateName.Si 
		|| state===worksConst.stateName.Ok 
		|| state===worksConst.stateName.Ng 
		|| state===worksConst.stateName.Ni) {
		return false;
	} else {
		return true;
	}
}

export function userDisabledWorksReport(user_id: string, state: string, current_user_id?: string) {
	if (!user_id || !state) return true;
	if (user_id===current_user_id 
		&& (state===worksConst.stateName.Ka 
			|| state===worksConst.stateName.Ng)) {
		return false;
	} else {
		return true;
	}
}
