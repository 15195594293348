import React, { useContext, useEffect, useState, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { db } from "../../../config/firebase";
import { CSVLink } from "react-csv";
import { getStartDay, getEndDay } from "../../../common/date";
import { usersConst } from "../../../config/Const";
import ResponsiveDrawer from "../../templates/ResponsiveDrawer";
import { AuthContext } from "../../../store/AuthContext";
import { ConfigContext } from "../../../store/ConfigContext";
import {
  DataObj,
  DataObjUser,
  headers,
  headersUser,
} from "./components/exportsDataObj";
import {
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
} from "@material-ui/core";
import CsvIcon from "@material-ui/icons/Description";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100ch",
      marginBottom: 20,
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: "30ch",
  },
  formControlPeriod: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    width: "30ch",
  },
  textfield: {
    marginLeft: theme.spacing(4),
    width: "30ch",
  },
  divCnt: {
    marginLeft: theme.spacing(6),
    width: "30ch",
  },
  icon: {
    color: "#3F51B5",
  },
  iconcsv: {
    color: "magenta",
  },
  link: {
    marginLeft: theme.spacing(2),
  },
}));

type Inputs = {
  selectMonth: string;
};

const Exports: React.FC = (props: any) => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const configValue = useContext(ConfigContext);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [loading, setLoading] = useState(false);
  const [selectPeriod, setSelectPeriod] = useState("年月度");
  //const selectMonth = "";
  const [selectedMonth, setSelectedMonth] = useState("");
  const [cnt, setCnt] = useState("");
  const [fetchDone, setFetchDone] = useState(false);
  const [data, setData] = useState<DataObj[]>([]);
  const [dataUser, setDataUser] = useState<DataObjUser[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      selectMonth: selectedMonth,
    },
  });

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authValue.currentUser]);

  useEffect(() => {
    setLoading(true);
    const getWorksCnt = async (yyyymm: string) => {
      try {
        let workRef;
        if (yyyymm === "") {
          if (authValue.users.role !== usersConst.roleName.User) {
            workRef = db
              .collection("works")
              .where("team", "==", authValue.users.team);
          } else {
            workRef = db
              .collection("works")
              .where("team", "==", authValue.users.team)
              .where("user_id", "==", authValue.users.doc_id);
          }
        } else {
          if (authValue.users.role !== usersConst.roleName.User) {
            workRef = db
              .collection("works")
              .where("team", "==", authValue.users.team)
              .where("completion_date", ">=", getStartDay(yyyymm))
              .where("completion_date", "<=", getEndDay(yyyymm));
          } else {
            workRef = db
              .collection("works")
              .where("team", "==", authValue.users.team)
              .where("user_id", "==", authValue.users.doc_id)
              .where("completion_date", ">=", getStartDay(yyyymm))
              .where("completion_date", "<=", getEndDay(yyyymm));
          }
        }
        const workDoc = await workRef.get();
        if (workDoc.empty) {
          setCnt("0");
        }
        setCnt(workDoc.docs.length.toString());
      } catch (e) {
        console.log("getCntに失敗しました:" + e);
        setCnt("0");
      }
    };

    if (selectPeriod === "全期間") {
      getWorksCnt("");
    } else if (selectedMonth !== "") {
      getWorksCnt(selectedMonth.replace(/-/g, ""));
    } else {
      setCnt("");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPeriod, selectedMonth]);

  useEffect(() => {
    if (fetchDone) {
      csvLinkRef.current?.link.click();
      // 初期化
      setFetchDone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDone]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.selectMonth.trim() === "") {
      alert("年月を指定してください");
      return;
    }
    reset();
    //buttonText === "追加" ? handleAdd() : handleEdit();
  };

  const handleChangeSelectPeriod = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectPeriod((event.target as HTMLInputElement).value);
  };

  const CsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <>0件</>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "works_all.csv"
          : "works_" + selectedMonth + ".csv";
      return (
        <>
          {props.cnt + "件"}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              setData(d);
              //console.log("getData Done!");
              //console.log(d);
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={data}
              headers={headers}
              filename={fileName}
              ref={csvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  const CsvButtonUser = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <>0件</>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "works_all.csv"
          : "works_" + selectedMonth + ".csv";
      return (
        <>
          {props.cnt + "件"}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getDataUser(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              setDataUser(d);
              //console.log("getDataUser Done!");
              //console.log(d);
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={dataUser}
              headers={headersUser}
              filename={fileName}
              ref={csvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  async function getData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<DataObj[]> {
    const d: DataObj[] = [];
    try {
      let workRef;
      if (selectPeriod === "全期間") {
        workRef = db
          .collection("works")
          .where("team", "==", team)
          .orderBy("completion_date", "desc")
          .orderBy("user_id", "asc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        workRef = db
          .collection("works")
          .where("team", "==", team)
          .where("completion_date", ">=", getStartDay(yyyymm))
          .where("completion_date", "<=", getEndDay(yyyymm))
          .orderBy("completion_date", "desc")
          .orderBy("user_id", "asc");
      }
      const workDoc = await workRef.get();
      if (workDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      workDoc.forEach(async (doc) => {
        //console.log("getData: " + doc.id);

        let plan = "";
        let aircon = "";
        let interior = "";
        let repo1 = "";
        let repo2 = "";
        let repo3 = "";
        let unConstructed = "";
        let cloth = "";
        let waxP = "";
        let led = "";
        let amido = "";
        let otherBuhin = "";
        let other = "";

        const reportRef = await db.collection("reports").doc(doc.id).get();
        if (reportRef.exists) {
          plan = reportRef.data()?.plan;
          aircon = reportRef.data()?.aircon;
          interior = reportRef.data()?.interior;
          repo1 = reportRef.data()?.repo1;
          repo2 = reportRef.data()?.repo2;
          repo3 = reportRef.data()?.repo3;
          if (reportRef.data()?.unConstructed !== undefined) {
            unConstructed = reportRef.data()?.unConstructed;
          }
        }
        const invoiceRef = await db.collection("invoices").doc(doc.id).get();
        if (invoiceRef.exists) {
          cloth = invoiceRef.data()?.cloth;
          waxP = invoiceRef.data()?.waxP;
          led = invoiceRef.data()?.led;
          amido = invoiceRef.data()?.amido;
          otherBuhin = invoiceRef.data()?.otherBuhin;
          other = invoiceRef.data()?.other;
        }

        d.push({
          user_name: doc.data().user_name,
          area: doc.data().area,
          name: doc.data().name,
          room: doc.data().room,
          state: doc.data().state,
          fixed_date: doc.data().fixed_date,
          scheduled_date: doc.data().scheduled_date,
          completion_date: doc.data().completion_date,
          plan: plan,
          aircon: aircon,
          interior: interior,
          repo1: repo1,
          repo2: repo2,
          repo3: repo3,
          unConstructed: unConstructed,
          cloth: cloth,
          waxP: waxP,
          led: led,
          amido: amido,
          otherBuhin: otherBuhin,
          other: other,
        });
        if (cnt === d.length.toString()) {
          setFetchDone(true);
        }
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  async function getDataUser(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<DataObjUser[]> {
    const d: DataObjUser[] = [];
    try {
      let workRef;
      if (selectPeriod === "全期間") {
        workRef = db
          .collection("works")
          .where("team", "==", team)
          .where("user_id", "==", authValue.users.doc_id)
          .orderBy("completion_date", "desc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        workRef = db
          .collection("works")
          .where("team", "==", team)
          .where("user_id", "==", authValue.users.doc_id)
          .where("completion_date", ">=", getStartDay(yyyymm))
          .where("completion_date", "<=", getEndDay(yyyymm))
          .orderBy("completion_date", "desc");
      }
      const workDoc = await workRef.get();
      if (workDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      workDoc.forEach(async (doc) => {
        //console.log("getDataUser: " + doc.id);

        let plan = "";
        let aircon = "";
        let interior = "";
        let repo1 = "";
        let repo2 = "";
        let repo3 = "";
        let unConstructed = "";
        let cloth = "";
        let waxP = "";
        let led = "";
        let amido = "";
        let otherBuhin = "";
        let other = "";

        const reportRef = await db.collection("reports").doc(doc.id).get();
        if (reportRef.exists) {
          plan = reportRef.data()?.plan;
          aircon = reportRef.data()?.aircon;
          interior = reportRef.data()?.interior;
          repo1 = reportRef.data()?.repo1;
          repo2 = reportRef.data()?.repo2;
          repo3 = reportRef.data()?.repo3;
          if (reportRef.data()?.unConstructed !== undefined) {
            unConstructed = reportRef.data()?.unConstructed;
          }
        }
        const invoiceRef = await db.collection("invoices").doc(doc.id).get();
        if (invoiceRef.exists) {
          cloth = invoiceRef.data()?.cloth;
          waxP = invoiceRef.data()?.waxP;
          led = invoiceRef.data()?.led;
          amido = invoiceRef.data()?.amido;
          otherBuhin = invoiceRef.data()?.otherBuhin;
          other = invoiceRef.data()?.other;
        }

        d.push({
          area: doc.data().area,
          name: doc.data().name,
          room: doc.data().room,
          state: doc.data().state,
          fixed_date: doc.data().fixed_date,
          scheduled_date: doc.data().scheduled_date,
          completion_date: doc.data().completion_date,
          plan: plan,
          aircon: aircon,
          interior: interior,
          repo1: repo1,
          repo2: repo2,
          repo3: repo3,
          unConstructed: unConstructed,
          cloth: cloth,
          waxP: waxP,
          led: led,
          amido: amido,
          otherBuhin: otherBuhin,
          other: other,
        });
        if (cnt === d.length.toString()) {
          setFetchDone(true);
        }
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  return (
    <>
      {loading ? (
        <div>LOADING.....</div>
      ) : (
        <ResponsiveDrawer title={configValue.lang.exports}>
          <div>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControlPeriod}
                >
                  <FormLabel component="legend"></FormLabel>
                  <RadioGroup
                    row
                    aria-label="radio-group-select"
                    name="radio-group-select"
                    value={selectPeriod}
                    defaultValue={selectPeriod}
                    onChange={handleChangeSelectPeriod}
                  >
                    <FormControlLabel
                      value="年月度"
                      control={<Radio color="default" />}
                      label="年月度"
                    />
                    <FormControlLabel
                      value="全期間"
                      control={<Radio color="default" />}
                      label="全期間"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <TextField
                  className={classes.textfield}
                  id="month"
                  label="年月度"
                  type="month"
                  value={selectedMonth}
                  aria-invalid={errors.selectMonth ? "true" : "false"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("selectMonth", {
                    required: true,
                    maxLength: 7,
                    pattern: /^[0-9]{4}-(0[1-9]|1[0-2])$/,
                  })}
                  error={Boolean(errors.selectMonth)}
                  helperText={[
                    errors.selectMonth &&
                      errors.selectMonth.type === "required" &&
                      "入力必須です",
                    errors.selectMonth &&
                      errors.selectMonth.type === "maxLength" &&
                      "7文字以内で入力してください",
                    errors.selectMonth &&
                      errors.selectMonth.type === "pattern" &&
                      "日付フォーマット（YYYY/MM）で入力してください",
                  ]}
                  onChange={(event) => {
                    setSelectedMonth(event.target.value);
                    setValue("selectMonth", event.target.value);
                  }}
                  disabled={selectPeriod === "全期間"}
                />
              </div>
              {/*
              <div className={classes.divCnt}>
                <CsvExport cnt={cnt} />
              </div>
              */}
              <div className={classes.divCnt}>
                {authValue.users.role !== usersConst.roleName.User ? (
                  <CsvButton
                    cnt={cnt}
                    selectPeriod={selectPeriod}
                    selectedMonth={selectedMonth}
                  />
                ) : (
                  <CsvButtonUser
                    cnt={cnt}
                    selectPeriod={selectPeriod}
                    selectedMonth={selectedMonth}
                  />
                )}
              </div>
            </form>
          </div>
        </ResponsiveDrawer>
      )}
    </>
  );
};

/*
const CsvExport = (props: any) => {
  if (props.cnt === "") {
    return <></>;
  } else if (props.cnt === "0") {
    return <>0件</>;
  } else {
    const headers = [
      { label: "First Name", key: "firstname" },
      { label: "Last Name", key: "lastname" },
      { label: "Email", key: "email" },
    ];
    const data = [
      { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
      { firstname: "Read", lastname: "Labes", email: "rl@smthing.co.com" },
      { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@co.co.com" },
    ];
    return (
      <>
        {props.cnt + "件"}
        <CSVLink data={data} headers={headers} filename={"works.csv"}>
          <Typography>CSVエクスポート</Typography>
        </CSVLink>
      </>
    );
  }
};
*/

export default Exports;
