import React, { useContext } from 'react';
import '../css/Views.css';
import firebase from '../../config/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../store/AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LockIcon from '@material-ui/icons/LockOutlined';

const Login: React.FC = () => {
  const authValue = useContext(AuthContext);

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ],
  };

  return (
    <>
      <div>
        <Header />
        {
          (authValue.currentUser === null || authValue.currentUser === undefined) ? (
            <div className="Views-body">
              <LockIcon color="secondary" fontSize="large" />
              <div>Sign in</div>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
          ) :
            <Redirect to="/" />
        }
        <Footer />
      </div>
    </>
  );
}

export default Login;