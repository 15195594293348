import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import firebase, { db } from '../../../config/firebase';
import { usersConst, infomationsConst } from '../../../config/Const';
import { AuthContext } from '../../../store/AuthContext';
import { fromTimeStampToDate } from '../../../common/date';
import InfomationsState from './components/InfomationsState';
import InfomationsMenu from './components/InfomationsMenu';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Container,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCounter } from '../../../common/CustomHooks';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    height: 40,
  },
  container: {
    minWidth: '100vh',
    textAlign: 'left',
  },
  icon: {
    color: '#3F51B5',
  },
}));

const Infomations: React.FC = (props: any) => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [infomations, setInfomations] = useState<firebase.firestore.DocumentData[]>([]);
  const [count, countUp] = useCounter(0);

  useEffect(() => {
    searchInfomations();
    setLoading(false);
  }, []);

  const searchInfomations = async () => {
    try {
      var ref;
      // NOTE: FirebaseのWebコンソールより手動で複合インデックスを設定
      // NOTE: index1（1:team 昇順 2:create_time 降順）、コレクション
      // NOTE: index2（1:team 昇順 2:to_user_id 昇順 3:create_time 降順）、コレクション
      if (authValue.users.role === usersConst.roleName.Manager
        || authValue.users.role === usersConst.roleName.Admin) {
        ref = await db.collection('infomations')
          .where('team', '==', authValue.users.team)
          .orderBy('create_time', 'desc')
          .get();
      } else {
        ref = await db.collection('infomations')
          .where('team', '==', authValue.users.team)
          .where('to_user_id', '==', authValue.users.doc_id)
          .orderBy('create_time', 'desc')
          .get();
      }

      if (ref.empty) return [];
      const infomationList: firebase.firestore.DocumentData[] = [];
      ref.forEach(doc => {
        infomationList.push({
          doc_id: doc.id,
          ...doc.data(),
        });
      });
      setInfomations(infomationList);

    } catch (e) {
      console.log("searchInfomationsに失敗しました：" + e);
    }
  }

  const handleCheck = async (doc_id: string) => {
    try {
      const ref = db.collection('infomations').doc(doc_id);
      await ref.set({
        verification_flg: true,
      }, { merge: true });

      searchInfomations();

    } catch (e) {
      console.log("Infomationsの確認に失敗しました：" + e);
    }
  };

  const handleDelete = async (doc_id: string) => {
    //if (window.confirm("削除しますか？")) {
    try {
      const ref = db.collection('infomations').doc(doc_id);
      await ref.delete();

      // 初期化
      setInfomations([]);
      searchInfomations();

    } catch (e) {
      console.log("Infomationsの削除に失敗しました：" + e);
    }
    //}
  };

  const DelButtonGroup = (props: any) => {
    if ((props.manager_user_id === "" && authValue.users.role === usersConst.roleName.User)
      || (props.manager_user_id !== "" && authValue.users.role === usersConst.roleName.Manager)) {
      return (<></>);
    } else {
      return (
        <>
          <Button
            onClick={() => handleCheck(props.doc_id)}
            disabled={(props.verification_flg)}
          >
            <CheckIcon className={classes.icon} fontSize="large" />
            <Typography>確認済みにする</Typography>
          </Button>
          <Button
            onClick={() => handleDelete(props.doc_id)}
            disabled={!(props.verification_flg)}
          >
            <DeleteIcon className={classes.icon} fontSize="large" />
            <Typography>削除</Typography>
          </Button>
        </>
      );
    }
  }

  const handleLink = (url: string) => {
    props.history.push(url);
  };

  return (
    <>
      {
        loading ? (
          <div>LOADING.....</div>
        ) :
          <div>
            <Divider className={classes.divider} />
            <Container className={classes.container}>
              <h3>新着情報</h3>
            </Container>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="simple table">
                <TableBody>
                  {infomations.map((row) => (
                    <TableRow hover key={row.doc_id}>
                      <TableCell align="left" width="160">{fromTimeStampToDate(row.create_time)}</TableCell>
                      <TableCell align="left" width="105">
                        <InfomationsMenu>{row.menu}</InfomationsMenu>
                      </TableCell>
                      <TableCell align="left" width="300">
                        {
                          ((row.manager_user_id === "" && authValue.users.role === usersConst.roleName.Manager)
                            || (row.manager_user_id !== "" && authValue.users.role === usersConst.roleName.User)) ?
                            <Link
                              component="button"
                              variant="body2"
                              align="left"
                              color={(authValue.users.is_dark) ? 'inherit' : 'primary'}
                              onClick={() => handleLink(row.url)}
                            >
                              <div>{row.text}</div>
                              <div>{row.info}</div>
                            </Link>
                            :
                            <>
                              <div>{row.from_text}</div>
                              <div>{row.info}</div>
                            </>
                        }
                      </TableCell>
                      <TableCell align="left" width="160">{row.from_user_name}</TableCell>
                      <TableCell align="center" width="105">
                        <InfomationsState>
                          {(row.verification_flg) ?
                            infomationsConst.verification.True
                            : infomationsConst.verification.False}
                        </InfomationsState>
                      </TableCell>
                      <TableCell align="right" width="250">
                        <DelButtonGroup
                          doc_id={row.doc_id}
                          verification_flg={row.verification_flg}
                          manager_user_id={row.manager_user_id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Divider className={classes.divider} />
            <div>{count}</div>
            <button onClick={() => { countUp() }}>Test</button> */}
          </div>
      }
    </>
  );
}
/* NOTO: 子コンポーネントにpropsを渡しhistory.push()するときはwithRouter必須らしい */
export default withRouter(Infomations);