import React, { useEffect, useState } from 'react';
import { db } from '../../../../config/firebase';
import { worksConst } from '../../../../config/Const';
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogActions, 
  DialogTitle, 
  TextField, 
  InputLabel, 
  MenuItem, 
  FormControl, 
  Select, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
 
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '40ch',
      marginBottom: 20,
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: '30ch'
  },
  textfield: {
    width: '30ch',
  },
}));

type Props = {
  title: string;
  isOpen: boolean;
  buttonText: string;
  fncClose: () => void;
  fncIsChange: () => void;
  userDocId?: string;
  docId?: string;
  editArea?: string;
  editName?: string;
  editRoom?: string;
  editFixedDate?: string;
  editScheduledDate?: string;
  editCompletionDate?: string;
  editState?: string;
  team: string;
  reportFlg?: string;
  userName?: string;
};

type Inputs = {
  area: string,
  name: string,
  room: string,
  fixedDate: string,
  scheduledDate: string,
  completionDate: string,
  state: string,
};

const WorksDialog: React.FC<Props> = ({ 
  title, 
  isOpen, 
  buttonText, 
  fncClose, 
  fncIsChange, 
  userDocId,
  docId, 
  editArea, 
  editName, 
  editRoom,
  editFixedDate, 
  editScheduledDate, 
  editCompletionDate, 
  editState, 
  team, 
  reportFlg, 
  userName, 
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const area = (editArea === null || editArea === undefined) ? worksConst.areaName.Sa : editArea;
  const name = (editName === null || editName === undefined) ? '' : editName;
  const room = (editRoom === null || editRoom === undefined) ? '' : editRoom;
  const fixedDate = (editFixedDate === null || editFixedDate === undefined) ? '' : editFixedDate;
  const scheduledDate = (editScheduledDate === null || editScheduledDate === undefined) ? '' : editScheduledDate;
  const completionDate = (editCompletionDate === null || editCompletionDate === undefined) ? '' : editCompletionDate;
  const state = (editState === null || editState === undefined) ? worksConst.stateName.Mi : editState;
  const [addArea, setAddArea] = useState(area);
  const [addName, setAddName] = useState(name);
  const [addRoom, setAddRoom] = useState(room);
  const [addFixedDate, setAddFixedDate] = useState(fixedDate);
  const [addScheduledDate, setAddScheduledDate] = useState(scheduledDate);
  const [addCompletionDate, setAddCompletionDate] = useState(completionDate);
  const [addState, setAddState] = useState(state);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<Inputs>({
    defaultValues: {
      area: addArea,
      name: addName,
      room: addRoom,
      fixedDate: addFixedDate,
      scheduledDate: addScheduledDate,
      completionDate: addCompletionDate,
      state: addState,
    },
  });
  
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    reset();
    setAddArea(area);
    setAddName(name);
    setAddRoom(room);
    setAddFixedDate(fixedDate);
    setAddScheduledDate(scheduledDate);
    setAddCompletionDate(completionDate);
    setAddState(state);
    setOpen(false);
    fncClose();
  };

  const handleChangeArea = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAddArea(event.target.value as string);
  };

  const handleChangeState = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAddState(event.target.value as string);
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.state===worksConst.stateName.Ka && data.completionDate.trim()==="") {
      alert("STATEが作業完了の場合は、完了日を入力してください");
      return;
    };
    if (data.state!==worksConst.stateName.Ka && data.completionDate.trim()!=="") {
      alert("STATEが作業完了以外の場合は、完了日を入力しないでください");
      return;
    };
    reset();
    buttonText === "追加" ? handleAdd() : handleEdit();
  };

  const handleAdd = async() => {
    try {
      await db.runTransaction(async transaction => {
        const worksRef = db.collection('works').doc();
        await transaction.get(worksRef);
        transaction.set(worksRef, {
          area: addArea,
          name: addName,
          room: addRoom,
          fixed_date: addFixedDate,
          scheduled_date: addScheduledDate,
          completion_date: addCompletionDate, 
          state: addState,
          user_id: userDocId,
          team: team,
          reportFlg: '0',
          user_name: userName,
        });
        const invoicesRef = db.collection('invoices').doc(worksRef.id);
        transaction.set(invoicesRef, {
          completion_date: addCompletionDate, 
          area: addArea,
          name: addName,
          room: addRoom,
          user_id: userDocId,
          team: team,
          user_name: userName,
        });
      });
      setAddArea('');
      setAddName('');
      setAddRoom('');
      setAddFixedDate('');
      setAddScheduledDate('');
      setAddCompletionDate('');
      setAddState('');
      fncIsChange();
    } catch(e) {
      console.log("handleAddに失敗しました：" + e);
    }
    setOpen(false);
    fncClose();
  };

  const handleEdit = async() => {
    try {
      await db.runTransaction(async transaction => {
        const worksRef = db.collection('works').doc(docId);
        await transaction.get(worksRef);
        transaction.update(worksRef, {
          area: addArea,
          name: addName,
          room: addRoom,
          fixed_date: addFixedDate,
          scheduled_date: addScheduledDate,
          completion_date: addCompletionDate,
          state: addState,
          reportFlg: (addState===worksConst.stateName.Ka && reportFlg==='1') ? '1' : '0'
        });
        const invoicesRef = db.collection('invoices').doc(docId);
        transaction.update(invoicesRef, {
          completion_date: addCompletionDate, 
          area: addArea,
          name: addName,
          room: addRoom,
        });
      });
      fncIsChange();
    } catch (e) {
      console.log("handleEditに失敗しました：" + e);
    }
    setOpen(false);
    fncClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
          <DialogContent>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="select-label-area">AREA</InputLabel>
                <Select
                  labelId="select-label-area"
                  id="select-area"
                  value={addArea}
                  onChange={handleChangeArea}
                >
                  {Object.keys(worksConst.areaName).map((key) => (
                    <MenuItem value={worksConst.areaName[key]}>{worksConst.areaName[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="date" 
                label="完了予定" 
                type="date" 
                value={addScheduledDate}
                aria-invalid={errors.scheduledDate ? "true" : "false"}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("scheduledDate", {
                  required: true,
                  maxLength: 10,
                  pattern: /^[0-9]{4}\-(0[1-9]|1[0-2])\-(0[1-9]|[12][0-9]|3[01])$/,
                })}
                error={Boolean(errors.scheduledDate)} 
                helperText={[
                  errors.scheduledDate && errors.scheduledDate.type==="required" && "入力必須です",
                  errors.scheduledDate && errors.scheduledDate.type==="maxLength" && "10文字以内で入力してください",
                  errors.scheduledDate && errors.scheduledDate.type==="pattern" && "日付フォーマット（YYYY/MM/DD）で入力してください"
                ]}
                onChange={(event) => {
                  setAddScheduledDate(event.target.value);
                  setValue("scheduledDate", event.target.value);
                }} 
                />
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="standard-basic" 
                label="NAME" 
                value={addName}
                aria-invalid={errors.name ? "true" : "false"}
                {...register("name", {
                  required: true,
                  maxLength: 20,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.name)} 
                helperText={[
                  errors.name && errors.name.type==="required" && "入力必須です",
                  errors.name && errors.name.type==="maxLength" && "20文字以内で入力してください",
                  errors.name && errors.name.type==="pattern" && "禁則文字が使用されています"
                ]}
                onChange={(event) => {
                  setAddName(event.target.value);
                  setValue("name", event.target.value);
                }} 
                />
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="standard-basic" 
                label="ROOM" 
                type="number"
                value={addRoom}
                aria-invalid={errors.room ? "true" : "false"}
                {...register("room", {
                  required: true,
                  maxLength: 4,
                  pattern: /^[0-9]+$/,
                })}
                error={Boolean(errors.room)} 
                helperText={[
                  errors.room && errors.room.type==="required" && "入力必須です",
                  errors.room && errors.room.type==="maxLength" && "4文字以内で入力してください",
                  errors.room && errors.room.type==="pattern" && "整数で入力してください"
                ]}
                onChange={(event) => {
                  setAddRoom(event.target.value);
                  setValue("room", event.target.value);
                }} 
                />
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="date" 
                label="期日" 
                type="date" 
                value={addFixedDate}
                aria-invalid={errors.fixedDate ? "true" : "false"}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("fixedDate", {
                  required: true,
                  maxLength: 10,
                  pattern: /^[0-9]{4}\-(0[1-9]|1[0-2])\-(0[1-9]|[12][0-9]|3[01])$/,
                })}
                error={Boolean(errors.fixedDate)} 
                helperText={[
                  errors.fixedDate && errors.fixedDate.type==="required" && "入力必須です",
                  errors.fixedDate && errors.fixedDate.type==="maxLength" && "10文字以内で入力してください",
                  errors.fixedDate && errors.fixedDate.type==="pattern" && "日付フォーマット（YYYY/MM/DD）で入力してください"
                ]}
                onChange={(event) => {
                  setAddFixedDate(event.target.value);
                  setValue("fixedDate", event.target.value);
                }} 
                />
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="date" 
                label="完了日" 
                type="date" 
                value={addCompletionDate}
                aria-invalid={errors.completionDate ? "true" : "false"}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("completionDate", {
                  required: (addState===worksConst.stateName.Ka || addState===worksConst.stateName.Ng) ? true : false,
                  maxLength: (addState===worksConst.stateName.Ka || addState===worksConst.stateName.Ng) ? 10 : 1,
                  pattern: /^[0-9]{4}\-(0[1-9]|1[0-2])\-(0[1-9]|[12][0-9]|3[01])$/,
                })}
                error={Boolean(errors.completionDate)} 
                helperText={[
                  errors.completionDate && errors.completionDate.type==="required" && "STATEが完了・差戻しのときは、入力必須です",
                  errors.completionDate && errors.completionDate.type==="maxLength" && "STATEが完了・差戻し以外の場合は入力しないでください",
                  errors.completionDate && errors.completionDate.type==="pattern" && "日付フォーマット（YYYY/MM/DD）で入力してください"
                ]}
                onChange={(event) => {
                  setAddCompletionDate(event.target.value);
                  setValue("completionDate", event.target.value);
                }} 
                />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">STATE</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  {...register("state")}
                  id="demo-simple-select"
                  value={addState}
                  onChange={handleChangeState}
                >
                  {Object.keys(worksConst.stateName).map((key) => (
                    (worksConst.stateName[key]===worksConst.stateName.Mi 
                      || worksConst.stateName[key]===worksConst.stateName.Sa
                      || worksConst.stateName[key]===worksConst.stateName.Ka
                      || worksConst.stateName[key]===worksConst.stateName.Pe
                      || (worksConst.stateName[key]===worksConst.stateName.Ng && editState===worksConst.stateName.Ng)) ?  
                      <MenuItem value={worksConst.stateName[key]}>{worksConst.stateName[key]}</MenuItem>
                      : <></>
                  ))}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="default" type="submit">{buttonText}</Button>
            <Button onClick={handleClose} color="default">キャンセル</Button>
         </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default WorksDialog;