import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../../store/AuthContext';
import { ConfigContext } from '../../../store/ConfigContext';
import ResponsiveDrawer from '../../templates/ResponsiveDrawer';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import SelectLang from './components/SelectLang';
import SwitchTheme from './components/SwitchTheme';
import SetDisplayName from './components/SetDisplayName';

const useStyles = makeStyles(() => ({
  card: {
      margin: 36,
      height: 180,
  },
}));

const Settings: React.FC = (props: any) => {
  const classes = useStyles();
  const configValue = useContext(ConfigContext);
  const authValue = useContext(AuthContext);

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
  }, [authValue.currentUser]);

  return (
    <>
      <ResponsiveDrawer title={configValue.lang.settings}>
        <Card className={classes.card}>
          <CardHeader title="言語" titleTypographyProps={{ variant: "h6" }} />
          <CardContent>
            <SelectLang />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="テーマ" titleTypographyProps={{ variant: "h6" }} />
          <CardContent>
            <SwitchTheme />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="個人設定" titleTypographyProps={{ variant: "h6" }} />
          <CardContent>
            <SetDisplayName />
          </CardContent>
        </Card>
      </ResponsiveDrawer>
    </>
  );
}

export default Settings;