import React, { useState, useEffect } from "react";
import { Page, Document, StyleSheet, Font } from "@react-pdf/renderer";
import firebase, { db } from "../../../../config/firebase";
import { worksConst, pdfConst } from "../../../../config/Const";
import { sumSubTotal, setSumSubTotal } from "./InvoicesPdfGlobal";
import { buzaiTotal, setBuzaiTotal } from "./InvoicesPdfGlobal";
import {
  fromTimeStampToString,
  getStartDay,
  getEndDay,
} from "../../../../common/date";
import Header from "./InvoicesPdfHeader";
import ListHeader from "./InvoicesPdfListHeader";
import ListItem from "./InvoicesPdfListItem";
import Footer from "./InvoicesPdfFooter";
import nasuRegular from "../../../../fonts/Nasu-Regular.ttf";
import nasuBold from "../../../../fonts/Nasu-Bold.ttf";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    //fontFamily: pdfConst.font.family,
    fontFamily: "Nasu-Regular",
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 10,
    paddingBottom: 10,
  },
});

Font.register({
  family: pdfConst.font.family,
  src: pdfConst.font.src,
});

Font.register({
  family: "Nasu-Regular",
  src: nasuRegular as string,
});

Font.register({
  family: "Nasu-Bold",
  src: nasuBold as string,
});

type Props = {
  docId: string;
};

function getMaterialStart(yyyymm: string) {
  const yyyy = yyyymm.substr(0, 4);
  const mm = yyyymm.substr(4, 2);
  if (mm === "01") {
    return new Date((Number(yyyy) - 1).toString() + "-12-26T00:00:00");
  } else {
    return new Date(
      yyyy + "-" + (Number(mm) - 1).toString().padStart(2, "0") + "-26T00:00:00"
    );
  }
}

function getMaterialEnd(yyyymm: string) {
  const yyyy = yyyymm.substr(0, 4);
  const mm = yyyymm.substr(4, 2);
  return new Date(yyyy + "-" + mm + "-25T23:59:59");
}

const InvoicesPdf: React.FC<Props> = ({ docId }) => {
  const lineNum = 12;
  let recNumber = 0;
  let totalPage = 0;
  var v: any[] = [];
  const [team, setTeam] = useState("");
  const [userId, setUserId] = useState("");
  const [yyyymm, setYyyymm] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmDate, setConfirmDate] = useState("");
  const [view, setView] = useState<any[]>([]);

  useEffect(() => {
    searchInvoiceLinks();
  }, []);

  useEffect(() => {
    searchMaterials();
    searchInvoices();
  }, [yyyymm]);

  const searchInvoiceLinks = async () => {
    try {
      const invoicelinksDoc = await db
        .collection("invoicelinks")
        .doc(docId)
        .get();
      if (invoicelinksDoc.exists) {
        setTeam(invoicelinksDoc.get("team"));
        setUserId(invoicelinksDoc.get("user_id"));
        setUserName(invoicelinksDoc.get("user_name"));
        setConfirmDate(
          fromTimeStampToString(invoicelinksDoc.get("confirm_date"))
        );
        setYyyymm(invoicelinksDoc.get("yyyymm"));
      }
    } catch (e) {
      console.log("getMaterialsTotalに失敗しました：" + e);
    }
  };

  const searchMaterials = async () => {
    try {
      const ref = await db
        .collection("materials")
        .orderBy("offerTime", "asc")
        .startAt(getMaterialStart(yyyymm))
        .endAt(getMaterialEnd(yyyymm))
        .get();

      if (ref.empty) return 0;
      let s: number = 0;
      ref.forEach((doc) => {
        s = s + Number(doc.get("total"));
      });
      setBuzaiTotal(s);
    } catch (e) {
      console.log("getMaterialsTotalに失敗しました：" + e);
    }
  };

  const searchInvoices = async () => {
    try {
      // NOTE: FirebaseのWebコンソールより手動で複合インデックスを設定（team 昇順 user_id 昇順 completion_date 昇順）
      const ref = await db
        .collection("invoices")
        .where("team", "==", team)
        .where("user_id", "==", userId)
        .orderBy("completion_date", "asc")
        .startAt(getStartDay(yyyymm))
        .endAt(getEndDay(yyyymm))
        .get();

      if (ref.empty) return [];
      const invoiceList: firebase.firestore.DocumentData[] = [];
      let s: number = 0;
      ref.forEach((doc) => {
        invoiceList.push({
          doc_id: doc.id,
          //user_name: doc.get('user_name'),
          completion_date: doc.get("completion_date").substr(5, 5),
          area: doc.get("area"),
          name: doc.get("name"),
          room: doc.get("room"),
          plan: doc.get("plan"),
          aircon: doc.get("aircon"),
          cloth: doc.get("cloth"),
          waxP: doc.get("waxP"),
          led: doc.get("led"),
          amido: doc.get("amido"),
          otherBuhin: doc.get("otherBuhin"),
          other: doc.get("other"),
        });
        // 仕方なくここで
        s =
          s +
          feeSubTotal(
            doc.get("plan"),
            doc.get("aircon"),
            doc.get("cloth"),
            doc.get("waxP"),
            doc.get("led"),
            doc.get("amido"),
            doc.get("otherBuhin"),
            doc.get("other")
          );
      });
      setSumSubTotal(s);

      recNumber = invoiceList.length;
      totalPage = Math.ceil(recNumber / lineNum);
      //const userName = invoiceList[0].user_name;
      // invoice配列を12件ごとに分割し、2次元配列に格納してる
      var a: any = chunk(invoiceList, lineNum);
      for (var i = 1; i <= totalPage; i++) {
        //alert(a[i-1][0].name);
        //alert(a[i-1][1].name);

        // 12行に満たない場合、空行を入れてる
        var len = a[i - 1].length;
        for (var k = len; k < lineNum; k++) {
          a[i - 1].push({
            doc_id: "",
            //user_name: '',
            completion_date: "",
            area: "",
            name: "",
            room: "",
            plan: "",
            roomCQ: "",
            roomCF: "",
            airconCQ: "",
            airconCF: "",
            clothCQ: "",
            clothCF: "",
            waxPCQ: "",
            waxPCF: "",
            ledQ: "",
            ledF: "",
            amidoQ: "",
            amidoF: "",
            otherBuhinQ: "",
            otherBuhinF: "",
            otherQ: "",
            otherF: "",
            subTotal: "",
          });
        }
        v.push(
          <PdfPage
            yyyymm={yyyymm}
            userName={userName}
            currentPage={i}
            totalPage={totalPage}
            list={a[i - 1]}
            confirmDate={confirmDate}
          />
        );
      }
      setView(v);
    } catch (e) {
      console.log("searchInvoicesに失敗しました：" + e);
    }
  };

  function chunk<T extends any[]>(arr: T, size: number) {
    return arr.reduce(
      (newarr, _, i) =>
        i % size ? newarr : [...newarr, arr.slice(i, i + size)],
      [] as T[][]
    );
  }

  return <Document>{view}</Document>;
};

interface PdfPageProps {
  yyyymm: string;
  userName: string;
  currentPage: number;
  totalPage: number;
  list: any[];
  confirmDate: string;
}

function feeRoomC(plan: string): number {
  switch (plan) {
    case worksConst.plan["1K"]:
    case worksConst.plan["2K"]:
    case worksConst.plan["1DK"]:
      return 16000;
    case worksConst.plan["1LDK"]:
    case worksConst.plan["2DK"]:
      return 19000;
    case worksConst.plan["2LDK"]:
    case worksConst.plan["3DK"]:
    case worksConst.plan["3LDK"]:
      return 23000;
    case worksConst.plan["NO"]:
      return 0;
    default:
      return NaN;
  }
}

function feeAirconC(quantity: number): number {
  return quantity * 3000;
}

function feeClothC(quantity: number): number {
  return quantity * 150;
}

function feeWaxP(quantity: number): number {
  return quantity * 500;
}

function feeSubTotal(
  plan: string,
  aircon: number,
  cloth: number,
  waxP: number,
  led: number,
  amido: number,
  otherBuhin: number,
  other: number
): number {
  return (
    feeRoomC(plan) +
    feeAirconC(aircon) +
    feeClothC(cloth) +
    feeWaxP(waxP) +
    Number(led) +
    Number(amido) +
    Number(otherBuhin) +
    Number(other)
  );
}

const PdfPage: React.FC<PdfPageProps> = ({
  yyyymm,
  userName,
  currentPage,
  totalPage,
  list,
  confirmDate,
}) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header
        yyyymm={yyyymm}
        userName={userName}
        currentPage={currentPage}
        totalPage={totalPage}
        confirmDate={confirmDate}
      />
      <ListHeader />
      {list.map((value) => {
        return (
          <ListItem
            doc_id={value.doc_id}
            completion_date={value.completion_date}
            area={value.area}
            name={value.name}
            room={value.room}
            plan={value.plan}
            roomCQ={
              value.doc_id
                ? value.plan === worksConst.plan["NO"]
                  ? "0"
                  : "1"
                : ""
            }
            roomCF={value.doc_id ? feeRoomC(value.plan).toLocaleString() : ""}
            airconCQ={value.aircon}
            airconCF={
              value.doc_id ? feeAirconC(value.aircon).toLocaleString() : ""
            }
            clothCQ={value.cloth}
            clothCF={
              value.doc_id ? feeClothC(value.cloth).toLocaleString() : ""
            }
            waxPCQ={value.waxP}
            waxPCF={value.doc_id ? feeWaxP(value.waxP).toLocaleString() : ""}
            ledQ={value.doc_id ? "-" : ""}
            ledF={value.doc_id ? Number(value.led).toLocaleString() : ""}
            amidoQ={value.doc_id ? "-" : ""}
            amidoF={value.doc_id ? Number(value.amido).toLocaleString() : ""}
            otherBuhinQ={value.doc_id ? "-" : ""}
            otherBuhinF={
              value.doc_id ? Number(value.otherBuhin).toLocaleString() : ""
            }
            otherQ={value.doc_id ? "-" : ""}
            otherF={value.doc_id ? Number(value.other).toLocaleString() : ""}
            subTotal={
              value.doc_id
                ? feeSubTotal(
                    value.plan,
                    value.aircon,
                    value.cloth,
                    value.waxP,
                    value.led,
                    value.amido,
                    value.otherBuhin,
                    value.other
                  ).toLocaleString()
                : ""
            }
          />
        );
      })}
      <Footer
        sum={currentPage === totalPage ? sumSubTotal.toLocaleString() : " "}
        buzai={currentPage === totalPage ? buzaiTotal.toLocaleString() : " "}
        /* TODO: その他 */
        other={currentPage === totalPage ? "0" : " "}
        invoice={
          currentPage === totalPage
            ? (sumSubTotal - buzaiTotal) /* TODO: その他 相殺 */
                .toLocaleString()
            : " "
        }
      />
    </Page>
  );
};

export default InvoicesPdf;
