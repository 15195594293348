import React from 'react';
import { infomationsConst } from '../../../../config/Const';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(() => ({
	icon: {
		marginRight: 2,
		marginTop: 10,
	},
	iconWorks: {
		color: '#3F51B5',
	},
	iconInvoices: {
		color: '#E91E63',
	},
	iconMaterials: {
		color: '#607D8B',
	},
	iconOthers: {
		color: '#F44336',
	},
  }));
  
export interface InfomationsMenuProps {
    children: React.ReactNode;
}
  
const InfomationsMenu: React.FC<InfomationsMenuProps> = ({children}) => {
	const classes = useStyles();
	if (children===infomationsConst.menu.Works) {
		return (
			<ListItemIcon>
				<FiberIcon className={classes.icon + ' ' + classes.iconWorks} fontSize="inherit" />
				<ListItemText primary={children} />
			</ListItemIcon>
		);
	} else if (children===infomationsConst.menu.Invoices) {
		return (
			<ListItemIcon>
				<FiberIcon className={classes.icon + ' ' + classes.iconInvoices} fontSize="inherit" />
				<ListItemText primary={children} />
			</ListItemIcon>
		);
	} else if (children===infomationsConst.menu.Materials) {
		return (
			<ListItemIcon>
				<FiberIcon className={classes.icon + ' ' + classes.iconMaterials} fontSize="inherit" />
				<ListItemText primary={children} />
			</ListItemIcon>
		);
	} else {
		return (
			<ListItemIcon>
				<FiberIcon className={classes.icon + ' ' + classes.iconOthers} fontSize="inherit" />
				<ListItemText primary={children} />
			</ListItemIcon>
		);
	}
};

export default InfomationsMenu;