import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import Grid from '../../../components/Grid';
import ListColumn from '../../../components/ListColumn';
import MyText from '../../../components/MyText';

const styles = StyleSheet.create({
  footerContainer: {
    marginTop: 10,
  },
});

type Props = {
  sum?: string;
  buzai?: string;
  other?: string;
  invoice?: string;
};

const InvoicesPdfFooter: React.FC<Props> = ({sum=" ", buzai=" ", other=" ", invoice=" "}) => {
  return (
    <>
    <View style={styles.footerContainer}>
      <Grid height={25} borderTop borderLeft borderRight>
        <ListColumn widthPer="25%" borderRight><MyText fontSize='14' textAlign='center'>合計</MyText></ListColumn>
        <ListColumn widthPer="25%" borderRight><MyText fontSize='14' textAlign='center'>部材相殺金額</MyText></ListColumn>
        <ListColumn widthPer="25%" borderRight><MyText fontSize='14' textAlign='center'>その他</MyText></ListColumn>
        <ListColumn widthPer="25%"><MyText fontSize='14' textAlign='center'>請求金額</MyText></ListColumn>
      </Grid>
      <Grid height={25} borderTop borderBottom borderLeft borderRight>
        <ListColumn widthPer="25%" borderRight><MyText fontSize='14' textAlign='right' marginRight='4'>{sum}</MyText></ListColumn>
        <ListColumn widthPer="25%" borderRight><MyText fontSize='14' textAlign='right' marginRight='4'>{buzai}</MyText></ListColumn>
        <ListColumn widthPer="25%" borderRight><MyText fontSize='14' textAlign='right' marginRight='4'>{other}</MyText></ListColumn>
        <ListColumn widthPer="25%"><MyText fontSize='14' textAlign='right' marginRight='4'>{invoice}</MyText></ListColumn>
      </Grid>
    </View>
    </>
  );
};

export default InvoicesPdfFooter;