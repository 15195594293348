import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Page,
  Document,
  StyleSheet,
  Font,
  View,
} from "@react-pdf/renderer";
import { db } from "../../../../config/firebase";
import {
  fromTimeStampToDate,
  getTargetMonth,
  getBillingDate,
} from "../../../../common/date";
import { materialConst, pdfConst } from "../../../../config/Const";
import { amountOfMoney, subTotal } from "../common/MaterialsFunction";
import MyText from "../../../components/MyText";
import Grid from "../../../components/Grid";
import ListColumn from "../../../components/ListColumn";
import nasuRegular from "../../../../fonts/Nasu-Regular.ttf";
import nasuBold from "../../../../fonts/Nasu-Bold.ttf";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    //fontFamily: pdfConst.font.family,
    fontFamily: "Nasu-Regular",
    paddingVertical: 30,
    paddingHorizontal: 50,
  },
  headerContainer: {
    flexDirection: "column",
    paddingLeft: 2,
    paddingRight: 2,
  },
  headerColumnTitle: {
    flexDirection: "column",
  },
  headerColumnDate: {
    flexDirection: "column",
  },
});

Font.register({
  family: pdfConst.font.family,
  src: pdfConst.font.src,
});

Font.register({
  family: "Nasu-Regular",
  src: nasuRegular as string,
});

Font.register({
  family: "Nasu-Bold",
  src: nasuBold as string,
});

const MaterialsPdf: React.FC = (props: any) => {
  const { params } = props.match;
  const [offerTime, setOfferTime] = useState("");
  const [userName, setUserName] = useState("");
  const [ssJh, setSsJh] = useState("");
  const [ssJa, setSsJa] = useState("");
  const [elbowI, setElbowI] = useState("");
  const [elbowH, setElbowH] = useState("");
  const [nipple, setNipple] = useState("");
  const [shower, setShower] = useState("");
  const [heatShield, setHeatShield] = useState("");
  const [antiEva, setAntiEva] = useState("");
  const [pipeFanUnPlug, setPipeFanUnPlug] = useState("");
  const [pipeFanPlug, setPipeFanPlug] = useState("");
  const [fan, setFan] = useState("");
  const [triangle, setTriangle] = useState("");
  const [top, setTop] = useState("");
  const [filterL, setFilterL] = useState("");
  const [filterS, setFilterS] = useState("");
  const [wax, setWax] = useState("");
  const [offsetDate, setOffsetDate] = useState("");
  const gridHeight = 25;
  const textMarginRight = "4";
  const textMarginLeft = "10";
  const textMarginLeft2 = "20";
  const textMarginTop = "4";

  useEffect(() => {
    searchMaterials();
  }, []);

  interface ListItem {
    material: string;
    materialSub: string;
    quantity: string;
    unitPrice: number;
  }

  function createData(
    material: string,
    materialSub: string,
    quantity: string,
    unitPrice: number
  ): ListItem {
    return { material, materialSub, quantity, unitPrice };
  }

  const searchMaterials = async () => {
    try {
      const materialRef = db.collection("materials").doc(params.docId);
      const materialDoc = await materialRef.get();
      if (materialDoc.exists) {
        if (materialDoc.get("offerTime") !== undefined) {
          const oTime = fromTimeStampToDate(materialDoc.get("offerTime"));
          if (oTime !== undefined) {
            setOfferTime(
              oTime.substr(0, 4) +
                "年" +
                oTime.substr(5, 2) +
                "年" +
                oTime.substr(8, 2) +
                "日"
            );
            const targetMonth = getTargetMonth(
              oTime.substr(0, 4) + oTime.substr(5, 2) + oTime.substr(8, 2)
            );
            const billingDate = getBillingDate(targetMonth);
            setOffsetDate(
              targetMonth.substr(0, 4) +
                "年" +
                targetMonth.substr(4, 2) +
                "月請求分 (" +
                billingDate.substr(0, 4) +
                "/" +
                billingDate.substr(4, 2) +
                "/" +
                billingDate.substr(6, 2) +
                ")で相殺されます。"
            );
          }
        }
        if (materialDoc.get("user_name") !== undefined)
          setUserName(materialDoc.get("user_name"));
        if (materialDoc.get("ss_jh") !== undefined)
          setSsJh(materialDoc.get("ss_jh"));
        if (materialDoc.get("ss_ja") !== undefined)
          setSsJa(materialDoc.get("ss_ja"));
        if (materialDoc.get("elbow_i") !== undefined)
          setElbowI(materialDoc.get("elbow_i"));
        if (materialDoc.get("elbow_h") !== undefined)
          setElbowH(materialDoc.get("elbow_h"));
        if (materialDoc.get("nipple") !== undefined)
          setNipple(materialDoc.get("nipple"));
        if (materialDoc.get("shower") !== undefined)
          setShower(materialDoc.get("shower"));
        if (materialDoc.get("heat_shield") !== undefined)
          setHeatShield(materialDoc.get("heat_shield"));
        if (materialDoc.get("anti_eva") !== undefined)
          setAntiEva(materialDoc.get("anti_eva"));
        if (materialDoc.get("pipe_fan_un_plug") !== undefined)
          setPipeFanUnPlug(materialDoc.get("pipe_fan_un_plug"));
        if (materialDoc.get("pipe_fan_plug") !== undefined)
          setPipeFanPlug(materialDoc.get("pipe_fan_plug"));
        if (materialDoc.get("fan") !== undefined)
          setFan(materialDoc.get("fan"));
        if (materialDoc.get("triangle") !== undefined)
          setTriangle(materialDoc.get("triangle"));
        if (materialDoc.get("top") !== undefined)
          setTop(materialDoc.get("top"));
        if (materialDoc.get("filterL") !== undefined)
          setFilterL(materialDoc.get("filterL"));
        if (materialDoc.get("filterS") !== undefined)
          setFilterS(materialDoc.get("filterS"));
        if (materialDoc.get("wax") !== undefined)
          setWax(materialDoc.get("wax"));
      }
    } catch (e) {
      console.log("searchmaterialsに失敗しました：" + e);
    }
  };

  const items = [
    createData(
      "シャワースクリーン　( JH )",
      "10個入り",
      ssJh,
      materialConst.unitPrice.SsJh
    ),
    createData(
      "シャワースクリーン　( JA )",
      "10個入り",
      ssJa,
      materialConst.unitPrice.SsJa
    ),
    createData(
      "エルボー　　　　アイボリー",
      "10個入り",
      elbowI,
      materialConst.unitPrice.ElbowI
    ),
    createData(
      offerTime.substring(0, 4) === "2021" ||
        (offerTime.substring(0, 4) === "2022" &&
          offerTime.substring(5, 7) === "01")
        ? "エルボー　　　　半透明"
        : "エルボー　　　　グレー",
      "10個入り",
      elbowH,
      materialConst.unitPrice.ElbowH
    ),
    createData(
      "洗濯水栓ニップル",
      "10個入り",
      nipple,
      materialConst.unitPrice.Nipple
    ),
    createData(
      "シャワー　　　　ヘッドホース",
      "",
      shower,
      materialConst.unitPrice.Shower
    ),
    createData("防熱板", "", heatShield, materialConst.unitPrice.HeatShield),
    createData(
      "蒸発防止剤　　　ニオイしません",
      "1本",
      antiEva,
      materialConst.unitPrice.AntiEva
    ),
    createData(
      "パイプファン　　プラグ無し",
      "",
      pipeFanUnPlug,
      materialConst.unitPrice.PipeFanUnPlug
    ),
    createData(
      "パイプファン　　プラグ有り",
      "",
      pipeFanPlug,
      materialConst.unitPrice.PipeFanPlug
    ),
    createData("換気扇　　　　　1室用", "", fan, materialConst.unitPrice.Fan),
    createData(
      "三角パッキン　　パッキン+ワッシャー",
      "200組",
      triangle,
      materialConst.unitPrice.Triangle
    ),
    createData(
      "ケレップ　　　　コマパッキン",
      "200組",
      top,
      materialConst.unitPrice.Top
    ),
    createData(
      "フィルター　　　大",
      "",
      filterL,
      materialConst.unitPrice.FilterL
    ),
    createData(
      "フィルター　　　小",
      "",
      filterS,
      materialConst.unitPrice.FilterS
    ),
    createData("新フレグランスワックス", "", wax, materialConst.unitPrice.Wax),
    createData("", "", "", 0),
    createData("", "", "", 0),
    createData("", "", "", 0),
    createData("", "", "", 0),
    createData("", "", "", 0),
    createData("", "", "", 0),
    createData("", "", "", 0),
  ];

  const subT: number = subTotal(
    Number(ssJh),
    Number(ssJa),
    Number(elbowI),
    Number(elbowH),
    Number(nipple),
    Number(shower),
    Number(heatShield),
    Number(antiEva),
    Number(pipeFanUnPlug),
    Number(pipeFanPlug),
    Number(fan),
    Number(triangle),
    Number(top),
    Number(filterL),
    Number(filterS),
    Number(wax)
  );

  return (
    //<PDFViewer width="100%" height="500">
    <PDFViewer
      style={{ position: "absolute", border: 0, width: "100%", height: "100%" }}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <View style={styles.headerColumnTitle}>
              <MyText fontSize="12">{pdfConst.CO_NAME}　　　御中</MyText>
            </View>
            <View style={styles.headerColumnDate}>
              <MyText fontSize="11" textAlign="right">
                {offerTime}
              </MyText>
            </View>
          </View>
          <Grid height={gridHeight} borderTop borderLeft borderRight>
            <ListColumn widthPer="40%" borderRight>
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft2}
                marginTop={textMarginTop}
              >
                部材　買取依頼表
              </MyText>
            </ListColumn>
            <ListColumn widthPer="60%">
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft}
                marginTop={textMarginTop}
              >
                業者名:　{userName}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={gridHeight} borderTop borderLeft borderRight>
            <ListColumn widthPer="55%" borderRight>
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft2}
                marginTop={textMarginTop}
              >
                部材・商品名
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%" borderRight>
              <MyText
                fontSize="11"
                textAlign="right"
                marginRight={textMarginRight}
                marginTop={textMarginTop}
              >
                数量
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%" borderRight>
              <MyText
                fontSize="11"
                textAlign="right"
                marginRight={textMarginRight}
                marginTop={textMarginTop}
              >
                単価
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%">
              <MyText
                fontSize="11"
                textAlign="right"
                marginRight={textMarginRight}
                marginTop={textMarginTop}
              >
                金額
              </MyText>
            </ListColumn>
          </Grid>
          {items.map((item) => (
            <Grid height={gridHeight} borderTop borderLeft borderRight>
              <ListColumn widthPer="40%">
                <MyText
                  fontSize="10"
                  textAlign="left"
                  marginLeft={textMarginLeft2}
                  marginTop={textMarginTop}
                >
                  {item.material}
                </MyText>
              </ListColumn>
              <ListColumn widthPer="15%" borderRight>
                <MyText
                  fontSize="11"
                  textAlign="left"
                  marginLeft={textMarginLeft2}
                  marginTop={textMarginTop}
                >
                  {item.materialSub}
                </MyText>
              </ListColumn>
              <ListColumn widthPer="15%" borderRight>
                <MyText
                  fontSize="11"
                  textAlign="right"
                  marginRight={textMarginRight}
                  marginTop={textMarginTop}
                >
                  {item.quantity === "0" ? "" : item.quantity}
                </MyText>
              </ListColumn>
              <ListColumn widthPer="15%" borderRight>
                <MyText
                  fontSize="11"
                  textAlign="right"
                  marginRight={textMarginRight}
                  marginTop={textMarginTop}
                >
                  {item.unitPrice === 0 ? "" : item.unitPrice.toLocaleString()}
                </MyText>
              </ListColumn>
              <ListColumn widthPer="15%">
                <MyText
                  fontSize="11"
                  textAlign="right"
                  marginRight={textMarginRight}
                  marginTop={textMarginTop}
                >
                  {amountOfMoney(item.quantity, item.unitPrice)}
                </MyText>
              </ListColumn>
            </Grid>
          ))}
          <Grid height={gridHeight} borderTop borderLeft borderRight>
            <ListColumn widthPer="70%" borderRight>
              <MyText fontSize="11" textAlign="left">
                　
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%" borderRight>
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft}
                marginTop={textMarginTop}
              >
                小計
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%">
              <MyText
                fontSize="11"
                textAlign="right"
                marginRight={textMarginRight}
                marginTop={textMarginTop}
              >
                {subT.toLocaleString()}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={gridHeight} borderTop borderLeft borderRight>
            <ListColumn widthPer="70%" borderRight>
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft2}
                marginTop={textMarginTop}
              >
                ※{offsetDate}
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%" borderRight>
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft}
                marginTop={textMarginTop}
              >
                消費税
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%">
              <MyText
                fontSize="11"
                textAlign="right"
                marginRight={textMarginRight}
                marginTop={textMarginTop}
              >
                {Math.floor(subT * materialConst.taxRate).toLocaleString()}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid
            height={gridHeight}
            borderTop
            borderLeft
            borderRight
            borderBottom
          >
            <ListColumn widthPer="70%" borderRight>
              <MyText fontSize="11" textAlign="left">
                　
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%" borderRight>
              <MyText
                fontSize="11"
                textAlign="left"
                marginLeft={textMarginLeft}
                marginTop={textMarginTop}
              >
                合計
              </MyText>
            </ListColumn>
            <ListColumn widthPer="15%">
              <MyText
                fontSize="11"
                textAlign="right"
                marginRight={textMarginRight}
                marginTop={textMarginTop}
              >
                {Math.floor(
                  subT * (1 + materialConst.taxRate)
                ).toLocaleString()}
              </MyText>
            </ListColumn>
          </Grid>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default MaterialsPdf;
