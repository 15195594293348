// 全角英数字記号を半角に変換する
export function zen2Han(val: string): string {
	var regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;
	const value = val.replace(regex, function(s) {
		return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
	})
	.replace(/[Ⅰ]/g, "1")
	.replace(/[Ⅱ]/g, "2")
	.replace(/[Ⅲ]/g, "3")
	.replace(/[Ⅳ]/g, "4")
	.replace(/[Ⅴ]/g, "5")
	.replace(/[Ⅵ]/g, "6")
	.replace(/[Ⅶ]/g, "7")
	.replace(/[Ⅷ]/g, "8")
	.replace(/[Ⅸ]/g, "9")
	.replace(/[Ⅹ]/g, "10")
	.replace(/[‐－―]/g, "-")
	.replace(/[～〜]/g, "~");
	//.replace(/　/g, " ");

	return value;
}