import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import firebase, { db } from "../../../config/firebase";
import {
  rowDisabledWorksEdit,
  rowDisabledWorksReportEdit,
} from "../../../common/role";
import ResponsiveDrawer from "../../templates/ResponsiveDrawer";
import { AuthContext } from "../../../store/AuthContext";
import { ConfigContext } from "../../../store/ConfigContext";
import WorksDialog from "./components/WorksDialog";
import WorksState from "./components/WorksState";
import { usersConst, worksConst } from "../../../config/Const";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Container,
  Tooltip,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReportIcon from "@material-ui/icons/Report";
import DescriptionIcon from "@material-ui/icons/Description";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  container: {
    minWidth: "100vh",
    textAlign: "right",
  },
  icon: {
    color: "#3F51B5",
  },
  iconrep: {
    color: "magenta",
  },
}));

const Works: React.FC = (props: any) => {
  const classes = useStyles();
  const configValue = useContext(ConfigContext);
  const authValue = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [works, setWorks] = useState<firebase.firestore.DocumentData[]>([]);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpenId, setEditOpenId] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const handleChange = (): void => setIsChange(!isChange);

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authValue.currentUser]);

  useEffect(() => {
    searchWorks();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChange]);

  const searchWorks = async () => {
    try {
      var worksRef;
      // NOTE: FirebaseのWebコンソールより手動で複合インデックスを設定
      // NOTE: index1（1:team 昇順 2:scheduled_date 降順 3:user_id 昇順）、コレクション
      // NOTE: index2（1:team 昇順 2:user_id 昇順 3:scheduled_date 降順 ）、コレクション
      if (
        authValue.users.role === usersConst.roleName.Manager ||
        authValue.users.role === usersConst.roleName.Admin
      ) {
        worksRef = await db
          .collection("works")
          .where("team", "==", authValue.users.team)
          .orderBy("scheduled_date", "desc")
          .orderBy("user_id", "asc")
          .get();
      } else {
        worksRef = await db
          .collection("works")
          .where("team", "==", authValue.users.team)
          .where("user_id", "==", authValue.users.doc_id)
          .orderBy("scheduled_date", "desc")
          .get();
      }

      if (worksRef.empty) return [];
      const workList: firebase.firestore.DocumentData[] = [];
      worksRef.forEach((doc) => {
        workList.push({
          doc_id: doc.id,
          ...doc.data(),
        });
      });
      setWorks(workList);
    } catch (e) {
      console.log("searchWorksに失敗しました:" + e);
    }
  };

  const handleDelete = async (doc_id: string) => {
    if (window.confirm("削除しますか？")) {
      try {
        await db.runTransaction(async (transaction) => {
          const worksRef = db.collection("works").doc(doc_id);
          await transaction.get(worksRef);
          const invoicesRef = db.collection("invoices").doc(doc_id);
          transaction.delete(invoicesRef);
          const ref = await db.collection("reports").doc(doc_id).get();
          if (ref.exists) {
            const reportRef = db.collection("reports").doc(doc_id);
            transaction.delete(reportRef);
          }
          transaction.delete(worksRef);
        });

        // 初期化
        setWorks([]);
        searchWorks();
      } catch (e) {
        console.log("handleDeleteに失敗しました:" + e);
      }
    }
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleEditOpen = (id: any) => {
    setEditOpenId(id);
  };

  const handleEditClose = () => {
    setEditOpenId(null);
  };

  const handleViewOpen = (id: any) => {
    handleChange();
    props.history.push("/worksReport/" + id);
  };

  function toolTipRepo(state: string): string {
    if (state === worksConst.stateName.Si) {
      return "作業報告書を承認してください";
    } else if (state === worksConst.stateName.Ng) {
      return "差戻し中です";
    } else if (state === worksConst.stateName.Ni) {
      return "差戻し依頼があります";
    } else {
      return "";
    }
  }

  function toolTipEditRepo(state: string): string {
    if (state === worksConst.stateName.Ka) {
      return "作業報告書を作成し、承認依頼をしてください";
    } else if (state === worksConst.stateName.Si) {
      return "承認依頼中です";
    } else if (state === worksConst.stateName.Ng) {
      return "差戻しされました、再度承認依頼をしてください";
    } else if (state === worksConst.stateName.Ni) {
      return "差戻し依頼中です";
    } else {
      return "";
    }
  }

  const AddButtonGroup = () => {
    if (authValue.users.role === usersConst.roleName.User) {
      return (
        <>
          <Container className={classes.container}>
            <Button onClick={() => handleAddOpen()}>
              <AddCircleIcon className={classes.icon} fontSize="large" />
              <Typography>追加</Typography>
            </Button>
            <WorksDialog
              title={"WORKを追加する"}
              isOpen={addOpen}
              buttonText="追加"
              fncClose={() => handleAddClose()}
              fncIsChange={() => handleChange()}
              userDocId={authValue.users.doc_id}
              team={authValue.users.team}
              userName={authValue.users.name}
            />
          </Container>
        </>
      );
    } else {
      return <></>;
    }
  };

  const RepoIcon = (props: any) => {
    if (
      (props.state === worksConst.stateName.Si &&
        (authValue.users.role === usersConst.roleName.Manager ||
          authValue.users.role === usersConst.roleName.Admin)) ||
      (props.state === worksConst.stateName.Ni &&
        (authValue.users.role === usersConst.roleName.Manager ||
          authValue.users.role === usersConst.roleName.Admin)) ||
      (props.state === worksConst.stateName.Ok &&
        (authValue.users.role === usersConst.roleName.Manager ||
          authValue.users.role === usersConst.roleName.Admin)) ||
      (props.state === worksConst.stateName.Ng &&
        (authValue.users.role === usersConst.roleName.Manager ||
          authValue.users.role === usersConst.roleName.Admin))
    ) {
      return (
        <>
          <IconButton
            className={classes.iconrep}
            size="small"
            onClick={() => handleViewOpen(props.doc_id)}
          >
            <Tooltip title={toolTipRepo(props.state)} placement="top">
              {props.state === worksConst.stateName.Ok ? (
                <DescriptionIcon />
              ) : (
                <ReportIcon />
              )}
            </Tooltip>
          </IconButton>
        </>
      );
    } else {
      return <></>;
    }
  };

  const PdfRepoIcon = (props: any) => {
    if (props.state === worksConst.stateName.Ok) {
      return (
        <>
          <Link to={"/worksPdf/" + props.doc_id} target="_blank">
            <IconButton className={classes.iconrep} size="small">
              <PdfIcon />
            </IconButton>
          </Link>
        </>
      );
    } else {
      return <></>;
    }
  };

  const EditButtonGroup = (props: any) => {
    if (authValue.users.role === usersConst.roleName.User) {
      return (
        <>
          <Button
            onClick={() => handleEditOpen(props.doc_id)}
            disabled={rowDisabledWorksEdit(
              props.user_id,
              props.state,
              authValue.currentUser?.uid
            )}
          >
            <EditIcon className={classes.icon} fontSize="large" />
            <Typography>編集</Typography>
          </Button>
          <WorksDialog
            title={"WORKを編集する"}
            isOpen={editOpenId === props.doc_id}
            buttonText="編集"
            fncClose={() => handleEditClose()}
            fncIsChange={() => handleChange()}
            userDocId={authValue.users.doc_id}
            docId={props.doc_id}
            editArea={props.area}
            editName={props.name}
            editRoom={props.room}
            editFixedDate={props.fixed_date}
            editScheduledDate={props.scheduled_date}
            editCompletionDate={props.completion_date}
            editState={props.state}
            team={authValue.users.team}
            reportFlg={props.reportFlg}
          />
          <Button
            onClick={() => handleDelete(props.doc_id)}
            disabled={rowDisabledWorksEdit(
              props.user_id,
              props.state,
              authValue.currentUser?.uid
            )}
          >
            <DeleteIcon className={classes.icon} fontSize="large" />
            <Typography>削除</Typography>
          </Button>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {loading ? (
        <div>LOADING.....</div>
      ) : (
        <ResponsiveDrawer title={configValue.lang.works}>
          <AddButtonGroup />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">完了報告書</TableCell>
                  <TableCell align="left">完了予定</TableCell>
                  {authValue.users.role === usersConst.roleName.Manager ||
                  authValue.users.role === usersConst.roleName.Admin ? (
                    <TableCell align="left">作業者</TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell align="left">AREA</TableCell>
                  <TableCell align="left">NAME</TableCell>
                  <TableCell align="left">ROOM</TableCell>
                  <TableCell align="left">期日</TableCell>
                  <TableCell align="left">完了日</TableCell>
                  <TableCell align="left">STATE</TableCell>
                  {authValue.users.role === usersConst.roleName.User ? (
                    <TableCell align="left">報告書作成</TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {works.map((row) => (
                  <TableRow hover key={row.doc_id}>
                    <TableCell align="center">
                      <RepoIcon doc_id={row.doc_id} state={row.state} />
                      <PdfRepoIcon doc_id={row.doc_id} state={row.state} />
                    </TableCell>
                    <TableCell align="left" component="th" scope="row">
                      {row.scheduled_date}
                    </TableCell>
                    {authValue.users.role === usersConst.roleName.Manager ||
                    authValue.users.role === usersConst.roleName.Admin ? (
                      <TableCell align="left">{row.user_name}</TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell align="left">{row.area}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.room}</TableCell>
                    <TableCell align="left">
                      {row.fixed_date.substr(5, 5)}
                    </TableCell>
                    <TableCell align="left">
                      {row.completion_date.substr(5, 5)}
                    </TableCell>
                    <TableCell align="center">
                      <WorksState>{row.state}</WorksState>
                    </TableCell>
                    {authValue.users.role === usersConst.roleName.User ? (
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconrep}
                          size="small"
                          onClick={() => handleViewOpen(row.doc_id)}
                          disabled={rowDisabledWorksReportEdit(row.state)}
                        >
                          <Tooltip
                            title={toolTipEditRepo(row.state)}
                            placement="top"
                          >
                            <EditTwoToneIcon />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell align="right">
                      <EditButtonGroup
                        doc_id={row.doc_id}
                        user_id={row.user_id}
                        area={row.area}
                        name={row.name}
                        room={row.room}
                        fixed_date={row.fixed_date}
                        scheduled_date={row.scheduled_date}
                        completion_date={row.completion_date}
                        state={row.state}
                        reportFlg={row.reportFlg}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default Works;
