import React, { useEffect, useState, useContext } from 'react';
import firebase, { db } from '../../../config/firebase';
import ResponsiveDrawer from '../../templates/ResponsiveDrawer';
import { AuthContext } from '../../../store/AuthContext';
import { ConfigContext } from '../../../store/ConfigContext';
import UsersDialog from './components/UsersDialog';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { usersConst } from '../../../config/Const';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  container: {
    minWidth: '100vh',
    textAlign: 'right',
  },
  icon: {
    color: '#3F51B5',
  },
}));

const Users: React.FC = (props: any) => {
  const classes = useStyles();
  const configValue = useContext(ConfigContext);
  const authValue = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<firebase.firestore.DocumentData[]>([]);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpenId, setEditOpenId] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const handleChange = (): void => setIsChange(!isChange); 

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
  }, [authValue.currentUser]);

  useEffect(() => {
    searchUsers();
    setLoading(false);
  }, [isChange]);

  const searchUsers = async() => {
    // NOTE: FirebaseのWebコンソールより手動で複合インデックスを設定
    // NOTE: index（1:team 昇順 2:name 昇順）、コレクション
    const res = await db.collection('users')
      .where('team', '==', authValue.users.team)
      .orderBy('name')
      .get();
    if (res.empty) return [];
    const userList: firebase.firestore.DocumentData[] = [];
    // DocumentData型にはmapメソッドが定義されていないためforEach使用
    res.forEach(doc => {
      userList.push({
        doc_id: doc.id,
        ...doc.data(),
      });
    });
    setUsers(userList);
  }

  const handleDelete = (doc_id: string) => {
    if (window.confirm("削除しますか？")) {
      db.collection('users').doc(doc_id).delete().then(() => {
        // 初期化
        setUsers([]);
        searchUsers();        
      })
      .catch((e) => {
        console.log("削除に失敗しました：" + e);
      });
    }
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleEditOpen = (id: any) => {
    setEditOpenId(id);
  };

  const handleEditClose = () => {
    setEditOpenId(null);
  };

  const AddButtonGroup = () => {
    if (authValue.users.role===usersConst.roleName.Admin) {
      return (
        <>
        <Container className={classes.container}>
          <Button onClick={() => handleAddOpen()} >
            <AddCircleIcon className={classes.icon} fontSize="large" />
            <Typography>追加</Typography>
          </Button>
          <UsersDialog 
            title={'USERを追加する'} 
            isOpen={addOpen} 
            buttonText="追加" 
            fncClose={() => handleAddClose()} 
            fncIsChange={() => handleChange()} 
            currentUserRole={authValue.users.role} 
          /> 
        </Container>
        </>
      );
    } else {
      return (<></>);
    }
  }

  const EditButtonGroup = (props: any) => {
    if (authValue.users.role===usersConst.roleName.Admin) {
      return (
        <>
        <Button 
          onClick={() => handleEditOpen(props.doc_id)} 
          disabled={authValue.users.role === 'admin' ? false : true} 
        >
          <EditIcon className={classes.icon} fontSize="large" />
          <Typography>編集</Typography>
        </Button>
        <UsersDialog
          title={'USERを編集する'} 
          isOpen={editOpenId === props.doc_id} 
          buttonText="編集" 
          fncClose={() => handleEditClose()} 
          fncIsChange={() => handleChange()} 
          docId={props.doc_id} 
          editName={props.name} 
          editCategory={props.category} 
          editRole={props.role} 
          editMail={props.mail}
          currentUserRole={authValue.users.role} 
        /> 
        <Button 
          onClick={() => handleDelete(props.doc_id)} 
          disabled={(authValue.users.role === 'admin') ? false : true}
        >
          <DeleteIcon className={classes.icon} fontSize="large" />
          <Typography>削除</Typography>
        </Button>
        </>
      );
    } else {
      return (<></>);
    }
  }

  return (
    <>
      {
        loading ? (
          <div>LOADING.....</div>
        ) : 
          <ResponsiveDrawer title={configValue.lang.users}>
            <AddButtonGroup />
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">NAME</TableCell>
                    <TableCell align="left">CATEGORY</TableCell>
                    <TableCell align="left">ROLE</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <TableRow hover key={row.doc_id}>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="left">{row.category}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                    <TableCell align="left">
                      <EditButtonGroup 
                        doc_id={row.doc_id} 
                        name={row.name} 
                        category={row.category}
                        role={row.role}
                        mail={row.mail} 
                      />
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ResponsiveDrawer>
      }
    </>
  );
}

export default Users;