import React, { useEffect, useState, useContext } from "react";
import { db, timestamp } from "../../../../config/firebase";
import ResponsiveDrawer from "../../../templates/ResponsiveDrawer";
import { AuthContext } from "../../../../store/AuthContext";
import { ConfigContext } from "../../../../store/ConfigContext";
import {
  Divider,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import { materialConst } from "../../../../config/Const";
import { subTotal } from "../common/MaterialsFunction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100ch",
      marginBottom: 20,
    },
    flexGrow: 1,
  },
  formControlPlan: {
    margin: theme.spacing(1),
    width: "14ch",
  },
  formControlAircon: {
    margin: theme.spacing(1),
    width: "12ch",
  },
  formControlInterior: {
    marginLeft: theme.spacing(4),
    width: "40ch",
  },
  textfield: {
    marginTop: theme.spacing(1),
    width: "12ch",
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "12ch",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    padding: theme.spacing(2),
    fontSize: 20,
    textAlign: "right",
  },
}));

type Inputs = {
  ssJh: string;
  ssJa: string;
  elbowI: string;
  elbowH: string;
  nipple: string;
  shower: string;
  heatShield: string;
  antiEva: string;
  pipeFanUnPlug: string;
  pipeFanPlug: string;
  fan: string;
  triangle: string;
  top: string;
  filterL: string;
  filterS: string;
  wax: string;
};

const MaterialsEdit: React.FC = (props: any) => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const configValue = useContext(ConfigContext);
  const { params } = props.match;
  const [addSsJh, setAddSsJh] = useState("0");
  const [addSsJa, setAddSsJa] = useState("0");
  const [addElbowI, setAddElbowI] = useState("0");
  const [addElbowH, setAddElbowH] = useState("0");
  const [addNipple, setAddNipple] = useState("0");
  const [addShower, setAddShower] = useState("0");
  const [addHeatShield, setAddHeatShield] = useState("0");
  const [addAntiEva, setAddAntiEva] = useState("0");
  const [addPipeFanUnPlug, setAddPipeFanUnPlug] = useState("0");
  const [addPipeFanPlug, setAddPipeFanPlug] = useState("0");
  const [addFan, setAddFan] = useState("0");
  const [addTriangle, setAddTriangle] = useState("0");
  const [addTop, setAddTop] = useState("0");
  const [addFilterL, setAddFilterL] = useState("0");
  const [addFilterS, setAddFilterS] = useState("0");
  const [addWax, setAddWax] = useState("0");
  const [addState, setAddState] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      ssJh: addSsJh,
      ssJa: addSsJa,
      elbowI: addElbowI,
      elbowH: addElbowH,
      nipple: addNipple,
      shower: addShower,
      heatShield: addHeatShield,
      antiEva: addAntiEva,
      pipeFanUnPlug: addPipeFanUnPlug,
      pipeFanPlug: addPipeFanPlug,
      fan: addFan,
      triangle: addTriangle,
      top: addTop,
      filterL: addFilterL,
      filterS: addFilterS,
      wax: addWax,
    },
  });

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
  }, [authValue.currentUser]);

  useEffect(() => {
    if (
      (params.action !== "add" &&
        params.action !== "edit" &&
        params.action !== "view") ||
      (params.action === "add" && params.docId !== undefined) ||
      (params.action === "edit" && params.docId === undefined)
    )
      props.history.push("/materials");
    if (params.action === "edit" || params.action === "view") searchMaterials();
  }, []);

  const searchMaterials = async () => {
    try {
      const materialRef = db.collection("materials").doc(params.docId);
      const materialDoc = await materialRef.get();
      if (materialDoc.exists) {
        if (materialDoc.get("ss_jh") !== undefined)
          setAddSsJh(materialDoc.get("ss_jh"));
        if (materialDoc.get("ss_ja") !== undefined)
          setAddSsJa(materialDoc.get("ss_ja"));
        if (materialDoc.get("elbow_i") !== undefined)
          setAddElbowI(materialDoc.get("elbow_i"));
        if (materialDoc.get("elbow_h") !== undefined)
          setAddElbowH(materialDoc.get("elbow_h"));
        if (materialDoc.get("nipple") !== undefined)
          setAddNipple(materialDoc.get("nipple"));
        if (materialDoc.get("shower") !== undefined)
          setAddShower(materialDoc.get("shower"));
        if (materialDoc.get("heat_shield") !== undefined)
          setAddHeatShield(materialDoc.get("heat_shield"));
        if (materialDoc.get("anti_eva") !== undefined)
          setAddAntiEva(materialDoc.get("anti_eva"));
        if (materialDoc.get("pipe_fan_un_plug") !== undefined)
          setAddPipeFanUnPlug(materialDoc.get("pipe_fan_un_plug"));
        if (materialDoc.get("pipe_fan_plug") !== undefined)
          setAddPipeFanPlug(materialDoc.get("pipe_fan_plug"));
        if (materialDoc.get("fan") !== undefined)
          setAddFan(materialDoc.get("fan"));
        if (materialDoc.get("triangle") !== undefined)
          setAddTriangle(materialDoc.get("triangle"));
        if (materialDoc.get("top") !== undefined)
          setAddTop(materialDoc.get("top"));
        if (materialDoc.get("filterL") !== undefined)
          setAddFilterL(materialDoc.get("filterL"));
        if (materialDoc.get("filterS") !== undefined)
          setAddFilterS(materialDoc.get("filterS"));
        if (materialDoc.get("wax") !== undefined)
          setAddWax(materialDoc.get("wax"));
        if (materialDoc.get("state") !== undefined)
          setAddState(materialDoc.get("state"));
      } else {
        props.history.push("/materials");
      }
    } catch (e) {
      console.log("searchmaterialsに失敗しました：" + e);
    }
  };

  const handleClose = () => {
    //reset();
    props.history.goBack();
  };

  const onSubmit: SubmitHandler<Inputs> = ({
    ssJh,
    ssJa,
    elbowI,
    elbowH,
    nipple,
    shower,
    heatShield,
    antiEva,
    pipeFanUnPlug,
    pipeFanPlug,
    fan,
    triangle,
    top,
    filterL,
    filterS,
    wax,
  }) => {
    if (
      ssJh === "0" &&
      ssJa === "0" &&
      elbowI === "0" &&
      elbowH === "0" &&
      nipple === "0" &&
      shower === "0" &&
      heatShield === "0" &&
      antiEva === "0" &&
      pipeFanUnPlug === "0" &&
      pipeFanPlug === "0" &&
      fan === "0" &&
      triangle === "0" &&
      top === "0" &&
      filterL === "0" &&
      filterS === "0" &&
      wax === "0"
    ) {
      alert("購入数量を指定してください");
      return;
    }
    reset();
    if (params.action === "add") handleAdd();
    if (params.action === "edit" || params.action === "view") handleEdit();
  };

  const handleAdd = async () => {
    try {
      const materialRef = db.collection("materials");
      await materialRef.add({
        createTime: timestamp,
        team: authValue.users.team,
        user_id: authValue.users.doc_id,
        user_name: authValue.users.name,
        ss_jh: addSsJh,
        ss_ja: addSsJa,
        elbow_i: addElbowI,
        elbow_h: addElbowH,
        nipple: addNipple,
        shower: addShower,
        heat_shield: addHeatShield,
        anti_eva: addAntiEva,
        pipe_fan_un_plug: addPipeFanUnPlug,
        pipe_fan_plug: addPipeFanPlug,
        fan: addFan,
        triangle: addTriangle,
        top: addTop,
        filterL: addFilterL,
        filterS: addFilterS,
        wax: addWax,
        state: materialConst.state.Mikakutei,
        total: Math.floor(subT * (1 + materialConst.taxRate)).toString(),
      });
      props.history.goBack();
    } catch (e) {
      console.log("編集に失敗しました：" + e);
    }
  };

  const handleEdit = async () => {
    try {
      const materialRef = db.collection("materials").doc(params.docId);
      await materialRef.set(
        {
          ss_jh: addSsJh,
          ss_ja: addSsJa,
          elbow_i: addElbowI,
          elbow_h: addElbowH,
          nipple: addNipple,
          shower: addShower,
          heat_shield: addHeatShield,
          anti_eva: addAntiEva,
          pipe_fan_un_plug: addPipeFanUnPlug,
          pipe_fan_plug: addPipeFanPlug,
          fan: addFan,
          triangle: addTriangle,
          top: addTop,
          filterL: addFilterL,
          filterS: addFilterS,
          wax: addWax,
          state: materialConst.state.Mikakutei,
          total: Math.floor(subT * (1 + materialConst.taxRate)).toString(),
        },
        { merge: true }
      );

      props.history.goBack();
    } catch (e) {
      console.log("編集に失敗しました：" + e);
    }
  };

  const TextFieldSsJh = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-ssjh"
        label=""
        type="number"
        value={addSsJh}
        InputProps={{
          endAdornment: <InputAdornment position="end">セット</InputAdornment>,
        }}
        aria-invalid={errors.ssJh ? "true" : "false"}
        {...register("ssJh", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.ssJh)}
        helperText={[
          errors.ssJh &&
            errors.ssJh.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.ssJh &&
            errors.ssJh.type === "pattern" &&
            "購入セット数を入力してください",
        ]}
        onChange={(event) => {
          setAddSsJh(event.target.value);
          setValue("ssJh", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldSsJa = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-ssja"
        label=""
        type="number"
        value={addSsJa}
        InputProps={{
          endAdornment: <InputAdornment position="end">セット</InputAdornment>,
        }}
        aria-invalid={errors.ssJa ? "true" : "false"}
        {...register("ssJa", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.ssJa)}
        helperText={[
          errors.ssJa &&
            errors.ssJa.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.ssJa &&
            errors.ssJa.type === "pattern" &&
            "購入セット数を入力してください",
        ]}
        onChange={(event) => {
          setAddSsJa(event.target.value);
          setValue("ssJa", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldElbowI = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-elbowi"
        label=""
        type="number"
        value={addElbowI}
        InputProps={{
          endAdornment: <InputAdornment position="end">セット</InputAdornment>,
        }}
        aria-invalid={errors.elbowI ? "true" : "false"}
        {...register("elbowI", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.elbowI)}
        helperText={[
          errors.elbowI &&
            errors.elbowI.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.elbowI &&
            errors.elbowI.type === "pattern" &&
            "購入セット数を入力してください",
        ]}
        onChange={(event) => {
          setAddElbowI(event.target.value);
          setValue("elbowI", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldElbowH = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-elbowh"
        label=""
        type="number"
        value={addElbowH}
        InputProps={{
          endAdornment: <InputAdornment position="end">セット</InputAdornment>,
        }}
        aria-invalid={errors.elbowH ? "true" : "false"}
        {...register("elbowH", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.elbowH)}
        helperText={[
          errors.elbowH &&
            errors.elbowH.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.elbowH &&
            errors.elbowH.type === "pattern" &&
            "購入セット数を入力してください",
        ]}
        onChange={(event) => {
          setAddElbowH(event.target.value);
          setValue("elbowH", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldNipple = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-nipple"
        label=""
        type="number"
        value={addNipple}
        InputProps={{
          endAdornment: <InputAdornment position="end">セット</InputAdornment>,
        }}
        aria-invalid={errors.nipple ? "true" : "false"}
        {...register("nipple", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.nipple)}
        helperText={[
          errors.nipple &&
            errors.nipple.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.nipple &&
            errors.nipple.type === "pattern" &&
            "購入セット数を入力してください",
        ]}
        onChange={(event) => {
          setAddNipple(event.target.value);
          setValue("nipple", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldShower = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-shower"
        label=""
        type="number"
        value={addShower}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.shower ? "true" : "false"}
        {...register("shower", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.shower)}
        helperText={[
          errors.shower &&
            errors.shower.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.shower &&
            errors.shower.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddShower(event.target.value);
          setValue("shower", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldHeatShield = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-heatShield"
        label=""
        type="number"
        value={addHeatShield}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.heatShield ? "true" : "false"}
        {...register("heatShield", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.heatShield)}
        helperText={[
          errors.heatShield &&
            errors.heatShield.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.heatShield &&
            errors.heatShield.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddHeatShield(event.target.value);
          setValue("heatShield", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldAntiEva = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-antiEva"
        label=""
        type="number"
        value={addAntiEva}
        InputProps={{
          endAdornment: <InputAdornment position="end">本</InputAdornment>,
        }}
        aria-invalid={errors.antiEva ? "true" : "false"}
        {...register("antiEva", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.antiEva)}
        helperText={[
          errors.antiEva &&
            errors.antiEva.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.antiEva &&
            errors.antiEva.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddAntiEva(event.target.value);
          setValue("antiEva", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldPipeFanUnPlug = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-pipeFanUnPlug"
        label=""
        type="number"
        value={addPipeFanUnPlug}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.pipeFanUnPlug ? "true" : "false"}
        {...register("pipeFanUnPlug", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.pipeFanUnPlug)}
        helperText={[
          errors.pipeFanUnPlug &&
            errors.pipeFanUnPlug.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.pipeFanUnPlug &&
            errors.pipeFanUnPlug.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddPipeFanUnPlug(event.target.value);
          setValue("pipeFanUnPlug", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldPipeFanPlug = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-pipeFanPlug"
        label=""
        type="number"
        value={addPipeFanPlug}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.pipeFanPlug ? "true" : "false"}
        {...register("pipeFanPlug", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.pipeFanPlug)}
        helperText={[
          errors.pipeFanPlug &&
            errors.pipeFanPlug.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.pipeFanPlug &&
            errors.pipeFanPlug.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddPipeFanPlug(event.target.value);
          setValue("pipeFanPlug", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldFan = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-fan"
        label=""
        type="number"
        value={addFan}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.fan ? "true" : "false"}
        {...register("fan", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.fan)}
        helperText={[
          errors.fan &&
            errors.fan.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.fan &&
            errors.fan.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddFan(event.target.value);
          setValue("fan", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldTriangle = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-triagle"
        label=""
        type="number"
        value={addTriangle}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.triangle ? "true" : "false"}
        {...register("triangle", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.triangle)}
        helperText={[
          errors.triangle &&
            errors.triangle.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.triangle &&
            errors.triangle.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddTriangle(event.target.value);
          setValue("triangle", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldTop = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-top"
        label=""
        type="number"
        value={addTop}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.top ? "true" : "false"}
        {...register("top", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.top)}
        helperText={[
          errors.top &&
            errors.top.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.top &&
            errors.top.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddTop(event.target.value);
          setValue("top", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldFilterL = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-filterL"
        label=""
        type="number"
        value={addFilterL}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.filterL ? "true" : "false"}
        {...register("filterL", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.filterL)}
        helperText={[
          errors.filterL &&
            errors.filterL.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.filterL &&
            errors.filterL.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddFilterL(event.target.value);
          setValue("filterL", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldFilterS = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-filterS"
        label=""
        type="number"
        value={addFilterS}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.filterS ? "true" : "false"}
        {...register("filterS", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.filterS)}
        helperText={[
          errors.filterS &&
            errors.filterS.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.filterS &&
            errors.filterS.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddFilterS(event.target.value);
          setValue("filterS", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const TextFieldWax = () => {
    return (
      <TextField
        className={classes.textfield}
        id="text-wax"
        label=""
        type="number"
        value={addWax}
        InputProps={{
          endAdornment: <InputAdornment position="end">個</InputAdornment>,
        }}
        aria-invalid={errors.wax ? "true" : "false"}
        {...register("wax", {
          maxLength: 1,
          pattern: /\d+(?:\.\d+)?/,
        })}
        error={Boolean(errors.wax)}
        helperText={[
          errors.wax &&
            errors.wax.type === "maxLength" &&
            "1桁以内で入力してください",
          errors.wax &&
            errors.wax.type === "pattern" &&
            "購入数量を入力してください",
        ]}
        onChange={(event) => {
          setAddWax(event.target.value);
          setValue("wax", event.target.value);
        }}
        disabled={
          params.action === "view" || addState === materialConst.state.KonyuIrai
            ? true
            : false
        }
      />
    );
  };

  const subT: number = subTotal(
    Number(addSsJh),
    Number(addSsJa),
    Number(addElbowI),
    Number(addElbowH),
    Number(addNipple),
    Number(addShower),
    Number(addHeatShield),
    Number(addAntiEva),
    Number(addPipeFanUnPlug),
    Number(addPipeFanPlug),
    Number(addFan),
    Number(addTriangle),
    Number(addTop),
    Number(addFilterL),
    Number(addFilterS),
    Number(addWax)
  );

  return (
    <>
      <ResponsiveDrawer
        title={configValue.lang.materials + "　@" + params.action}
      >
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Divider className={classes.divider} />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">部品材料</TableCell>
                  <TableCell align="left">購入数量</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover key={1}>
                  <TableCell align="left">
                    シャワースクリーン（JH）10個入　単価
                    {materialConst.unitPrice.SsJh.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldSsJh />
                  </TableCell>
                </TableRow>
                <TableRow hover key={2}>
                  <TableCell align="left">
                    シャワースクリーン（JA）10個入　単価
                    {materialConst.unitPrice.SsJa.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldSsJa />
                  </TableCell>
                </TableRow>
                <TableRow hover key={3}>
                  <TableCell align="left">
                    エルボー　アイボリー　10個入　単価
                    {materialConst.unitPrice.ElbowI.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldElbowI />
                  </TableCell>
                </TableRow>
                <TableRow hover key={4}>
                  <TableCell align="left">
                    エルボー　グレー　10個入　単価
                    {materialConst.unitPrice.ElbowH.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldElbowH />
                  </TableCell>
                </TableRow>
                <TableRow hover key={5}>
                  <TableCell align="left">
                    洗濯水栓ニップル　10個入り　単価
                    {materialConst.unitPrice.Nipple.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldNipple />
                  </TableCell>
                </TableRow>
                <TableRow hover key={6}>
                  <TableCell align="left">
                    シャワー　ヘッド・ホース　単価
                    {materialConst.unitPrice.Shower.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldShower />
                  </TableCell>
                </TableRow>
                <TableRow hover key={7}>
                  <TableCell align="left">
                    防熱板　単価
                    {materialConst.unitPrice.HeatShield.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldHeatShield />
                  </TableCell>
                </TableRow>
                <TableRow hover key={8}>
                  <TableCell align="left">
                    蒸発防止剤　ニオイしま栓　1本　単価
                    {materialConst.unitPrice.AntiEva.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldAntiEva />
                  </TableCell>
                </TableRow>
                <TableRow hover key={9}>
                  <TableCell align="left">
                    パイプファン　プラグ無し　単価
                    {materialConst.unitPrice.PipeFanUnPlug.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldPipeFanUnPlug />
                  </TableCell>
                </TableRow>
                <TableRow hover key={10}>
                  <TableCell align="left">
                    パイプファン　プラグ有り　単価
                    {materialConst.unitPrice.PipeFanPlug.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldPipeFanPlug />
                  </TableCell>
                </TableRow>
                <TableRow hover key={11}>
                  <TableCell align="left">
                    換気扇　1室用　単価
                    {materialConst.unitPrice.Fan.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldFan />
                  </TableCell>
                </TableRow>
                <TableRow hover key={12}>
                  <TableCell align="left">
                    三角パッキン　パッキン＋ワッシャー　200組　単価
                    {materialConst.unitPrice.Triangle.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldTriangle />
                  </TableCell>
                </TableRow>
                <TableRow hover key={13}>
                  <TableCell align="left">
                    ケレップ　コマパッキン　200組　単価
                    {materialConst.unitPrice.Top.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldTop />
                  </TableCell>
                </TableRow>
                <TableRow hover key={14}>
                  <TableCell align="left">
                    フィルター大　単価
                    {materialConst.unitPrice.FilterL.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldFilterL />
                  </TableCell>
                </TableRow>
                <TableRow hover key={15}>
                  <TableCell align="left">
                    フィルター小　単価
                    {materialConst.unitPrice.FilterS.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldFilterS />
                  </TableCell>
                </TableRow>
                <TableRow hover key={16}>
                  <TableCell align="left">
                    新フレグランスワックス　単価
                    {materialConst.unitPrice.Wax.toLocaleString()}円
                  </TableCell>
                  <TableCell align="left">
                    <TextFieldWax />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                小計　{subT.toLocaleString()}　円
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                消費税　
                {Math.floor(subT * materialConst.taxRate).toLocaleString()}　円
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                合計　
                {Math.floor(
                  subT * (1 + materialConst.taxRate)
                ).toLocaleString()}
                　円
              </Paper>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={
              params.action === "view" ||
              addState === materialConst.state.KonyuIrai
                ? true
                : false
            }
          >
            作成
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClose}
            color="secondary"
          >
            戻る
          </Button>
        </form>
      </ResponsiveDrawer>
    </>
  );
};

export default MaterialsEdit;
