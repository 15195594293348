import React from 'react';
import '../css/Views.css';

const Nomatch: React.FC = () => {
  return (
    <div>
      <body className="Views-body">URLが存在しません</body>
    </div>
  );
}

export default Nomatch;
