import React from 'react';
import '../css/Views.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const About: React.FC = () => {
  return (
    <div>
      <Header />
      <body className="Views-body">RestoreFlow （Public Bata）</body>
      <Footer />
    </div>
  );

}

export default About;