import React from "react";
import "../css/Views.css";
import { Link } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Copyright from "./Copyright";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: "#e0e0e0",
      paddingTop: 20,
      minHeight: "10vh",
    },
    copyright: {
      paddingTop: 10,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    a: {
      [theme.breakpoints.up("sm")]: {
        marginRight: 20,
      },
      fontSize: 13,
    },
    ul: {
      listStyle: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
      },
    },
    li: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  })
);

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <ul className={classes.ul}>
        <li className={classes.li}>
          <Link href="/login" color="inherit" className={classes.a}>
            HOME
          </Link>
        </li>
        {/*
          <li className={classes.li}>
            <Link href="/about" color="inherit" className={classes.a}>
              ABOUT
            </Link>
          </li>
        */}
        <li className={classes.li}>
          <Link href="/privacyPolicy/" color="inherit" className={classes.a}>
            プライバシーポリシー
          </Link>
        </li>
        <li className={classes.li}>
          <Link
            href="https://docs.google.com/forms/d/e/1FAIpQLSd19tC-22r9E-JL-9v4FU71B3bUy1s30ISyswOai82LcxxQgA/viewform?usp=sf_link"
            target="_brank"
            color="inherit"
            className={classes.a}
          >
            お問合せ・削除依頼
          </Link>
        </li>
        {/*
          <li className={classes.li}>
            <Link href="/contact" color="inherit" className={classes.a}>
              CONTACT US
            </Link>
          </li>
        */}
      </ul>
      <div className={classes.copyright}>
        <Copyright />
      </div>
    </div>
  );
};

export default Footer;
