import React from 'react';
import { zen2Han } from '../../../../common/common';
import Grid from '../../../components/Grid';
import ListColumn from '../../../components/ListColumn';
import MyText from '../../../components/MyText';

export interface objPdf {
  doc_id: string;
  completion_date: string;
  area: string;
  name: string;
  room: string;
  plan: string;
  roomCQ: string;
  roomCF: string;
  airconCQ: string;
  airconCF: string;
  clothCQ: string;
  clothCF: string;
  waxPCQ: string;
  waxPCF: string;
  ledQ: string;
  ledF: string;
  amidoQ: string;
  amidoF: string;
  otherBuhinQ: string;
  otherBuhinF: string;
  otherQ: string;
  otherF: string;
  subTotal: string;
}

const InvoicesPdfListItem: React.FC<objPdf> = ({
  doc_id,
  completion_date,
  area,
  name,
  room,
  plan,
  roomCQ,
  roomCF,
  airconCQ,
  airconCF,
  clothCQ,
  clothCF,
  waxPCQ,
  waxPCF,
  ledQ,
  ledF,
  amidoQ,
  amidoF,
  otherBuhinQ,
  otherBuhinF,
  otherQ,
  otherF,
  subTotal,
}) => {
  return (
    <>
    <Grid borderLeft borderRight>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{completion_date}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{area}</MyText></ListColumn>
      <ListColumn widthPer="18%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{zen2Han(name)}</MyText></ListColumn>
      <ListColumn widthPer="4%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{room}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{plan}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? ' ' : 'ルームC'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{roomCQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{roomCF}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : 'ワックスはくり'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{waxPCQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{waxPCF}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : 'その他部品交換'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{otherBuhinQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{otherBuhinF}</MyText></ListColumn>
      <ListColumn widthPer="6.5%"><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'></MyText></ListColumn>
    </Grid>
    <Grid borderLeft borderRight>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'> </MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="18%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="4%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : 'エアコンC'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{airconCQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{airconCF}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : 'LED・照明'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{ledQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{ledF}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : 'その他'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{otherQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{otherF}</MyText></ListColumn>
      <ListColumn widthPer="6.5%"><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'></MyText></ListColumn>
    </Grid>
    <Grid borderBottom borderLeft borderRight>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'> </MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="18%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="4%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : 'クロスC'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{clothCQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{clothCF}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'>{!completion_date ? '' : '網戸'}</MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{amidoQ}</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{amidoF}</MyText></ListColumn>
      <ListColumn widthPer="14%" borderRight><MyText fontSize='8' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="3%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="6.5%"><MyText fontSize='8' textAlign='right' marginLeft='1' marginRight='1'>{subTotal}</MyText></ListColumn>
    </Grid>
    </>
  );
};

export default InvoicesPdfListItem;