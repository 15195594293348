export type InfomationsDataObj = {
    doc_id: string;
    team: string;
    from_user_name: string;
    from_text: string;
    info: string;
    menu: string;
    text: string;
    url: string;
    manager_user_id: string;
    to_user_id: string;
    verification_flg: string;
    create_time: string;
  };

export type InvoicelinksDataObj = {
    doc_id: string;
    team: string;
    user_id: string;
    user_name: string;
    yyyymm: string;
    confirm_date: string;
  };

export type InvoicesDataObj = {
    doc_id: string;
    team: string;
    user_id: string;
    user_name: string;
    area: string;
    name: string;
    room: string;
    plan: string;
    aircon: string;
    cloth: string;
    waxP: string;
    led: string;
    amido: string;
    otherBuhin: string;
    other: string;
    completion_date: string;
  };

export type MaterialsDataObj = {
    doc_id: string;
    team: string;
    user_id: string;
    user_name: string;
    state: string;
    createTime: string;
    offerTime: string;
    ss_jh: string;
    ss_ja: string;
    elbow_i: string;
    elbow_h: string;
    nipple: string;
    shower: string;
    heat_shield: string;
    anti_eva: string;
    pipe_fan_un_plug: string;
    pipe_fan_plug: string;
    fan: string;
    triangle: string;
    top: string;
    filterL: string;
    filterS: string;
    total: string;
  };

export type ReportsDataObj = {
    doc_id: string;
    user_name: string;
    state: string;
    name: string;
    room: string;
    plan: string;
    aircon: string;
    interior: string;
    unConstructed: string;
    repo1: string;
    repo2: string;
    repo3: string;
    comment: string;
    comment2: string;
    completion_date: string;
  };

export type WorksDataObj = {
    doc_id: string;
    team: string;
    user_id: string;
    user_name: string;
    area: string;
    name: string;
    room: string;
    state: string;
    fixed_date: string;
    scheduled_date: string;
    completion_date: string;
    reportFlg: string;
  };

export const InfomationsHeaders = [
    { label: "doc_id", key: "doc_id" },
    { label: "team", key: "team" },
    { label: "from_user_name", key: "from_user_name" },
    { label: "from_text", key: "from_text" },
    { label: "info", key: "info" },
    { label: "menu", key: "menu" },
    { label: "text", key: "text" },
    { label: "url", key: "url" },
    { label: "manager_user_id", key: "manager_user_id" },
    { label: "to_user_id", key: "to_user_id" },
    { label: "verification_flg", key: "verification_flg" },
    { label: "create_time", key: "create_time" },
  ];

export const InvoicelinksHeaders = [
    { label: "doc_id", key: "doc_id" },
    { label: "team", key: "team" },
    { label: "user_id", key: "user_id" },
    { label: "user_name", key: "user_name" },
    { label: "yyyymm", key: "yyyymm" },
    { label: "confirm_date", key: "confirm_date" },
  ];

export const InvoicesHeaders = [
    { label: "doc_id", key: "doc_id" },
    { label: "team", key: "team" },
    { label: "user_id", key: "user_id" },
    { label: "user_name", key: "user_name" },
    { label: "area", key: "area" },
    { label: "name", key: "name" },
    { label: "room", key: "room" },
    { label: "plan", key: "plan" },
    { label: "aircon", key: "aircon" },
    { label: "cloth", key: "cloth" },
    { label: "waxP", key: "waxP" },
    { label: "led", key: "led" },
    { label: "amido", key: "amido" },
    { label: "otherBuhin", key: "otherBuhin" },
    { label: "other", key: "other" },
    { label: "completion_date", key: "completion_date" },
  ];

export const MaterialsHeaders = [
    { label: "doc_id", key: "doc_id" },
    { label: "team", key: "team" },
    { label: "user_id", key: "user_id" },
    { label: "user_name", key: "user_name" },
    { label: "state", key: "state" },
    { label: "createTime", key: "createTime" },
    { label: "offerTime", key: "offerTime" },
    { label: "ss_jh", key: "ss_jh" },
    { label: "ss_ja", key: "ss_ja" },
    { label: "elbow_i", key: "elbow_i" },
    { label: "elbow_h", key: "elbow_h" },
    { label: "nipple", key: "nipple" },
    { label: "shower", key: "shower" },
    { label: "heat_shield", key: "heat_shield" },
    { label: "anti_eva", key: "anti_eva" },
    { label: "pipe_fan_un_plug", key: "pipe_fan_un_plug" },
    { label: "pipe_fan_plug", key: "pipe_fan_plug" },
    { label: "fan", key: "fan" },
    { label: "triangle", key: "triangle" },
    { label: "top", key: "top" },
    { label: "filterL", key: "filterL" },
    { label: "filterS", key: "filterS" },
    { label: "total", key: "total" },
];

export const ReportsHeaders = [
    { label: "doc_id", key: "doc_id" },
    { label: "user_name", key: "user_name" },
    { label: "state", key: "state" },
    { label: "name", key: "name" },
    { label: "room", key: "room" },
    { label: "plan", key: "plan" },
    { label: "aircon", key: "aircon" },
    { label: "interior", key: "interior" },
    { label: "unConstructed", key: "unConstructed" },
    { label: "repo1", key: "repo1" },
    { label: "repo2", key: "repo2" },
    { label: "repo3", key: "repo3" },
    { label: "comment", key: "comment" },
    { label: "comment2", key: "comment2" },
    { label: "completion_date", key: "completion_date" },
  ];

export const WorksHeaders = [
    { label: "doc_id", key: "doc_id" },
    { label: "team", key: "team" },
    { label: "user_id", key: "user_id" },
    { label: "user_name", key: "user_name" },
    { label: "area", key: "area" },
    { label: "name", key: "name" },
    { label: "room", key: "room" },
    { label: "state", key: "state" },
    { label: "fixed_date", key: "fixed_date" },
    { label: "scheduled_date", key: "scheduled_date" },
    { label: "completion_date", key: "completion_date" },
    { label: "reportFlg", key: "reportFlg" },
  ];
