import { materialConst } from "../../../../config/Const";

export const amountOfMoney = (quantity: string, price: number): string => {
    if (quantity==="0" || quantity==="" || !quantity) return "";
    return (Number(quantity) * price).toLocaleString();
}

export const subTotal = (
	ssJh: number,
	ssJa: number,
	elbowI: number,
	elbowH: number,
	nipple: number,
	shower: number,
	heatShield: number,
	antiEva: number,
	pipeFanUnPlug: number,
	pipeFanPlug: number,
	fan: number,
	triangle: number,
	top: number,
	filterL: number,
	filterS: number,
	wax: number,
): number => {
	return ssJh * materialConst.unitPrice.SsJh + 
	ssJa * materialConst.unitPrice.SsJa +
	elbowI * materialConst.unitPrice.ElbowI +
	elbowH * materialConst.unitPrice.ElbowH +
	nipple * materialConst.unitPrice.Nipple +
	shower * materialConst.unitPrice.Shower +
	heatShield * materialConst.unitPrice.HeatShield +
	antiEva * materialConst.unitPrice.AntiEva +
	pipeFanUnPlug * materialConst.unitPrice.PipeFanUnPlug +
	pipeFanPlug * materialConst.unitPrice.PipeFanPlug +
	fan * materialConst.unitPrice.Fan +
	triangle * materialConst.unitPrice.Triangle +
	top * materialConst.unitPrice.Top +
	filterL * materialConst.unitPrice.FilterL +
	filterS * materialConst.unitPrice.FilterS +
	wax * materialConst.unitPrice.Wax;
}