import React, { useContext, useEffect, useState, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { db } from "../../../config/firebase";
import { CSVLink } from "react-csv";
import { getStartDay, getEndDay } from "../../../common/date";
import ResponsiveDrawer from "../../templates/ResponsiveDrawer";
import { AuthContext } from "../../../store/AuthContext";
import { ConfigContext } from "../../../store/ConfigContext";
import {
  InfomationsDataObj,
  InfomationsHeaders,
  InvoicelinksDataObj,
  InvoicelinksHeaders,
  InvoicesDataObj,
  InvoicesHeaders,
  MaterialsDataObj,
  MaterialsHeaders,
  ReportsDataObj,
  ReportsHeaders,
  WorksDataObj,
  WorksHeaders,
} from "./components/controlsDataObj";
import {
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
} from "@material-ui/core";
import CsvIcon from "@material-ui/icons/Description";
import DelIcon from "@material-ui/icons/DeleteForever";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100ch",
      marginBottom: 20,
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: "30ch",
  },
  formControlPeriod: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    width: "30ch",
  },
  textfield: {
    marginLeft: theme.spacing(4),
    width: "30ch",
  },
  divCnt: {
    marginLeft: theme.spacing(6),
    width: "60ch",
  },
  icon: {
    color: "#3F51B5",
  },
  iconcsv: {
    color: "magenta",
  },
  icondel: {
    color: "red",
  },
  link: {
    marginLeft: theme.spacing(2),
  },
}));

type Inputs = {
  selectMonth: string;
};

const Controls: React.FC = (props: any) => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const configValue = useContext(ConfigContext);
  const InfomationsCsvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const InvoicelinksCsvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const InvoicesCsvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const MaterialsCsvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const ReportsCsvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const WorksCsvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [loading, setLoading] = useState(false);
  const [selectPeriod, setSelectPeriod] = useState("年月度");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [infomationsCnt, setInfomationsCnt] = useState("");
  const [infomationsData, setInfomationsData] = useState<InfomationsDataObj[]>(
    []
  );
  const [invoicelinksCnt, setInvoicelinksCnt] = useState("");
  const [invoicelinksData, setInvoicelinksData] = useState<
    InvoicelinksDataObj[]
  >([]);
  const [invoicesCnt, setInvoicesCnt] = useState("");
  const [invoicesData, setInvoicesData] = useState<InvoicesDataObj[]>([]);
  const [materialsCnt, setMaterialsCnt] = useState("");
  const [materialsData, setMaterialsData] = useState<MaterialsDataObj[]>([]);
  const [reportsCnt, setReportsCnt] = useState("");
  const [reportsData, setReportsData] = useState<ReportsDataObj[]>([]);
  const [worksCnt, setWorksCnt] = useState("");
  const [worksData, setWorksData] = useState<WorksDataObj[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      selectMonth: selectedMonth,
    },
  });

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authValue.currentUser]);

  useEffect(() => {
    setLoading(true);
    const getInfomationsCnt = async (yyyymm: string) => {
      try {
        let infomationsRef;
        if (yyyymm === "") {
          infomationsRef = db
            .collection("infomations")
            .where("team", "==", authValue.users.team);
        } else {
          const st = new Date(getStartDay(yyyymm));
          const end = new Date(getEndDay(yyyymm));
          infomationsRef = db
            .collection("infomations")
            .where("team", "==", authValue.users.team)
            .orderBy("create_time")
            .startAt(st)
            .endAt(end);
        }
        const infomationsDoc = await infomationsRef.get();
        if (infomationsDoc.empty) {
          setInfomationsCnt("0");
        }
        setInfomationsCnt(infomationsDoc.docs.length.toString());
      } catch (e) {
        console.log("getInfomationsCntに失敗しました:" + e);
        setInfomationsCnt("0");
      }
    };

    const getInvoicelinksCnt = async (yyyymm: string) => {
      try {
        let invoicelinksRef;
        if (yyyymm === "") {
          invoicelinksRef = db
            .collection("invoicelinks")
            .where("team", "==", authValue.users.team);
        } else {
          invoicelinksRef = db
            .collection("invoicelinks")
            .where("team", "==", authValue.users.team)
            .where("yyyymm", "==", yyyymm);
        }
        const invoicelinksDoc = await invoicelinksRef.get();
        if (invoicelinksDoc.empty) {
          setInvoicelinksCnt("0");
        }
        setInvoicelinksCnt(invoicelinksDoc.docs.length.toString());
      } catch (e) {
        console.log("getInvoicelinksCntに失敗しました:" + e);
        setInvoicelinksCnt("0");
      }
    };

    const getInvoicesCnt = async (yyyymm: string) => {
      try {
        let invoicesRef;
        if (yyyymm === "") {
          invoicesRef = db
            .collection("invoices")
            .where("team", "==", authValue.users.team);
        } else {
          invoicesRef = db
            .collection("invoices")
            .where("team", "==", authValue.users.team)
            .where("completion_date", ">=", getStartDay(yyyymm))
            .where("completion_date", "<=", getEndDay(yyyymm));
        }
        const invoicesDoc = await invoicesRef.get();
        if (invoicesDoc.empty) {
          setInvoicesCnt("0");
        }
        setInvoicesCnt(invoicesDoc.docs.length.toString());
      } catch (e) {
        console.log("getInvoicesCntに失敗しました:" + e);
        setInvoicesCnt("0");
      }
    };

    const getMaterialsCnt = async (yyyymm: string) => {
      try {
        let materialsRef;
        if (yyyymm === "") {
          materialsRef = db
            .collection("materials")
            .where("team", "==", authValue.users.team);
        } else {
          const st = new Date(getStartDay(yyyymm));
          const end = new Date(getEndDay(yyyymm));
          materialsRef = db
            .collection("materials")
            .where("team", "==", authValue.users.team)
            .orderBy("createTime")
            .startAt(st)
            .endAt(end);
        }
        const materialsDoc = await materialsRef.get();
        if (materialsDoc.empty) {
          setMaterialsCnt("0");
        }
        setMaterialsCnt(materialsDoc.docs.length.toString());
      } catch (e) {
        console.log("getMaterialsCntに失敗しました:" + e);
        setMaterialsCnt("0");
      }
    };

    const getReportsCnt = async (yyyymm: string) => {
      try {
        const usersRef = db
          .collection("users")
          .where("team", "==", authValue.users.team);
        const usersDoc = await usersRef.get();
        if (usersDoc.empty) {
          setReportsCnt("0");
          return;
        }
        var names: Array<string> = [];
        usersDoc.forEach((doc) => {
          names.push(doc.data().name);
        });

        let reportsRef;
        if (yyyymm === "") {
          reportsRef = db.collection("reports").where("user_name", "in", names);
        } else {
          reportsRef = db
            .collection("reports")
            .where("user_name", "in", names)
            .where("completion_date", ">=", getStartDay(yyyymm))
            .where("completion_date", "<=", getEndDay(yyyymm));
        }
        const reportsDoc = await reportsRef.get();
        if (reportsDoc.empty) {
          setReportsCnt("0");
        }
        setReportsCnt(reportsDoc.docs.length.toString());
      } catch (e) {
        console.log("getReportsCntに失敗しました:" + e);
        setReportsCnt("0");
      }
    };

    const getWorksCnt = async (yyyymm: string) => {
      try {
        let worksRef;
        if (yyyymm === "") {
          worksRef = db
            .collection("works")
            .where("team", "==", authValue.users.team);
        } else {
          worksRef = db
            .collection("works")
            .where("team", "==", authValue.users.team)
            .where("completion_date", ">=", getStartDay(yyyymm))
            .where("completion_date", "<=", getEndDay(yyyymm));
        }
        const worksDoc = await worksRef.get();
        if (worksDoc.empty) {
          setWorksCnt("0");
        }
        setWorksCnt(worksDoc.docs.length.toString());
      } catch (e) {
        console.log("getWorksCntに失敗しました:" + e);
        setWorksCnt("0");
      }
    };

    if (selectPeriod === "全期間") {
      getInfomationsCnt("");
      getInvoicelinksCnt("");
      getInvoicesCnt("");
      getMaterialsCnt("");
      getReportsCnt("");
      getWorksCnt("");
    } else if (selectedMonth !== "") {
      getInfomationsCnt(selectedMonth.replace(/-/g, ""));
      getInvoicelinksCnt(selectedMonth.replace(/-/g, ""));
      getInvoicesCnt(selectedMonth.replace(/-/g, ""));
      getMaterialsCnt(selectedMonth.replace(/-/g, ""));
      getReportsCnt(selectedMonth.replace(/-/g, ""));
      getWorksCnt(selectedMonth.replace(/-/g, ""));
    } else {
      setInfomationsCnt("");
      setInvoicelinksCnt("");
      setInvoicesCnt("");
      setMaterialsCnt("");
      setReportsCnt("");
      setWorksCnt("");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPeriod, selectedMonth]);

  useEffect(() => {
    if (infomationsData.length > 0) {
      InfomationsCsvLinkRef.current?.link.click();
      // 初期化
      setInfomationsData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infomationsData]);

  useEffect(() => {
    if (invoicelinksData.length > 0) {
      InvoicelinksCsvLinkRef.current?.link.click();
      // 初期化
      setInvoicelinksData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicelinksData]);

  useEffect(() => {
    if (invoicesData.length > 0) {
      InvoicesCsvLinkRef.current?.link.click();
      // 初期化
      setInvoicesData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesData]);

  useEffect(() => {
    if (materialsData.length > 0) {
      MaterialsCsvLinkRef.current?.link.click();
      // 初期化
      setMaterialsData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialsData]);

  useEffect(() => {
    if (reportsData.length > 0) {
      ReportsCsvLinkRef.current?.link.click();
      // 初期化
      setReportsData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsData]);

  useEffect(() => {
    if (worksData.length > 0) {
      WorksCsvLinkRef.current?.link.click();
      // 初期化
      setWorksData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksData]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.selectMonth.trim() === "") {
      alert("年月を指定してください");
      return;
    }
    reset();
    //buttonText === "追加" ? handleAdd() : handleEdit();
  };

  const handleChangeSelectPeriod = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectPeriod((event.target as HTMLInputElement).value);
  };

  async function deleteInfomations(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<number> {
    let cnt = 0;
    if (window.confirm("削除しますか？")) {
      try {
        let infomationsRef;
        if (selectPeriod === "全期間") {
          infomationsRef = db
            .collection("infomations")
            .where("team", "==", team);
        } else {
          const yyyymm = selectedMonth.replace(/-/g, "");
          const st = new Date(getStartDay(yyyymm));
          const end = new Date(getEndDay(yyyymm));
          infomationsRef = db
            .collection("infomations")
            .where("team", "==", team)
            .orderBy("create_time")
            .startAt(st)
            .endAt(end);
        }
        const infomationsDoc = await infomationsRef.get();
        if (infomationsDoc.empty) {
          console.log("infomations削除に失敗しました");
          return -1;
        }
        infomationsDoc.forEach(async (doc) => {
          //console.log(`deleteInfomations: ${doc.id}`);
          await db.collection("infomations").doc(doc.id).delete();
          cnt++;
        });
        return cnt;
      } catch (e) {
        console.log("infomations削除に失敗しました:" + e);
        return -1;
      }
    }
    return cnt;
  }

  async function deleteInvoicelinks(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<number> {
    let cnt = 0;
    if (window.confirm("削除しますか？")) {
      try {
        let invoicelinksRef;
        if (selectPeriod === "全期間") {
          invoicelinksRef = db
            .collection("invoicelinks")
            .where("team", "==", team);
        } else {
          const yyyymm = selectedMonth.replace(/-/g, "");
          invoicelinksRef = db
            .collection("invoicelinks")
            .where("team", "==", team)
            .where("yyyymm", "==", yyyymm);
        }
        const invoicelinksDoc = await invoicelinksRef.get();
        if (invoicelinksDoc.empty) {
          console.log("invoicelinks削除に失敗しました");
          return -1;
        }
        invoicelinksDoc.forEach(async (doc) => {
          //console.log(`deleteInvoicelinks: ${doc.id}`);
          await db.collection("invoicelinks").doc(doc.id).delete();
          cnt++;
        });
        return cnt;
      } catch (e) {
        console.log("invoicelinks削除に失敗しました:" + e);
        return -1;
      }
    }
    return cnt;
  }

  async function deleteInvoices(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<number> {
    let cnt = 0;
    if (window.confirm("削除しますか？")) {
      try {
        let invoicesRef;
        if (selectPeriod === "全期間") {
          invoicesRef = db.collection("invoices").where("team", "==", team);
        } else {
          const yyyymm = selectedMonth.replace(/-/g, "");
          invoicesRef = db
            .collection("invoices")
            .where("team", "==", team)
            .where("completion_date", ">=", getStartDay(yyyymm))
            .where("completion_date", "<=", getEndDay(yyyymm));
        }
        const invoicesDoc = await invoicesRef.get();
        if (invoicesDoc.empty) {
          console.log("invoices削除に失敗しました");
          return -1;
        }
        invoicesDoc.forEach(async (doc) => {
          //console.log(`deleteInvoices: ${doc.id}`);
          await db.collection("invoices").doc(doc.id).delete();
          cnt++;
        });
        return cnt;
      } catch (e) {
        console.log("invoices削除に失敗しました:" + e);
        return -1;
      }
    }
    return cnt;
  }

  async function deleteMaterials(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<number> {
    let cnt = 0;
    if (window.confirm("削除しますか？")) {
      try {
        let materialsRef;
        if (selectPeriod === "全期間") {
          materialsRef = db.collection("materials").where("team", "==", team);
        } else {
          const yyyymm = selectedMonth.replace(/-/g, "");
          const st = new Date(getStartDay(yyyymm));
          const end = new Date(getEndDay(yyyymm));
          materialsRef = db
            .collection("materials")
            .where("team", "==", team)
            .orderBy("createTime")
            .startAt(st)
            .endAt(end);
        }
        const materialsDoc = await materialsRef.get();
        if (materialsDoc.empty) {
          console.log("materials削除に失敗しました");
          return -1;
        }
        materialsDoc.forEach(async (doc) => {
          //console.log(`deleteMaterials: ${doc.id}`);
          await db.collection("materials").doc(doc.id).delete();
          cnt++;
        });
        return cnt;
      } catch (e) {
        console.log("materials削除に失敗しました:" + e);
        return -1;
      }
    }
    return cnt;
  }

  async function deleteReports(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<number> {
    let cnt = 0;
    if (window.confirm("削除しますか？")) {
      try {
        const usersRef = db
          .collection("users")
          .where("team", "==", authValue.users.team);
        const usersDoc = await usersRef.get();
        if (usersDoc.empty) {
          console.log("reports削除に失敗しました");
          return -1;
        }
        var names: Array<string> = [];
        usersDoc.forEach((doc) => {
          names.push(doc.data().name);
        });

        let reportsRef;
        if (selectPeriod === "全期間") {
          reportsRef = db.collection("reports").where("user_name", "in", names);
        } else {
          const yyyymm = selectedMonth.replace(/-/g, "");
          reportsRef = db
            .collection("reports")
            .where("user_name", "in", names)
            .where("completion_date", ">=", getStartDay(yyyymm))
            .where("completion_date", "<=", getEndDay(yyyymm));
        }
        const reportsDoc = await reportsRef.get();
        if (reportsDoc.empty) {
          console.log("reports削除に失敗しました");
          return -1;
        }
        reportsDoc.forEach(async (doc) => {
          //console.log(`deleteReports: ${doc.id}`);
          await db.collection("reports").doc(doc.id).delete();
          cnt++;
        });
        return cnt;
      } catch (e) {
        console.log("reports削除に失敗しました:" + e);
        return -1;
      }
    }
    return cnt;
  }

  async function deleteWorks(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<number> {
    let cnt = 0;
    if (window.confirm("削除しますか？")) {
      try {
        let worksRef;
        if (selectPeriod === "全期間") {
          worksRef = db.collection("works").where("team", "==", team);
        } else {
          const yyyymm = selectedMonth.replace(/-/g, "");
          worksRef = db
            .collection("works")
            .where("team", "==", team)
            .where("completion_date", ">=", getStartDay(yyyymm))
            .where("completion_date", "<=", getEndDay(yyyymm));
        }
        const worksDoc = await worksRef.get();
        if (worksDoc.empty) {
          console.log("works削除に失敗しました");
          return -1;
        }
        worksDoc.forEach(async (doc) => {
          //console.log(`deleteWorks: ${doc.id}`);
          await db.collection("works").doc(doc.id).delete();
          cnt++;
        });
        return cnt;
      } catch (e) {
        console.log("works削除に失敗しました:" + e);
        return -1;
      }
    }
    return cnt;
  }

  const InfomationsCsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <div>infomations 0件</div>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "table_infomations_all.csv"
          : `table_infomations_${selectedMonth}.csv`;
      return (
        <>
          {`infomations ${props.cnt}件`}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getInfomationsData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              //console.log("getData Done!");
              //console.log(d);
              setInfomationsData(d);
              InfomationsCsvLinkRef.current?.link.click();
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <IconButton
            className={classes.icondel}
            size="medium"
            onClick={async function () {
              const res = await deleteInfomations(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              if (res > 0) {
                setInfomationsCnt(String(Number(infomationsCnt) - res));
              }
            }}
          >
            <DelIcon />
            <Typography>削除</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={infomationsData}
              headers={InfomationsHeaders}
              filename={fileName}
              ref={InfomationsCsvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  const InvoicelinksCsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <div>invoicelinks 0件</div>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "table_invoicelinks_all.csv"
          : `table_invoicelinks_${selectedMonth}.csv`;
      return (
        <>
          {`invoicelinks ${props.cnt}件`}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getInvoicelinksData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              //console.log("getData Done!");
              //console.log(d);
              setInvoicelinksData(d);
              InvoicelinksCsvLinkRef.current?.link.click();
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <IconButton
            className={classes.icondel}
            size="medium"
            onClick={async function () {
              const res = await deleteInvoicelinks(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              if (res > 0) {
                setInvoicelinksCnt(String(Number(invoicelinksCnt) - res));
              }
            }}
          >
            <DelIcon />
            <Typography>削除</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={invoicelinksData}
              headers={InvoicelinksHeaders}
              filename={fileName}
              ref={InvoicelinksCsvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  const InvoicesCsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <div>invoices 0件</div>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "table_invoices_all.csv"
          : `table_invoices_${selectedMonth}.csv`;
      return (
        <>
          {`invoices ${props.cnt}件`}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              var d = await getInvoicesData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              //console.log("getData Done!");
              //console.log(d);
              setInvoicesData(d);
              InvoicesCsvLinkRef.current?.link.click();
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <IconButton
            className={classes.icondel}
            size="medium"
            onClick={async function () {
              const res = await deleteInvoices(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              if (res > 0) {
                setInvoicesCnt(String(Number(invoicesCnt) - res));
              }
            }}
          >
            <DelIcon />
            <Typography>削除</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={invoicesData}
              headers={InvoicesHeaders}
              filename={fileName}
              ref={InvoicesCsvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  const MaterialsCsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <div>materials 0件</div>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "table_materials_all.csv"
          : `table_materials_${selectedMonth}.csv`;
      return (
        <>
          {`materials ${props.cnt}件`}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getMaterialsData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              //console.log("getData Done!");
              //console.log(d);
              setMaterialsData(d);
              MaterialsCsvLinkRef.current?.link.click();
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <IconButton
            className={classes.icondel}
            size="medium"
            onClick={async function () {
              const res = await deleteMaterials(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              if (res > 0) {
                setMaterialsCnt(String(Number(materialsCnt) - res));
              }
            }}
          >
            <DelIcon />
            <Typography>削除</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={materialsData}
              headers={MaterialsHeaders}
              filename={fileName}
              ref={MaterialsCsvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  const ReportsCsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <div>reports 0件</div>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "table_reports_all.csv"
          : `table_reports_${selectedMonth}.csv`;
      return (
        <>
          {`reports ${props.cnt}件`}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getReportsData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              //console.log("getData Done!");
              //console.log(d);
              setReportsData(d);
              ReportsCsvLinkRef.current?.link.click();
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <IconButton
            className={classes.icondel}
            size="medium"
            onClick={async function () {
              const res = await deleteReports(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              if (res > 0) {
                setReportsCnt(String(Number(reportsCnt) - res));
              }
            }}
          >
            <DelIcon />
            <Typography>削除</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={reportsData}
              headers={ReportsHeaders}
              filename={fileName}
              ref={ReportsCsvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  const WorksCsvButton = (props: any) => {
    if (props.cnt === "") {
      return <></>;
    } else if (props.cnt === "0") {
      return <div>works 0件</div>;
    } else {
      const fileName =
        selectPeriod === "全期間"
          ? "table_works_all.csv"
          : `table_works_${selectedMonth}.csv`;
      return (
        <>
          {`works ${props.cnt}件`}
          <IconButton
            className={classes.iconcsv}
            size="medium"
            onClick={async function () {
              //console.log("onClick!");
              var d = await getWorksData(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              //console.log("getData Done!");
              //console.log(d);
              setWorksData(d);
              WorksCsvLinkRef.current?.link.click();
            }}
          >
            <CsvIcon />
            <Typography>CSV</Typography>
          </IconButton>
          <IconButton
            className={classes.icondel}
            size="medium"
            onClick={async function () {
              const res = await deleteWorks(
                authValue.users.team,
                props.selectPeriod,
                props.selectedMonth
              );
              if (res > 0) {
                setWorksCnt(String(Number(worksCnt) - res));
              }
            }}
          >
            <DelIcon />
            <Typography>削除</Typography>
          </IconButton>
          <div>
            <CSVLink
              data={worksData}
              headers={WorksHeaders}
              filename={fileName}
              ref={WorksCsvLinkRef}
            ></CSVLink>
          </div>
        </>
      );
    }
  };

  async function getInfomationsData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<InfomationsDataObj[]> {
    const d: InfomationsDataObj[] = [];
    try {
      let infomationsRef;
      if (selectPeriod === "全期間") {
        infomationsRef = db
          .collection("infomations")
          .where("team", "==", team)
          .orderBy("create_time", "desc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        const st = new Date(getStartDay(yyyymm));
        const end = new Date(getEndDay(yyyymm));
        infomationsRef = db
          .collection("infomations")
          .where("team", "==", team)
          .orderBy("create_time", "desc")
          // note: desc なので st と end を逆にする
          .startAt(end)
          .endAt(st);
      }
      const infomationsDoc = await infomationsRef.get();
      if (infomationsDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      infomationsDoc.forEach((doc) => {
        //console.log("getInfomationsData: " + doc.id);
        //console.log("create_time: " + doc.data().toDate().toString());
        d.push({
          doc_id: doc.id,
          team: doc.data().team,
          from_user_name: doc.data().from_user_name,
          from_text: doc.data().from_text,
          info: doc.data().info,
          menu: doc.data().menu,
          text: doc.data().text,
          url: doc.data().url,
          manager_user_id: doc.data().manager_user_id,
          to_user_id: doc.data().to_user_id,
          verification_flg: doc.data().verification_flg,
          create_time: doc.data().create_time,
        });
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  async function getInvoicelinksData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<InvoicelinksDataObj[]> {
    const d: InvoicelinksDataObj[] = [];
    try {
      let invoicelinksRef;
      if (selectPeriod === "全期間") {
        invoicelinksRef = db
          .collection("invoicelinks")
          .where("team", "==", team)
          .orderBy("yyyymm", "desc")
          .orderBy("user_id", "asc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        invoicelinksRef = db
          .collection("invoicelinks")
          .where("team", "==", team)
          .where("yyyymm", "==", yyyymm)
          .orderBy("user_id", "asc");
      }
      const invoicelinksDoc = await invoicelinksRef.get();
      if (invoicelinksDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      invoicelinksDoc.forEach((doc) => {
        //console.log("getInvoicelinksData: " + doc.id);
        d.push({
          doc_id: doc.id,
          team: doc.data().team,
          user_id: doc.data().user_id,
          user_name: doc.data().user_name,
          yyyymm: doc.data().yyyymm,
          confirm_date: doc.data().confirm_date,
        });
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  async function getInvoicesData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<InvoicesDataObj[]> {
    const d: InvoicesDataObj[] = [];
    try {
      let invoicesRef;
      if (selectPeriod === "全期間") {
        invoicesRef = db
          .collection("invoices")
          .where("team", "==", team)
          .orderBy("completion_date", "desc")
          .orderBy("user_id", "asc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        invoicesRef = db
          .collection("invoices")
          .where("team", "==", team)
          .where("completion_date", ">=", getStartDay(yyyymm))
          .where("completion_date", "<=", getEndDay(yyyymm))
          .orderBy("completion_date", "desc")
          .orderBy("user_id", "asc");
      }
      const invoicesDoc = await invoicesRef.get();
      if (invoicesDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      invoicesDoc.forEach((doc) => {
        //console.log("getInvoicesData: " + doc.id);
        d.push({
          doc_id: doc.id,
          team: doc.data().team,
          user_id: doc.data().user_id,
          user_name: doc.data().user_name,
          area: doc.data().area,
          name: doc.data().name,
          room: doc.data().room,
          plan: doc.data().plan,
          aircon: doc.data().aircon,
          cloth: doc.data().cloth,
          waxP: doc.data().waxP,
          led: doc.data().led,
          amido: doc.data().amido,
          otherBuhin: doc.data().otherBuhin,
          other: doc.data().other,
          completion_date: doc.data().completion_date,
        });
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  async function getMaterialsData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<MaterialsDataObj[]> {
    const d: MaterialsDataObj[] = [];
    try {
      let materialsRef;
      if (selectPeriod === "全期間") {
        materialsRef = db
          .collection("materials")
          .where("team", "==", team)
          .orderBy("createTime", "desc")
          .orderBy("user_id", "asc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        const st = new Date(getStartDay(yyyymm));
        const end = new Date(getEndDay(yyyymm));
        //console.log(`st: ${st}`);
        //console.log(`end: ${end}`);
        materialsRef = db
          .collection("materials")
          .where("team", "==", team)
          .orderBy("createTime", "desc")
          .orderBy("user_id", "asc")
          // note: desc なので st と end を逆にする
          .startAt(end)
          .endAt(st);
      }
      const materialsDoc = await materialsRef.get();
      if (materialsDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      materialsDoc.forEach((doc) => {
        //console.log("getMaterialsData: " + doc.id);
        d.push({
          doc_id: doc.id,
          team: doc.data().team,
          user_id: doc.data().user_id,
          user_name: doc.data().user_name,
          state: doc.data().state,
          createTime: doc.data().createTime,
          offerTime: doc.data().offerTime,
          ss_jh: doc.data().ss_jh,
          ss_ja: doc.data().ss_ja,
          elbow_i: doc.data().elbow_i,
          elbow_h: doc.data().elbow_h,
          nipple: doc.data().nipple,
          shower: doc.data().shower,
          heat_shield: doc.data().heat_shield,
          anti_eva: doc.data().anti_eva,
          pipe_fan_un_plug: doc.data().pipe_fan_un_plug,
          pipe_fan_plug: doc.data().pipe_fan_plug,
          fan: doc.data().fan,
          triangle: doc.data().triangle,
          top: doc.data().top,
          filterL: doc.data().filterL,
          filterS: doc.data().filterS,
          total: doc.data().total,
        });
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  async function getReportsData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<ReportsDataObj[]> {
    const d: ReportsDataObj[] = [];
    try {
      const usersRef = db
        .collection("users")
        .where("team", "==", authValue.users.team);
      const usersDoc = await usersRef.get();
      if (usersDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      var names: Array<string> = [];
      usersDoc.forEach((doc) => {
        names.push(doc.data().name);
      });

      let reportsRef;
      if (selectPeriod === "全期間") {
        reportsRef = db
          .collection("reports")
          .where("user_name", "in", names)
          .orderBy("completion_date", "desc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        reportsRef = db
          .collection("reports")
          .where("user_name", "in", names)
          .where("completion_date", ">=", getStartDay(yyyymm))
          .where("completion_date", "<=", getEndDay(yyyymm))
          .orderBy("completion_date", "desc");
      }
      const reportsDoc = await reportsRef.get();
      if (reportsDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      reportsDoc.forEach((doc) => {
        //console.log("getData: " + doc.id);
        d.push({
          doc_id: doc.id,
          user_name: doc.data().user_name,
          state: doc.data().state,
          name: doc.data().name,
          room: doc.data().room,
          plan: doc.data().plan,
          aircon: doc.data().aircon,
          interior: doc.data().interior,
          unConstructed: doc.data().unConstructed,
          repo1: doc.data().repo1,
          repo2: doc.data().repo2,
          repo3: doc.data().repo3,
          comment: doc.data().comment,
          comment2: doc.data().comment2,
          completion_date: doc.data().completion_date,
        });
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  async function getWorksData(
    team: string,
    selectPeriod: string,
    selectedMonth: string
  ): Promise<WorksDataObj[]> {
    const d: WorksDataObj[] = [];
    try {
      let worksRef;
      if (selectPeriod === "全期間") {
        worksRef = db
          .collection("works")
          .where("team", "==", team)
          .orderBy("completion_date", "desc")
          .orderBy("user_id", "asc");
      } else {
        const yyyymm = selectedMonth.replace(/-/g, "");
        worksRef = db
          .collection("works")
          .where("team", "==", team)
          .where("completion_date", ">=", getStartDay(yyyymm))
          .where("completion_date", "<=", getEndDay(yyyymm))
          .orderBy("completion_date", "desc")
          .orderBy("user_id", "asc");
      }
      const worksDoc = await worksRef.get();
      if (worksDoc.empty) {
        console.log("エクスポートに失敗しました");
        return d;
      }
      worksDoc.forEach((doc) => {
        //console.log("getData: " + doc.id);
        d.push({
          doc_id: doc.id,
          team: doc.data().team,
          user_id: doc.data().user_id,
          user_name: doc.data().user_name,
          area: doc.data().area,
          name: doc.data().name,
          room: doc.data().room,
          state: doc.data().state,
          fixed_date: doc.data().fixed_date,
          scheduled_date: doc.data().scheduled_date,
          completion_date: doc.data().completion_date,
          reportFlg: doc.data().reportFlg,
        });
      });
    } catch (e) {
      console.log("エクスポートに失敗しました:" + e);
      return d;
    }
    return d;
  }

  return (
    <>
      {loading ? (
        <div>LOADING.....</div>
      ) : (
        <ResponsiveDrawer title={configValue.lang.controls}>
          <div>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <FormControl
                  component="fieldset"
                  className={classes.formControlPeriod}
                >
                  <FormLabel component="legend">エクスポート/削除</FormLabel>
                  <RadioGroup
                    row
                    aria-label="radio-group-select"
                    name="radio-group-select"
                    value={selectPeriod}
                    defaultValue={selectPeriod}
                    onChange={handleChangeSelectPeriod}
                  >
                    <FormControlLabel
                      value="年月度"
                      control={<Radio color="default" />}
                      label="年月度"
                    />
                    <FormControlLabel
                      value="全期間"
                      control={<Radio color="default" />}
                      label="全期間"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <TextField
                  className={classes.textfield}
                  id="month"
                  label="年月度"
                  type="month"
                  value={selectedMonth}
                  aria-invalid={errors.selectMonth ? "true" : "false"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("selectMonth", {
                    required: true,
                    maxLength: 7,
                    pattern: /^[0-9]{4}-(0[1-9]|1[0-2])$/,
                  })}
                  error={Boolean(errors.selectMonth)}
                  helperText={[
                    errors.selectMonth &&
                      errors.selectMonth.type === "required" &&
                      "入力必須です",
                    errors.selectMonth &&
                      errors.selectMonth.type === "maxLength" &&
                      "7文字以内で入力してください",
                    errors.selectMonth &&
                      errors.selectMonth.type === "pattern" &&
                      "日付フォーマット（YYYY/MM）で入力してください",
                  ]}
                  onChange={(event) => {
                    setSelectedMonth(event.target.value);
                    setValue("selectMonth", event.target.value);
                  }}
                  disabled={selectPeriod === "全期間"}
                />
              </div>
              <div className={classes.divCnt}>
                <InfomationsCsvButton
                  cnt={infomationsCnt}
                  selectPeriod={selectPeriod}
                  selectedMonth={selectedMonth}
                />
                <InvoicelinksCsvButton
                  cnt={invoicelinksCnt}
                  selectPeriod={selectPeriod}
                  selectedMonth={selectedMonth}
                />
                <InvoicesCsvButton
                  cnt={invoicesCnt}
                  selectPeriod={selectPeriod}
                  selectedMonth={selectedMonth}
                />
                <MaterialsCsvButton
                  cnt={materialsCnt}
                  selectPeriod={selectPeriod}
                  selectedMonth={selectedMonth}
                />
                <ReportsCsvButton
                  cnt={reportsCnt}
                  selectPeriod={selectPeriod}
                  selectedMonth={selectedMonth}
                />
                <WorksCsvButton
                  cnt={worksCnt}
                  selectPeriod={selectPeriod}
                  selectedMonth={selectedMonth}
                />
              </div>
            </form>
          </div>
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default Controls;
