import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../../../store/AuthContext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AccountIcon from '@material-ui/icons/AccountCircle';
import { SubmitHandler, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: 500,
      maxWidth: 550,
      backgroundColor: theme.palette.background.paper,
    },
    textfield: {
      width: '16ch',
      marginRight: 20,
    },
    button: {
      width: '12ch',
    },
  }),
);

type Inputs = {
  name: string,
};

const SetDisplayName: React.FC = () => {
  const classes = useStyles();
  const authValue = useContext(AuthContext);
  const [addName, setAddName] = useState(authValue.users.name);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<Inputs>({
    defaultValues: {
      name: addName,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (/* data */) => {
    reset();
    authValue.updateDisplayName(
      authValue.users.doc_id, 
      addName,
      authValue.users.mail,
      authValue.users.category,
      authValue.users.role,
      authValue.users.lang,
      authValue.users.is_dark,
      authValue.users.team,
    );
  };
  
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
    <List>
      <ListItem>
          <ListItemIcon>
            <AccountIcon />
          </ListItemIcon>
          <ListItemText id="label-displayname" primary="表示名を変更する" />
          <ListItemSecondaryAction>
              <TextField 
                className={classes.textfield} 
                id="standard-basic" 
                value={addName}
                aria-invalid={errors.name ? "true" : "false"}
                {...register("name", {
                  required: true,
                  maxLength: 20,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.name)} 
                helperText={[
                  errors.name && errors.name.type==="required" && "入力必須です",
                  errors.name && errors.name.type==="maxLength" && "20文字以内で入力してください",
                  errors.name && errors.name.type==="pattern" && "禁則文字が使用されています"
                ]}
                onChange={(event) => {
                  setAddName(event.target.value);
                  setValue("name", event.target.value);
                }} 
              />
              <Button 
                className={classes.button} 
                variant="contained" 
                color="primary" 
                type="submit"
              >
                変更
              </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      </form>
    );
  }

export default SetDisplayName;