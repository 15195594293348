import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Page,
  Document,
  StyleSheet,
  Font,
  View,
} from "@react-pdf/renderer";
import { db } from "../../../../config/firebase";
import { pdfConst } from "../../../../config/Const";
import { zen2Han } from "../../../../common/common";
import MyText from "../../../components/MyText";
import Grid from "../../../components/Grid";
import ListColumn from "../../../components/ListColumn";
import nasuRegular from "../../../../fonts/Nasu-Regular.ttf";
import nasuBold from "../../../../fonts/Nasu-Bold.ttf";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    //fontFamily: pdfConst.font.family,
    fontFamily: "Nasu-Regular",
    paddingVertical: 30,
    paddingHorizontal: 50,
  },
  headerContainer: {
    flexDirection: "column",
  },
  headerTitle: {
    flexDirection: "column",
    height: 25,
  },
  headerName: {
    flexDirection: "column",
    height: 20,
  },
});

Font.register({
  family: pdfConst.font.family,
  src: pdfConst.font.src,
});

Font.register({
  family: "Nasu-Regular",
  src: nasuRegular as string,
});

Font.register({
  family: "Nasu-Bold",
  src: nasuBold as string,
});

const WorksPdf: React.FC = (props: any) => {
  const { params } = props.match;
  const [completionDate, setCompletionDate] = useState("");
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [plan, setPlan] = useState("");
  const [aircon, setAircon] = useState("");
  const [interior, setInterior] = useState("");
  const [repo1, setRepo1] = useState("");
  const [repo2, setRepo2] = useState("");
  const [repo3, setRepo3] = useState("");
  const [unConstructed, setUnConstructed] = useState("");

  useEffect(() => {
    searchReports();
  }, []);

  const searchReports = async () => {
    try {
      const reportRef = db.collection("reports").doc(params.docId);
      const reportDoc = await reportRef.get();
      if (reportDoc.exists) {
        if (reportDoc.get("completion_date") !== undefined)
          setCompletionDate(reportDoc.get("completion_date"));
        if (reportDoc.get("user_name") !== undefined)
          setUserName(reportDoc.get("user_name"));
        if (reportDoc.get("name") !== undefined) setName(reportDoc.get("name"));
        if (reportDoc.get("room") !== undefined) setRoom(reportDoc.get("room"));
        if (reportDoc.get("plan") !== undefined) setPlan(reportDoc.get("plan"));
        if (reportDoc.get("aircon") !== undefined)
          setAircon(reportDoc.get("aircon"));
        if (reportDoc.get("interior") !== undefined)
          setInterior(reportDoc.get("interior"));
        if (reportDoc.get("repo1") !== undefined)
          setRepo1(reportDoc.get("repo1"));
        if (reportDoc.get("repo2") !== undefined)
          setRepo2(reportDoc.get("repo2"));
        if (reportDoc.get("repo3") !== undefined)
          setRepo3(reportDoc.get("repo3"));
        if (reportDoc.get("unConstructed") !== undefined)
          setUnConstructed(reportDoc.get("unConstructed"));
      }
    } catch (e) {
      console.log("searchReportsに失敗しました：" + e);
    }
  };

  // 12件以下：改行、13件以上:空白
  const repoReplace = (repo: string): string => {
    if (repo.split(",").length > 12) {
      return repo.replace(/,/g, "　　");
    } else {
      return repo.replace(/,/g, "\n");
    }
  };

  return (
    //<PDFViewer width="100%" height="100%">
    //<PDFViewer width="100%" height={window.innerHeight}>
    <PDFViewer
      style={{ position: "absolute", border: 0, width: "100%", height: "100%" }}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <View style={styles.headerTitle}>
              <MyText fontSize="14" textAlign="center">
                作業報告書
              </MyText>
            </View>
            <View style={styles.headerName}>
              <MyText fontSize="11" textAlign="left">
                作業者名　:　{userName}
              </MyText>
            </View>
          </View>

          <Grid height={20} borderTop borderLeft borderRight>
            <ListColumn widthPer="7%" borderRight>
              <MyText fontSize="11" textAlign="center">
                日付
              </MyText>
            </ListColumn>
            <ListColumn widthPer="17%" borderRight>
              <MyText fontSize="11" textAlign="center">
                {completionDate.replace(/-/g, "/")}
              </MyText>
            </ListColumn>
            <ListColumn widthPer="10%" borderRight>
              <MyText fontSize="11" textAlign="center">
                現場名
              </MyText>
            </ListColumn>
            <ListColumn widthPer="40%" borderRight>
              <MyText fontSize="11" textAlign="center">
                {zen2Han(name)}
              </MyText>
            </ListColumn>
            <ListColumn widthPer="12%" borderRight>
              <MyText fontSize="11" textAlign="center">
                部屋番号
              </MyText>
            </ListColumn>
            <ListColumn widthPer="14%">
              <MyText fontSize="11" textAlign="center">
                {room}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid borderTop borderLeft borderRight>
            <ListColumn widthPer="18%">
              <MyText fontSize="9" textAlign="center">
                クリーニング :
              </MyText>
            </ListColumn>
            <ListColumn widthPer="10%">
              <MyText fontSize="9" textAlign="left">
                {plan}
              </MyText>
            </ListColumn>
            <ListColumn widthPer="12%">
              <MyText fontSize="9" textAlign="center">
                エアコン :
              </MyText>
            </ListColumn>
            <ListColumn widthPer="10%">
              <MyText fontSize="9" textAlign="left">
                {aircon}
              </MyText>
            </ListColumn>
            <ListColumn widthPer="12%">
              <MyText fontSize="9" textAlign="center">
                内装工事 :
              </MyText>
            </ListColumn>
            <ListColumn widthPer="36%">
              <MyText fontSize="9" textAlign="left">
                {interior}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid borderLeft borderRight>
            <ListColumn widthPer="12%">
              <MyText fontSize="9" textAlign="center">
                未施工 :
              </MyText>
            </ListColumn>
            <ListColumn widthPer="64%">
              <MyText fontSize="9" textAlign="left">
                {unConstructed}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={25} borderTop borderLeft borderRight>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="left" marginLeft="8">
                [指 示 書 記 載 作 業 報 告]
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={200} borderLeft borderRight>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="left" marginLeft="8">
                {zen2Han(repoReplace(repo1))}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={25} borderTop borderLeft borderRight>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="left" marginLeft="8">
                [指 示 書 無 記 載 作 業 報 告]
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={180} borderLeft borderRight>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="left" marginLeft="8">
                {zen2Han(repoReplace(repo2))}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={25} borderTop borderLeft borderRight>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="left" marginLeft="8">
                [気 付 き 箇 所 報 告]
              </MyText>
            </ListColumn>
          </Grid>
          <Grid height={180} borderLeft borderRight>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="left" marginLeft="8">
                {zen2Han(repoReplace(repo3))}
              </MyText>
            </ListColumn>
          </Grid>
          <Grid borderTop>
            <ListColumn widthPer="100%">
              <MyText fontSize="10" textAlign="right" marginRight="10">
                {pdfConst.CO_NAME}
              </MyText>
            </ListColumn>
          </Grid>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default WorksPdf;
