import React, { useEffect, useContext } from "react";
import { AuthContext } from "../../../../store/AuthContext";
import { PDFViewer } from "@react-pdf/renderer";
import InvoicesPdf from "./InvoicesPdf";

const InvoicesViewer: React.FC = (props: any) => {
  const authValue = useContext(AuthContext);
  const { params } = props.match;

  useEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    }
  }, [authValue.currentUser]);

  return (
    <>
      <PDFViewer
        style={{
          position: "absolute",
          border: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <InvoicesPdf docId={params.docId} />
      </PDFViewer>
    </>
  );
};

export default InvoicesViewer;
