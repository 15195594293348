import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const env = process.env;

// Firebaseの初期化
export const firebaseConfig = {
    apiKey: env.REACT_APP_API_KEY,
    authDomain: env.REACT_APP_AUTH_DOMAIN,
    databaseURL: env.REACT_APP_DATABASE_URL,
    projectId: env.REACT_APP_PROJECT_ID,
    storageBucket: env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: env.REACT_APP_MESSAGING_SENDER_ID,
    appId: env.REACT_APP_APP_ID,
    measurementId: env.REACT_APP_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);

// Firestoreのインスタンス作成
export const db = firebase.firestore();

export const timestamp = firebase.firestore.FieldValue.serverTimestamp();

// ドメインとポート番号a
export const domain = document.domain;
export const url = (domain === 'localhost') ? 'http://localhost:3000' : 'https://' + domain + ':443';

export default firebase;