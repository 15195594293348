import React from 'react';
import { StyleSheet, View} from "@react-pdf/renderer";

export interface ListColumnProps {
	children?: React.ReactNode;
	widthPer: string;
	borderLeft?: boolean;
	borderRight?: boolean;
	borderTop?: boolean;
	borderBottom?: boolean;
}

const ListColumn: React.FC<ListColumnProps> = ({
	children, 
	widthPer, 
	borderLeft, 
	borderRight, 
	borderTop, 
	borderBottom,
}) => {
	const styles = StyleSheet.create({
		listColumn: {
			width: widthPer,
			borderLeftWidth: borderLeft ? 1 : 0,
			borderRightWidth: borderRight ? 0.5 : 0,
			borderTopWidth: borderTop ? 1 : 0,
			borderBottomWidth: borderBottom ? 1 : 0,
		},
	});

	return (
		<View style={styles.listColumn}>{children}</View>
	);
};

export default ListColumn;