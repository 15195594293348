import React, { useContext, useState, useEffect } from 'react';
import '../css/Views.css';
import { url } from '../../config/firebase';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../store/AuthContext';

const Verified: React.FC = () => {
  const authValue = useContext(AuthContext);
  const [msg, setMsg] = useState<string>("");

  useEffect(() => {
    async function mailVerified() {
      // メールアドレス未確認
      if (authValue.currentUser !== null && authValue.currentUser !== undefined && authValue.currentUser.emailVerified===false) {
        const actionCodeSettings = {
          url: `${url}`
        };
        try {
          await authValue.currentUser.sendEmailVerification(actionCodeSettings);
          setMsg(`${authValue.currentUser?.email}宛に確認メールを送信しました`);
          return;
        } catch (e) {
          setMsg(`${authValue.currentUser?.email}宛に確認メールを送信できませんでした：${e}`);
          return;
        }
      }
    }
    mailVerified();
  }, [authValue.currentUser]);

  return (
    <>
    <div className="Views">
    {
      (authValue.currentUser?.emailVerified===false) ? (
        <div className="Views-body">{msg}</div>
      ) :
        <Redirect to="/" />
    }
    </div>
    </>
  );
}

export default Verified;
