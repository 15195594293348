export namespace SystemConst {
    export const APP_CODE = 'RestoreFlow';
    export const APP_NAME = 'レストアフロー';
    export namespace TestNameSpace {
        export const TestString = 'test';
    }
}

export namespace infomationsConst {
    export const verification = {
        True: '確認済み',
        False: '未確認',
    } as const;
    export const menu = {
        Works: 'works',
        Invoices: 'invoices',
        Materials: 'materials',
    } as const;
}

export namespace usersConst {
    export const categoryName = {
        Cleaning: 'クリーニング',
        Interior: '内装',
    } as const;
    export const roleName = {
        Manager: 'manager',
        User: 'user',
        Admin: 'admin',
    } as const;
}

export namespace worksConst {
    export const areaName: { [key: string]: string } = {
        Sa: "狭山",
        Oh: "青梅",
        Ta: "立川",
        To: "所沢",
    } as const;
    export const stateName: { [key: string]: string } = {
        Mi: "未着手",
        Sa: "作業中",
        Ka: "作業完了",
        Pe: "中断",
        Si: "承認依頼",
        Ok: "承認済",
        Ng: "差戻し",
        Ni: "差戻依頼",
    } as const;
    export const plan: { [key: string]: string } = {
        '1K': '1K',
        '2K': '2K',
        '1DK': '1DK',
        '1LDK': '1LDK',
        '2DK': '2DK',
        '2LDK': '2LDK',
        '3DK': '3DK',
        '3LDK': '3LDK',
        'NO': 'なし',
    } as const;
    export const airconName: { [key: string]: string } = {
        'A0': '0',
        'A1': '1',
        'A2': '2',
        'A3': '3',
        'A4': '4',
    } as const;
    export const textSi = {
        Manager: '作業報告書の承認依頼があります。',
        User: '作業報告書の承認依頼をしました。',
    } as const;
    export const textOk = {
        Manager: '作業報告書を承認しました。',
        User: '作業報告書が承認されました。',
    } as const;
    export const textNg = {
        Manager: '作業報告書を差戻しました。',
        User: '作業報告書を差戻しされました。',
    } as const;
    export const textNi = {
        Manager: '作業報告書の差戻し依頼があります。',
        User: '作業報告書の差戻し依頼をしました。',
    } as const;
}

export namespace materialConst {
    export const state = {
        Mikakutei: '未確定',
        KonyuIrai: '買取依頼済',
    } as const;
    export const unitPrice = {
        SsJh: 4410,
        SsJa: 4410,
        ElbowI: 4100,
        ElbowH: 4100,
        Nipple: 4400,
        Shower: 1260,
        HeatShield: 1940,
        AntiEva: 2800,
        PipeFanUnPlug: 2000,
        PipeFanPlug: 2000,
        Fan: 2200,
        Triangle: 11000,
        Top: 14600,
        FilterL: 3800,
        FilterS: 3800,
        Wax: 2850,
    } as const;
    export const text = {
        Manager: '部材買取依頼があります。',
        User: '部材買取依頼をしました。',
    } as const;
    export const taxRate = 0.1;
}

export namespace invoicesConst {
    export const text = {
        Manager: '請求書が確定されました。',
        User: '請求書を確定しました。',
    } as const;
}

export namespace pdfConst {
    export const font = {
        family: 'Sawarabi Mincho',
        src: 'https://fonts.gstatic.com/ea/sawarabimincho/v1/SawarabiMincho-Regular.ttf',
    } as const;
    export const CO_NAME = 'ECO1 - KABAKI';
}