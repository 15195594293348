import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { pdfConst } from '../../../../config/Const';
import MyText from '../../../components/MyText';

interface PdfHeaderProps {
  yyyymm: string;
  userName: string;
  currentPage: number;
	totalPage: number;
  confirmDate: string;
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'column',
    paddingLeft: 2,
    paddingRight:2,
  },
  headerColumnTitle: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  headerColumnDate: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  headerColumnConfirm: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    color: 'white',
    backgroundColor: 'orange',
  },
});

const InvoicesPdfHeader: React.FC<PdfHeaderProps> = ({yyyymm, userName, currentPage, totalPage, confirmDate}) => {
  const date: string = (confirmDate==='') 
    ? '----年--月--日' 
    : confirmDate.substr(0, 4) + '年' 
      + confirmDate.substr(4, 2) + '月' 
      + confirmDate.substr(6, 2) + '日';

  const Confirm = () => {
    return (
      <View style={styles.headerColumnConfirm}>
        <MyText fontSize='18'>&nbsp;未 確 定&nbsp;</MyText>
      </View>
    );
  }

  return (
    <View style={styles.headerContainer}>
      {(confirmDate==='') ? <Confirm /> : <></>}
      <View style={styles.headerColumnDate}>
        <MyText fontSize='9'>発行日:{date} P:{currentPage}/{totalPage}</MyText>
      </View>
      <View style={styles.headerColumnTitle}>
        <MyText fontSize='13'>{yyyymm.substr(0, 4)}年{yyyymm.substr(4, 2)}月分 請求書　　　{pdfConst.CO_NAME} 御中　　　業者:{userName}</MyText>
      </View>
    </View>
  );
};

export default InvoicesPdfHeader;