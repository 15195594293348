import React from 'react';
import '../css/Views.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact: React.FC = () => {
  return (
    <div>
      <Header />
      <body className="Views-contact">
        <h2>Contact Us</h2>
        <div>
          <div>If you need to get in touch,</div>
          <div>&nbsp;please email&nbsp;<a href="mailto:hello&#64;restoreflow3000.com">hello@restoreflow3000.com.</a></div>
        </div>
      </body>
      <Footer />
    </div>
  );

}

export default Contact;