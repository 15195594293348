import React from "react";
import { StyleSheet, View} from "@react-pdf/renderer";

export interface GridProps {
	children?: React.ReactNode;
	fdColumn?: boolean;
	height?: number;
	borderLeft?: boolean;
	borderRight?: boolean;
	borderTop?: boolean;
	borderBottom?: boolean;
}

const Grid: React.FC<GridProps> = ({
	children,
	fdColumn, 
	height,
	borderLeft, 
	borderRight, 
	borderTop, 
	borderBottom,
}) => {
	const styles = StyleSheet.create({
		grid: {
			flexDirection: fdColumn ? "column" : "row",
			height: height ? height : 18 ,
			borderLeftWidth: borderLeft ? 1 : 0,
			borderRightWidth: borderRight ? 1 : 0,
			borderTopWidth: borderTop ? 1 : 0,
			borderBottomWidth: borderBottom ? 1 : 0,
		},
	});

	return (
		<View style={styles.grid}>{children}</View>
	);
};

export default Grid;