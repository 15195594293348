import React, { useEffect, useState } from 'react';
import '../views/css/Views.css';
import firebase, { db } from '../config/firebase';

type users = {
  doc_id: string,
  name: string,
  mail: string,
  category: string,
  role: string,
  lang: string,
  is_dark: boolean,
  team: string,
}

interface IAuthContext {
  currentUser: firebase.User | null | undefined;
  setLogout: () => void;
  users: users;
  updateDisplayName: (
    doc_id: string, 
    name: string,
    mail: string,
    category: string,
    role: string,
    lang: string,
    is_dark: boolean,
    team: string,
    ) => void;
}

const AuthContext = React.createContext<IAuthContext>({ 
  currentUser: undefined,
  setLogout: () => {},
  users: {
    doc_id: "",
    name: "",
    mail: "",
    category: "",
    role: "",
    lang: "",
    is_dark: false,
    team: "",
  },
	updateDisplayName: () => {},
});

const AuthProvider = (props: any) => {
  //alert('AuthProvider');
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<firebase.User | null | undefined>(undefined);
  const [users, setUsers] = useState<users>({
    doc_id: "",
    name: "",
    mail: "",
    category: "",
    role: "",
    lang: "",
    is_dark: false,
    team: "",
  });

  const updateDisplayName = async(
    doc_id: string, 
    name: string,
    mail: string,
    category: string,
    role: string,
    lang: string,
    is_dark: boolean,
    team: string,
    ) => {
    try {
      const ref = db.collection('users').doc(doc_id);
      await ref.set({name: name}, {merge: true});

      const u: users = {
        doc_id: doc_id,
        name: name,
        mail: mail,
        category: category,
        role: role,
        lang: lang,
        is_dark: is_dark,
        team: team,
      };
      setUsers(u);

    } catch (e) {
      console.log("nameの更新に失敗しました：" + e);
    }
  };

  const setLogout = () => {
    setCurrentUser(undefined);
    setLoading(false);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user===null) setLoading(false);
      if (!user) return;
      if (!user.email) return;

      // ユーザ情報設定
      async function setUsersInfo(mail: string) {
        try {
          // ホワイトリストチェック
          const whitelistsRef = await db.collection('whitelists').where('mail', '==', mail).get();
          if (whitelistsRef.empty) {
            setLoading(false);
            return;
          }
          var category;
          var role;
          var team;
          whitelistsRef.forEach(doc => {
            category = doc.data().category;
            role = doc.data().role;
            team = doc.data().team;
          });

          setCurrentUser(user);

          // 初回のみusers作成
          const usersRef = await db.collection('users').doc(user?.uid).get();
          if (!usersRef.exists) {
            await db.collection('users').doc(user?.uid).set({
              name: user?.displayName,
              mail: user?.email,
              category: category,
              role: role,
              lang: 'en',
              is_dark: false,
              team: team,
            }, {merge: true});
          }

          const u: users = {
            doc_id: "",
            name: "",
            mail: "",
            category: "",
            role: "",
            lang: "en",
            is_dark: false,
            team: "",
          };
          
          const docRef = db.collection('users').doc(user?.uid);
          await docRef.get().then((doc) => {
            if (doc.exists) {
              u.doc_id = doc.id;
              u.name = doc.data()?.name;
              u.mail = doc.data()?.mail;
              u.category = doc.data()?.category;
              u.role = doc.data()?.role;
              u.lang = doc.data()?.lang;
              u.is_dark = doc.data()?.is_dark;
              u.team = doc.data()?.team;
            }
          }).catch((e) => {
            console.log('usersの取得に失敗しました:' + e);
          });

          setUsers(u);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
          return;
        }
      }
      // ユーザ情報設定
      setUsersInfo(user.email);
    });
  }, []);

  return (
    <>
    {
      loading ? (
        <div className="Views-body">LOADING.....</div> 
      ) :
        <AuthContext.Provider 
          value={{ 
            currentUser: currentUser, 
            setLogout: setLogout,
            users: users,
            updateDisplayName: updateDisplayName,
          }}>
          {props.children}
        </AuthContext.Provider>
    }
    </>
  );
}

export { AuthContext, AuthProvider };