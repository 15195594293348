import React from 'react';
import '../css/Views.css';
import { Link } from '@material-ui/core';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <Header />
      <body className="Views-container">
        <h2>RestoreFlowプライバシーポリシー</h2>
        <h3 className="Views-h">基本方針</h3>
        <div>RestoreFlow3000.com（以下「当サービス」といいます）は、個人情報保護の重要性を認識し、法令を遵守することが社会的責務であると考え、当サービスで取扱う個人情報の取得、利用、管理を適正に行います。</div>
        <h3 className="Views-h">定義</h3>
        <ol>
          <li>当サービス：RestoreFlow</li>
          <li>画面：Webアプリケーション</li>
          <li>帳票：完了報告書PDF、部材買取依頼表PDF、請求書PDF</li>
        </ol>
        <h3 className="Views-h">適用範囲</h3>
        <div>当サービスの提供におけるお客様の個人情報の取り扱いに適用されます。</div>
        <h3 className="Views-h">取得項目</h3>
        <ol>
          <li>メールアドレス</li>
          <li>組織名（表示名）</li>
          <li>氏名（表示名）</li>
          <li>作業情報</li>
          <li>請求情報</li>
        </ol>
        <h3 className="Views-h">取得目的と範囲</h3>
        <div>当サービスを提供するために以下の目的の範囲内で個人情報を取得します。</div>
        <ul>
          <li>当サービスのログイン認証に利用するため</li>
          <li>ログインユーザーの組織名、氏名（任意の表示名に変更可能）を画面に表示するため</li>
          <li>ログインユーザーの組織名、氏名（任意の表示名に変更可能）を帳票に表示するため</li>
          <li>ログインユーザーが作業情報、請求情報を入力し、当サービスを利用するため</li>
        </ul>
        <h3 className="Views-h">個人情報の管理</h3>
        <ul>
          <li>個人情報の管理者を設置し、法令、国が定める指針その他規範を常に最新に維持し遵守します。</li>
          <li>個人情報の不正利用・紛失・破壊・改ざん・漏洩に対し適切な予防ならびに是正に関する措置を講じます。</li>
          <li>個人情報を保護するため、当サービスを停止することがあります。</li>
          <li>個人情報の取り扱いを外部に委託する場合には、要件を満たした委託先にのみ委託を行い、適切に管理します。</li>
          <li>個人情報を第三者に提供することはありません。</li>
          <li>個人情報の保存期間を設定し、保存期間終了後は廃棄します。</li>
        </ul>
        <h3 className="Views-h">個人情報の管理者</h3>
        <div>ドルフィン　平野茂樹</div>
        <h3 className="Views-h">お問い合わせ</h3>
        <div>当サービス、個人情報の取り扱い、削除依頼は下記フォームからお問合せください。</div>
        <div>
          <Link 
            href="https://docs.google.com/forms/d/e/1FAIpQLSd19tC-22r9E-JL-9v4FU71B3bUy1s30ISyswOai82LcxxQgA/viewform?usp=sf_link" 
            target="_brank"
            color="inherit" 
          >
            お問合せ・削除依頼
          </Link>
        </div>
        <h3 className="Views-h">Cookie</h3>
        <div>当サービスではGoogleAnalyticsを利用する場合に限りCookieを利用します。またログの取得、広告配信をしていません。</div>
        <h3 className="Views-h">プライバシーポリシーの変更</h3>
        <div>プライバシーポリシーに変更があった場合、最新の内容は常に本ページに掲載されます。</div>
        <h3 className="Views-h">改定</h3>
        <div>制定：2021年8月22日</div>
      </body>
      <Footer />
    </div> 
  );

}

export default PrivacyPolicy;
