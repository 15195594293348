import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ConfigContext } from '../../../../store/ConfigContext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import BrightnessIcon from '@material-ui/icons/Brightness6';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const SwitchTheme: React.FC = () => {
  const classes = useStyles();
  const ConfigValue = useContext(ConfigContext);

  return (
    <List className={classes.root}>
      <ListItem>
          <ListItemIcon>
            <BrightnessIcon />
          </ListItemIcon>
          <ListItemText id="switch-list-label-brightness" primary="ダークモードにする" />
          <ListItemSecondaryAction>
            <Switch
              name="isDark"
              edge="end"
              onChange={ConfigValue.handleIsDark}
              checked={ConfigValue.blightness}
              inputProps={{ 'aria-labelledby': 'switch-list-label-brightness' }}
              color="primary"
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  }

export default SwitchTheme;