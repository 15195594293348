import React from 'react';
import Grid from '../../../components/Grid';
import ListColumn from '../../../components/ListColumn';
import MyText from '../../../components/MyText';

const InvoicesPdfListHeader: React.FC = () => {
  return (
    <>
    <Grid borderTop borderBottom borderLeft borderRight>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>施工日</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>営業所</MyText></ListColumn>
      <ListColumn widthPer="18%" borderRight><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>現場名</MyText></ListColumn>
      <ListColumn widthPer="4%" borderRight><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>部屋</MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>間取</MyText></ListColumn>
      <ListColumn widthPer="14%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>施工内容</MyText></ListColumn>
      <ListColumn widthPer="3%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="5.5%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="14%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="3%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="5.5%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="14%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="3%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="5.5%" borderRight><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'></MyText></ListColumn>
      <ListColumn widthPer="6.5%"><MyText fontSize='8' textAlign='center' marginLeft='1' marginRight='1'>計</MyText></ListColumn>
    </Grid>
    </>
  );
};

export default InvoicesPdfListHeader;