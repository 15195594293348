import React from 'react';
import { infomationsConst } from '../../../../config/Const';
import Box from '@material-ui/core/Box';

export interface InfomationsStateProps {
    children: React.ReactNode;
}
  
const InfomationsState: React.FC<InfomationsStateProps> = ({children}) => {
	if (children===infomationsConst.verification.True) {
		return (
			<Box bgcolor="success.main" color="white">{children}</Box>
		);
	} else {
		return (
			<Box bgcolor="warning.main" color="white">{children}</Box>
		);
	}
};

export default InfomationsState;