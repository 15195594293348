import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SystemConst } from "../../config/Const";
import { usersConst } from "../../config/Const";
import { AuthContext } from "../../store/AuthContext";
import { ConfigContext } from "../../store/ConfigContext";
import firebase from "../../config/firebase";
import Copyright from "../components/Copyright";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import UsersIcon from "@material-ui/icons/People";
import WorksIcon from "@material-ui/icons/AssignmentTurnedIn";
import InvoicesIcon from "@material-ui/icons/Description";
import MaterialsIcon from "@material-ui/icons/ShoppingCart";
import ControlsIcon from "@material-ui/icons/SettingsInputComponent";
import ExportsIcon from "@material-ui/icons/CloudDownload";
import { Button, Box, Container } from "@material-ui/core";

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        //width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    main: {
      flexGrow: 1,
    },
    content: {
      padding: theme.spacing(3),
      minHeight: "80vh",
    },
    title: {
      flexGrow: 1,
    },
    code: {
      flexGrow: 1,
      fontSize: 14,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    userName: {
      marginRight: theme.spacing(1),
    },
    footer: {
      padding: theme.spacing(3),
      minHeight: "10vh",
    },
    img: {
      width: "35px",
      marginRight: theme.spacing(2),
      borderRadius: "50%",
    },
  })
);

export interface ResponsiveDrawerProps {
  children: React.ReactNode;
  title: string;
}

interface MenuListItem {
  text: string;
  icon: any;
  to: string;
}

interface Props {
  window?: () => Window;
}

function createData(text: string, icon: any, to: string): MenuListItem {
  return { text, icon, to };
}

const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = (
  { children, title },
  props: Props
) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const authValue = useContext(AuthContext);
  const configValue = useContext(ConfigContext);
  const pUrl: any = authValue.currentUser?.photoURL;
  const { window } = props;

  const uMenuItems = [
    createData(configValue.lang.home, <HomeIcon />, "/"),
    createData(configValue.lang.works, <WorksIcon />, "/works"),
    createData(configValue.lang.materials, <MaterialsIcon />, "/materials"),
    createData(configValue.lang.invoices, <InvoicesIcon />, "/invoices"),
  ];

  const aMenuManager: MenuListItem[] = [
    createData(configValue.lang.users, <UsersIcon />, "/users"),
    createData(configValue.lang.settings, <SettingsIcon />, "/settings"),
    createData(configValue.lang.exports, <ExportsIcon />, "/exports"),
  ];

  const aMenuUser: MenuListItem[] = [
    createData(configValue.lang.settings, <SettingsIcon />, "/settings"),
    createData(configValue.lang.exports, <ExportsIcon />, "/exports"),
  ];

  const aMenuAdmin: MenuListItem[] = [
    createData(configValue.lang.users, <UsersIcon />, "/users"),
    createData(configValue.lang.settings, <SettingsIcon />, "/settings"),
    createData(configValue.lang.exports, <ExportsIcon />, "/exports"),
    createData(configValue.lang.controls, <ControlsIcon />, "/controls"),
  ];

  var aMenuItems;
  if (authValue.users.role === usersConst.roleName.Manager) {
    aMenuItems = aMenuManager;
  } else if (authValue.users.role === usersConst.roleName.User) {
    aMenuItems = aMenuUser;
  } else if (authValue.users.role === usersConst.roleName.Admin) {
    aMenuItems = aMenuAdmin;
  } else {
    aMenuItems = aMenuUser;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        authValue.setLogout();
        //window.location.reload();
        if (window !== undefined) window().location.reload();
        console.log("サインアウトしました");
      })
      .catch((e) => {
        console.log(`サインアウト時にエラーが発生しました（${e}）`);
      });
  };

  const drawer = (
    <div>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {uMenuItems.map((item, index) => (
            <Link to={item.to} className={classes.link}>
              <ListItem button key={index}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          {aMenuItems.map((item, index) => (
            <Link to={item.to} className={classes.link}>
              <ListItem button key={index}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {SystemConst.APP_CODE + " :" + authValue.users.team}
          </Typography>
          <Typography
            component="p"
            variant="body1"
            color="initial"
            noWrap
            className={classes.userName}
          >
            {configValue.lang.greet + " " + authValue.users.name}さん
          </Typography>
          <img src={pUrl} className={classes.img} alt="" />
          <Button variant="outlined" color="default" onClick={handleLogout}>
            LOGOUT
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.main}>
        <Container className={classes.content}>
          <Toolbar />
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.pageTitle}
          >
            {title}
          </Typography>
          <Typography paragraph>{children}</Typography>
        </Container>
        <Container className={classes.footer}>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default ResponsiveDrawer;
