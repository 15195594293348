import { Theme, useMediaQuery } from "@material-ui/core";
import { useCallback, useState } from "react";

// カウントアップ
export const useCounter = (initialValue: number) => {
	const [count, setCount] = useState(initialValue);
	const countUp = useCallback(() => {
		setCount((prev) => prev + 1);
	}, []);
	return [count, countUp] as const;
};

// モバイルサイズ判定
export const useSize = () => {
	const isMobileSize = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('xs')
	);
	return { isMobileSize };
};