import React, { useContext } from 'react';
import Config from '../../../../config/Config';
import { ConfigContext } from '../../../../store/ConfigContext';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TranslateIcon from '@material-ui/icons/Translate';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        marginRight: theme.spacing(1),
        width: '15ch',
    },
  }),
);
  
const SelectLang: React.FC = () => {
    const classes = useStyles();
    const ConfigValue = useContext(ConfigContext);

    const handleChangeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
        ConfigValue.changeLang(event.target.value as number);
    };

    return (
        <>
        <List className={classes.root}>
            <ListItem>
                <ListItemIcon>
                    <TranslateIcon />
                </ListItemIcon>
                <FormControl className={classes.formControl}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ConfigValue.lang.value}
                        onChange={handleChangeLanguage}
                    >
                        <MenuItem value={Config.language.en.value}>{Config.language.en.name}</MenuItem>
                        <MenuItem value={Config.language.ja.value}>{Config.language.ja.name}</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
        </>
    );
};

export default SelectLang;