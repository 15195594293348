import React, { useEffect, useState } from 'react';
import { db } from '../../../../config/firebase';
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogActions, 
  DialogTitle, 
  TextField, 
  InputLabel, 
  MenuItem, 
  FormControl, 
  Select, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import Config from '../../../../config/Config';
import { usersConst } from '../../../../config/Const';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '40ch',
      marginBottom: 20,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: '30ch'
  },
  textfield: {
    width: '30ch',
  },
}));

type Props = {
  title: string;
  isOpen: boolean;
  buttonText: string;
  fncClose: () => void;
  fncIsChange: () => void;
  docId?: string;
  editName?: string;
  editCategory?: string;
  editRole?: string;
  editMail?: string;
  currentUserRole: string;
};

type Inputs = {
  id: string,
  name: string,
  mail: string,
};

const UsersDialog: React.FC<Props> = ({ 
  title, 
  isOpen, 
  buttonText, 
  fncClose, 
  fncIsChange, 
  docId, 
  editName, 
  editCategory, 
  editRole, 
  editMail,  
  currentUserRole, 
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const id = (docId === null || docId === undefined) ? '' : docId;
  const name = (editName === null || editName === undefined) ? '' : editName;
  const category = (editCategory === null || editCategory === undefined) ? usersConst.categoryName.Cleaning : editCategory;
  const role = (editRole === null || editRole === undefined) ? usersConst.roleName.User : editRole;
  const mail = (editMail === null || editMail === undefined) ? '' : editMail;
  const [addId, setAddId] = useState(id);
  const [addName, setAddName] = useState(name);
  const [addCategory, setAddCategory] = useState(category);
  const [addRole, setAddRole] = useState(role);
  const [addMail, setAddMail] = useState(mail);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<Inputs>({
    defaultValues: {
      id: addId,
      name: addName,
      mail: addMail,
    },
  });
  
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    reset();
    setAddId(id);
    setAddName(name);
    setAddCategory(category);
    setAddRole(role);
    setAddMail(mail);
    setOpen(false);
    fncClose();
  };

  const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAddCategory(event.target.value as string);
  };

  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAddRole(event.target.value as string);
  };

  const onSubmit: SubmitHandler<Inputs> = (/* data */) => {
    reset();
    buttonText === "追加" ? handleAdd() : handleEdit();
  };

  const handleAdd = async() => {
    try {
      const addRef = db.collection('users');

/*       await addRef.add({
        name: addName,
        category: addCategory,
        role: addRole,
        mail: addMail,
        lang: Config.language.en.id,
        is_dark: Config.blightness.light.isDark,
      });
 */
      await addRef.doc(addId).set({
        name: addName,
        category: addCategory,
        role: addRole,
        mail: addMail,
        lang: Config.language.en.id,
        is_dark: Config.blightness.light.isDark,
      });
      setAddId('');
      setAddName('');
      setAddCategory('');
      setAddRole('');
      setAddMail('');
      fncIsChange();
    } catch(e) {
      console.log("追加に失敗しました：" + e);
    }
    setOpen(false);
    fncClose();
  };

  const handleEdit = async() => {
    try {
      const editRef = db.collection('users').doc(docId);
      await editRef.set({
        name: addName,
        category: addCategory,
        role: addRole,
        mail: addMail,
      }, {merge: true});
      fncIsChange();
    } catch (e) {
      console.log("編集に失敗しました：" + e);
    }
    setOpen(false);
    fncClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
          <DialogContent>
            <div>
              <TextField 
                className={classes.textfield} 
                id="standard-basic" 
                type="text" 
                disabled={buttonText === "追加" ? false : true} 
                label="ID" 
                value={addId}
                aria-invalid={errors.id ? "true" : "false"}
                {...register("id", {
                  required: true,
                  maxLength: 30,
                  pattern: /^[0-9A-Za-z]+$/,
                })}
                error={Boolean(errors.id)} 
                helperText={[
                  errors.id && errors.id.type==="required" && "入力必須です",
                  errors.id && errors.id.type==="maxLength" && "10文字以内で入力してください",
                  errors.id && errors.id.type==="pattern" && "半角英数字で入力してください"
                ]}
                onChange={(event) => {
                  setAddId(event.target.value);
                  setValue("id", event.target.value);
                }} 
                />
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="standard-basic" 
                disabled={buttonText === "追加" ? false : true} 
                label="NAME" 
                value={addName}
                aria-invalid={errors.name ? "true" : "false"}
                {...register("name", {
                  required: true,
                  maxLength: 20,
                  pattern: /[^!"#$%&'()\*\+\-\.,\/:;<=>?\[\\\]^_`{|}~]+/,
                })}
                error={Boolean(errors.name)} 
                helperText={[
                  errors.name && errors.name.type==="required" && "入力必須です",
                  errors.name && errors.name.type==="maxLength" && "20文字以内で入力してください",
                  errors.name && errors.name.type==="pattern" && "禁則文字が使用されています"
                ]}
                onChange={(event) => {
                  setAddName(event.target.value);
                  setValue("name", event.target.value);
                }} 
                />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">CATEGORY</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addCategory}
                  onChange={handleChangeCategory} 
                  disabled={(currentUserRole === 'admin') ? false: true} 
                >
                  <MenuItem value={usersConst.categoryName.Cleaning}>{usersConst.categoryName.Cleaning}</MenuItem>
                  <MenuItem value={usersConst.categoryName.Interior}>{usersConst.categoryName.Interior}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">ROLE</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addRole} 
                  onChange={handleChangeRole} 
                  disabled={(currentUserRole === 'admin') ? false: true} 
                >
                  <MenuItem value={usersConst.roleName.Manager}>{usersConst.roleName.Manager}</MenuItem>
                  <MenuItem value={usersConst.roleName.User}>{usersConst.roleName.User}</MenuItem>
                  <MenuItem value={usersConst.roleName.Admin}>{usersConst.roleName.Admin}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField 
                className={classes.textfield} 
                id="standard-basic" 
                type="email"
                placeholder="xxx@xxx.xxx"
                label="MAIL ADDRESS"
                value={addMail}
                aria-invalid={errors.mail ? "true" : "false"}
                {...register("mail", {
                  required: true,
                  pattern: /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                })}
                error={Boolean(errors.mail)}
                helperText={[
                  errors.mail && errors.mail.type==="required" && "入力必須です",
                  errors.mail && errors.mail.type==="pattern" && "正しいメールアドレスを入力してください"
                ]}
                onChange={(event) => {
                  setAddMail(event.target.value);
                  setValue("mail", event.target.value);
                }} 
                disabled={(currentUserRole === 'admin') ? false: true} 
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="default" type="submit">{buttonText}</Button>
            <Button onClick={handleClose} color="default">キャンセル</Button>
         </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default UsersDialog;