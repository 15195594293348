
import firebase from '../config/firebase';

export function fromTimeStampToDate(date: firebase.firestore.Timestamp) {
    if (!date) return;
    const d = new Date(date.seconds * 1000);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    const sec = (`0${d.getSeconds()}`).slice(-2);

    return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
}

export function fromTimeStampToString(date: firebase.firestore.Timestamp) {
    if (!date) return "";
    const d = new Date(date.seconds * 1000);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);

    return `${year}${month}${day}`;
}

export function getStartDay(yyyymm: string, isZeroP: boolean = true): string {
    const yyyy = yyyymm.substring(0, 4);
    const mm = yyyymm.substring(4, 6);
    if (mm === "01") {
      return (Number(yyyy) - 1).toString() + "-12-26";
    } else {
        if (isZeroP) {
            return yyyy + "-" + (Number(mm) - 1).toString().padStart(2, "0") + "-26";
        }
        return yyyy + "-" + (Number(mm) - 1).toString() + "-26";
    }
}

export function getEndDay(yyyymm: string, isZeroP: boolean = true): string {
    const yyyy = yyyymm.substring(0, 4);
    const mm = yyyymm.substring(4, 6);
    if (mm.substring(0,1) === "0" && isZeroP === false) {
        return yyyy + "-" + mm.substring(1,2) + "-25";
    }
    return yyyy + "-" + mm + "-25";
}

export function dateToString(date: Date, format: string): string {
    format = format.replace(/YYYY/, date.getFullYear().toString());
    format = format.replace(/MM/, (date.getMonth() + 1).toString().padStart(2, "0"));
    format = format.replace(/DD/, date.getDate().toString().padStart(2, "0"));
    return format;
}

export function getTargetMonth(yyyymmdd: string): string {
    const d = yyyymmdd.replace(/-/g, '');
    const yyyy = d.substr(0, 4);
    const mm = d.substr(4, 2);
    const dd = d.substr(6, 2);

    if (Number(dd) > 25) {
        if (mm === "12") {
            return (Number(yyyy) + 1).toString() + "01";
        } else {
            return yyyy + (Number(mm) + 1).toString().padStart(2, "0");
        }
    } else {
        return yyyy + mm;
    }
}

export function getBillingDate(yyyymm: string): string {
    const yyyy = yyyymm.substr(0, 4);
    const mm = yyyymm.substr(4, 2);

    if (mm === "12") {
        return (Number(yyyy) + 1).toString() + "0125";
    } else {
        return yyyy + (Number(mm) + 1).toString().padStart(2, "0") + "25";
    }
}