import React from 'react';
import { StyleSheet, Text } from "@react-pdf/renderer";

export interface TextProps {
	children?: React.ReactNode;
	fontSize?: string;
	textAlign?: any;
	marginLeft?: string;
	marginRight?: string;
	marginTop?: string;
}

const MyText: React.FC<TextProps> = ({
	children, 
	fontSize, 
	textAlign, 
	marginLeft, 
	marginRight, 
	marginTop,
}) => {
	const styles = StyleSheet.create({
		myText: {
			fontSize: fontSize ? fontSize : '12',
			textAlign: textAlign ? textAlign : 'left',
			marginLeft: marginLeft ? marginLeft : '0',
			marginRight: marginRight ? marginRight : '0',
			marginTop: marginTop ? marginTop : '2',
		},
	});

	return (
		<Text style={styles.myText}>{children}</Text>
	);
};

export default MyText;