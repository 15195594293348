import React from 'react';
import { worksConst } from '../../../../config/Const';
import Box from '@material-ui/core/Box';

export interface WorksStateProps {
    children: React.ReactNode;
}

const WorksState: React.FC<WorksStateProps> = ({children}) => {
	var bgColor;
	if (children===worksConst.stateName.Mi) {
		bgColor = "warning.light";
	} else if (children===worksConst.stateName.Sa) {
		bgColor = "warning.dark";
	} else if (children===worksConst.stateName.Ka) {
		bgColor = "primary.light";
	} else if (children===worksConst.stateName.Pe) {
		bgColor = "secondary.main";
	} else if (children===worksConst.stateName.Si) {
		bgColor = "primary.dark";
	} else if (children===worksConst.stateName.Ok) {
		bgColor = "success.main";
	} else if (children===worksConst.stateName.Ng) {
		bgColor = "error.main";
	} else if (children===worksConst.stateName.Ni) {
		bgColor = "error.light";
	} else {
		bgColor = "error.dark";
	}
	return (
		<Box bgcolor={bgColor} color="white">{children}</Box>
	);
};

export default WorksState;