import React, { useContext, useLayoutEffect } from 'react';
import ResponsiveDrawer from '../templates/ResponsiveDrawer';
import { AuthContext } from '../../store/AuthContext';
import { ConfigContext } from '../../store/ConfigContext';
import Infomations from './infomations/Infomations';

const Home: React.FC = (props: any) => {
  const authValue = useContext(AuthContext);
  const configValue = useContext(ConfigContext);

  useLayoutEffect(() => {
    if (authValue.currentUser === undefined || authValue.currentUser === null) {
      props.history.push("/login");
    // メール未確認の場合
    } else if (authValue.currentUser?.emailVerified === false) {
      // メール認証の場合のみ、メール確認をする
      if (authValue.currentUser?.providerData[0]?.providerId==='password') {
        props.history.push("/verified");
      }
    }
  }, [authValue.currentUser]);

  return (
    <>
    <ResponsiveDrawer title={configValue.lang.home}>
      <Infomations />
    </ResponsiveDrawer>
    </>
  );
}

export default Home;