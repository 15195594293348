import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { materialConst } from '../../../../config/Const';

export interface MaterialsStateProps {
    children: React.ReactNode;
}
  
const useStyles = makeStyles((theme) => ({
	boxSuccess: {
		width: 100,
		backgroundColor: theme.palette.success.main,
	},
	boxError: {
		width: 100,
		backgroundColor: theme.palette.error.main,
	},
}));

const MaterialsState: React.FC<MaterialsStateProps> = ({children}) => {
	const classes = useStyles();
	if (children===materialConst.state.KonyuIrai) {
		return (
			<Box className={classes.boxSuccess} color="white">{children}</Box>
		);
	} else {
		return (
			<Box className={classes.boxError} color="white">{children}</Box>
		);
	}
};

export default MaterialsState;