import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./store/AuthContext";
import { ConfigProvider } from "./store/ConfigContext";
import Login from "./views/pages/Login";
import Home from "./views/pages/Home";
import Verified from "./views/pages/Verified";
import Settings from "./views/pages/settings/Settings";
import Users from "./views/pages/users/Users";
import About from "./views/pages/About";
import Contact from "./views/pages/Contact";
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import Nomatch from "./views/pages/Nomatch";
import Works from "./views/pages/works/Works";
import WorksReport from "./views/pages/works/components/WorksReport";
import WorksPdf from "./views/pages/works/components/WorksPdf";
import Invoices from "./views/pages/invoices/Invoices";
import InvoicesViewer from "./views/pages/invoices/components/InvoicesViewer";
import InvoicesPdf from "./views/pages/invoices/components/InvoicesPdf";
import Materials from "./views/pages/materials/Materials";
import MaterialsEdit from "./views/pages/materials/components/MaterialsEdit";
import MaterialsPdf from "./views/pages/materials/components/MaterialsPdf";
import Controls from "./views/pages/controls/controls";
import Exports from "./views/pages/exports/exports";

const App: React.FC = () => {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/about" component={About} />
            <Route path="/privacyPolicy" component={PrivacyPolicy} />
            <Route path="/contact" component={Contact} />
            <Route path="/verified" component={Verified} />
            <ConfigProvider>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/works" component={Works} />
                <Route
                  exact
                  path="/worksReport/:docId"
                  component={WorksReport}
                />
                <Route exact path="/worksPdf/:docId" component={WorksPdf} />
                <Route exact path="/invoices" component={Invoices} />
                <Route
                  exact
                  path="/invoicesViewer/:docId/"
                  component={InvoicesViewer}
                />
                <Route exact path="/invoicesPdf" component={InvoicesPdf} />
                <Route exact path="/materials" component={Materials} />
                <Route
                  exact
                  path="/materialsEdit/:action/:docId?"
                  component={MaterialsEdit}
                />
                <Route
                  exact
                  path="/materialsPdf/:docId"
                  component={MaterialsPdf}
                />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/controls" component={Controls} />
                <Route exact path="/exports" component={Exports} />
                <Route component={Nomatch} />
              </Switch>
            </ConfigProvider>
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
