const Config = {
	language: {
		en: {
			id: 'en',
			name: 'English',
			value: 10,
			greet: 'Hello!',
			home: 'HOME',
			settings: 'SETTINGS',
			users: 'USERS',
			works: 'WORKS',
			invoices: 'INVOICES',
			materials: 'MATERIALS',
			controls: 'CONTROLS',
			exports: 'EXPORTS',
		},
		ja: {
			id: 'ja',
			name: '日本語',
			value: 20,
			greet: 'こんにちは!',
			home: 'ホーム',
			settings: '設定',
			users: 'ユーザー',
			works: '完了報告',
			invoices: '請求書',
			materials: '部材買取依頼表',
			controls: '管理画面',
			exports: 'エクスポート',
		},
	},
	blightness: {
		light: {
			isDark: false,
		},
		dark: {
			isDark: true,
		},
	},
}
export default Config;